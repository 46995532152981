// dependencies.
import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
// components.
import Head from '../components/Meta/Head'
import { ContentWrapper, ScreenWrapper, Topbar } from '../components/Layout'
import { ToastContainer } from 'react-toastify'
// utils.
import setBackgroundColor from '../js/setBackgroundColor'
import { getNameFull } from '../js/usernameUtils'
import { useAuth } from '../js/contexts/AuthContext'
import { useDimensions } from '../js/hooks'

/******************************/
/*                            */
/*    Admin Panel Template    */
/*                            */
/******************************/

const AdminTemplate = ({
  navigation,
  modalOpen,
  sectionTitle,
  children,
  handleIsCondensed = () => {},
  onOverlayClick,
}) => {
  // contexts.
  const { user, userData, sendOut } = useAuth()
  // hooks.
  const navigate = useNavigate()
  const { isMobile, isTablet } = useDimensions()
  // data objects.
  const cookies = new Cookies()
  const condensedCookie = cookies.get('navigation-condensed') === 'true'
  // states.
  const [isCondensed, setIsCondensed] = useState(condensedCookie || false)

  // ------------------------------------------------------------------------------------------- //
  // USER SESSION                                                                                //
  // ------------------------------------------------------------------------------------------- //
  // handle session log out.
  const handleLogout = () => {
    sendOut()
    navigate('/signin')
  }

  // handle logged out users.
  useEffect(() => {
    if (!user) navigate('/signin')
  }, [user])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  // build topbar title with full name or email.
  const { firstname, lastname, email } = userData
  const maybeShowName = (!!firstname || !!lastname) && getNameFull(firstname, lastname)
  const maybeShowEmail = !!email && email
  const topbarTitle = isMobile
    ? sectionTitle
    : `Bienvenido ${maybeShowName || maybeShowEmail || 'cargando...'}`

  // get navigation menu title and items.
  const { title, items } = navigation

  // set condensed as default for tablet screens.
  useEffect(() => {
    if (isTablet && !cookies.get('navigation-condensed')) setIsCondensed(true)
  }, [isTablet])

  useEffect(() => {
    cookies.remove('navigation-condensed')
    cookies.set('navigation-condensed', isCondensed, { path: '/' })
    handleIsCondensed(isCondensed)
  }, [isCondensed, isTablet])

  // return content.
  return (
    <>
      <Head color={setBackgroundColor(userData.access)} />

      <ScreenWrapper
        background={setBackgroundColor(userData.access)}
        modalOpen={modalOpen}
        onClick={onOverlayClick}
      >
        <Topbar
          label={topbarTitle}
          iconR="sign-out"
          condensed={isCondensed}
          actionLabel={!isMobile && 'Cerrar sesión'}
          onActionRPress={handleLogout}
        />

        <ContentWrapper
          background="white"
          title={title}
          navigation={items}
          condensed={isCondensed}
          onCondenseClick={() => setIsCondensed(!isCondensed)}
        >
          {children}
        </ContentWrapper>
      </ScreenWrapper>

      <ToastContainer />
    </>
  )
}

export default AdminTemplate
