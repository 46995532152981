// dependencies.
import { useEffect, useState } from 'react'
import { collection, orderBy, query, where } from 'firebase/firestore'
// utils.
import useFetch from '../useFetch'
import { db } from '../../firebase/firebase'
import { getNameFull } from '../../usernameUtils'
import { useAuth } from '../../contexts/AuthContext'

/******************************/
/*                            */
/*    Active Docrtors Data    */
/*                            */
/******************************/

const useDoctorsData = (reload, access = ['superadmin', 'doctor', 'developer', 'doctor-admin']) => {
  // contexts.
  const { user, userData } = useAuth()
  // hooks
  const [doctorsByStatus, setDoctorsByStatus] = useState({})

  // ------------------------------------------------------------------------------------------- //
  // FETCH DATABASE DATA                                                                         //
  // ------------------------------------------------------------------------------------------- //

  // database query.
  const q = query(
    collection(db, 'users'),
    where('access', '==', 'doctor'),
    orderBy('lastname', 'asc')
  )

  // fetch all active doctors from firebase's collection.
  const { data, empty } = useFetch({ q, access, user, userData, name: 'allDoctors' }, [reload])

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // process doctors list for select input for Admin Home page.
  const doctorsSelect = (doctorsSet = data) => {
    // set in the select only the active doctors
    return doctorsSet.reduce(function (filtered, doctorObj) {
      if (doctorObj.isActive) {
        const { firstname, lastname, uid } = doctorObj
        const doctor = {
          label: getNameFull(firstname, lastname),
          name: uid,
        }
        filtered.push(doctor)
      }
      return filtered
    }, [])
  }

  // filter doctors with isActive true.
  const doctorsStatus = (isActive = true, doctorsSet = data) => {
    return doctorsSet.filter((doctorsObj) => {
      if (
        doctorsSet.length === 0 ||
        doctorsObj.isActive !== isActive ||
        typeof doctorsObj.isActive !== 'boolean'
      ) {
        return null
      }
      return doctorsObj
    })
  }
  // build doctors by isActive status
  useEffect(() => {
    if (!data) return
    setDoctorsByStatus({
      active: doctorsStatus(true),
      inactive: doctorsStatus(false),
    })
  }, [data])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return values.
  return {
    doctors: data,
    doctorsCount: {
      all: data.length,
      active: doctorsByStatus?.active?.length,
      inactive: doctorsByStatus?.inactive?.length,
    },
    doctorsSelect,
    empty,
  }
}

export default useDoctorsData
