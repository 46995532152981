// dependencies.
import { collection, orderBy, query } from 'firebase/firestore'
// utils.
import useFetch from '../useFetch'
import { db } from '../../firebase/firebase'
import { getAddress } from '../../addressFormat'
import { jsonS } from '../../jsonMethods'
import { useAuth } from '../../contexts/AuthContext'
import { useEffect, useState } from 'react'

/***********************/
/*                     */
/*    Business Data    */
/*                     */
/***********************/

const useBusinessData = (
  patients,
  certifications,
  reload,
  access = ['superadmin', 'developer']
) => {
  // contexts.
  const { user, userData } = useAuth()
  // states.
  const [businessTableItems, setBusinessTableItems] = useState([])

  // ------------------------------------------------------------------------------------------- //
  // FETCH DATABASE DATA                                                                         //
  // ------------------------------------------------------------------------------------------- //

  // database query.
  const q = query(collection(db, 'business'), orderBy('name', 'asc'))

  // fetch all business from firebase's collection.
  const { data, empty } = useFetch({ q, access, user, userData, name: 'Business' }, [reload])

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // process business list for select input for Admin Home page.
  const businessSelect = (businessSet = data) => {
    return businessSet.map((businessObj) => {
      // select options.
      return {
        label: businessObj.name,
        name: businessObj.ref.id,
      }
    })
  }

  // filter certifications for Admin Certifications page.
  const businessTable = (
    patientsSet = patients,
    certificationsSet = certifications,
    businessSet = data
  ) => {
    return businessSet.map((businessObj) => {
      // get business patients.
      const patients = patientsSet.filter((patientObj) => {
        return patientObj?.businessRef?.find((ref) => jsonS(ref) === jsonS(businessObj.ref))
      })

      // get business certifications.
      const certifications = certificationsSet.filter((certificationObj) => {
        return jsonS(certificationObj.businessRef) === jsonS(businessObj.ref)
      })

      const { ref, name, rut, address } = businessObj

      // table items.
      return {
        items: [
          {
            copy: name,
            grow: 2,
            to: ref ? '/admin/empresas/' + ref.id : null,
            icon: 'image',
          },
          { copy: rut, grow: 1, label: 'RUT:', icon: 'wallet' },
          { copy: getAddress(address), grow: 2, label: 'Dirección:', icon: 'bookmark' },
          { copy: patients?.length, label: 'Empleados:', icon: 'user' },
          { copy: certifications?.length, label: 'Certificaciones:', icon: 'title' },
        ],
        disableDelete: patients?.length > 0,
        raw: businessObj,
      }
    })
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  useEffect(() => {
    if (!data || !patients || !certifications) return
    setBusinessTableItems(businessTable(patients, certifications))
  }, [data, patients, certifications])

  // return values.
  return {
    business: data,
    businessCount: data.length,
    businessSelect,
    businessTable,
    businessTableItems,
    empty,
  }
}

export default useBusinessData
