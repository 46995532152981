// dependencies.
import { Link } from 'react-router-dom'
import styled from 'styled-components'
// utils.
import styleguideHasKey from '../../js/styleguideHasKey'
import { theme } from '../../styleguide'

/********************************/
/*                              */
/*    Button Label Component    */
/*                              */
/********************************/

// partials.
const Element = ({ href, to, className, disabled, children, onClick }) => {
  const commonProps = { className, disabled }

  const handleOnClick = (e) => {
    e.preventDefault()
    onClick()
  }

  if (href) {
    return (
      <a href={!disabled && to} {...commonProps}>
        {children}
      </a>
    )
  }

  if (to) {
    return (
      <Link to={!disabled && to} {...commonProps}>
        {children}
      </Link>
    )
  }

  if (onClick) {
    return (
      <button
        onClick={disabled ? () => console.log('pst!') : (e) => handleOnClick(e)}
        {...commonProps}
      >
        {children}
      </button>
    )
  }

  return <span className={className}>{children}</span>
}

const Wrapper = styled(Element)({
  appearance: 'none',
  background: 'transparent',
  border: 'none',
  color: (props) => (props.disabled ? props.disabledColor : props.color),
  cursor: (props) => (props.disabled ? 'not-allowed' : 'pointer'),
  display: 'block',
  fontFamily: 'Roboto, sans-serif',
  fontSize: `${theme.fontSize.x16}px`,
  fontWeight: 'bold',
  lineHeight: `${theme.lineHeight.x16}px`,
  textDecoration: (props) => (props.href || props.href ? 'underline' : 'none'),
})

// main component.
const Button = ({ className, color, disabledColor, href, to, children, disabled, onClick }) => {
  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // make sure color is included in the library.
  const { colors } = theme
  const textColor = styleguideHasKey(color, colors, 'black')
  const disabledTextColor = styleguideHasKey(disabledColor, colors, 'ash')

  // build props object.
  const props = {
    className,
    color: colors[textColor],
    disabled,
    disabledColor: colors[disabledTextColor],
    href,
    to,
    onClick,
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return <Wrapper {...props}>{children}</Wrapper>
}

export default Button
