// global.
export const IS_DEV = process.env.NODE_ENV === 'development'
export const PUBLIC = process.env.PUBLIC_URL

export const DATE_FORMAT = 'DD/MM/YYYY'
export const US_DATE_FORMAT = 'yyyy-MM-DD'
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm'
export const MONTHS_ES =
  'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'
export const MONTHS_SHORT_ES = 'Ene._Feb._Mar._Abr._May._Jun._Jul._Ago._Set._Oct._Nov._Dec.'
export const DAYS_ES = 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'
export const DAYS_SHORT_ES = 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'
export const DAYS_MIN_ES = 'Do_Lu_Ma_Mi_Ju_Vi_Sa'

export const CURRENCY = 'UYU'

export const GMAPS_API_KEY = 'AIzaSyCetgQOan-haj8KxNX7TSTaiYqJHUrD1vc'
export const GMAP_LIBRARIES = ['drawing', 'geometry']

export const DOMAIN = 'medilab.com.uy'
export const SITE_URL = `https://${DOMAIN}`

// social.
export const TWITTER_USERNAME = 'medilabuy'

// navigation.
export const NAVIGATION_ITEMS = {
  admin: {
    title: 'Admin',
    items: [
      { label: 'Inicio', icon: 'home', path: '/admin' },
      { type: 'divider' },
      { label: 'Certificaciones', icon: 'certification', path: '/admin/certificaciones' },
      { label: 'Zonificación', icon: 'location', path: '/admin/mapa' },
      { label: 'Patologías', icon: 'pathology', path: '/admin/patologias' },
      { type: 'divider' },
      { label: 'Empresas', icon: 'business', path: '/admin/empresas' },
      { label: 'Médicos', icon: 'doctor', path: '/admin/medicos' },
      { label: 'Admins', icon: 'admin', path: '/admin/usuarios' },
      { type: 'divider' },
      { label: 'Estadísticas', icon: 'reports', path: '/admin/estadisticas' },
      { type: 'spacer' },
      { label: 'Ajustes', icon: 'settings', path: '/admin/ajustes' },
    ],
  },

  business: {
    title: 'Empresas',
    items: [
      { label: 'Inicio', icon: 'home', path: '/empresas' },
      { type: 'divider' },
      { label: 'Empleados', icon: 'employees', path: '/empresas/empleados' },
      { label: 'Certificaciones', icon: 'certification', path: '/empresas/certificaciones' },
    ],
  },

  doctor: {
    title: 'Doctores',
    items: [
      { label: 'Inicio', icon: 'home', path: '/doctores' },
      { type: 'divider' },
      { label: 'Certificaciones', icon: 'certification', path: '/doctores/certificaciones' },
      { type: 'spacer' },
      { label: 'Ajustes', icon: 'settings', path: '/doctores/ajustes' },
    ],
  },

  doctoradmin: {
    title: 'Doctor Admin',
    items: [{ label: 'Zonificación', icon: 'location', path: '/admin/mapa' }],
  },
}

export const DEV_NAVIGATION_ITEMS = {
  title: 'Developer',
  items: [
    { label: 'Panel', icon: 'home', path: '/dev' },
    { type: 'blockspacer', label: 'Admin', icon: 'bookmark' },
    ...NAVIGATION_ITEMS.admin.items,
    { type: 'blockspacer', label: 'Empresas', icon: 'bookmark' },
    ...NAVIGATION_ITEMS.business.items,
    { type: 'blockspacer', label: 'Doctores', icon: 'bookmark' },
    ...NAVIGATION_ITEMS.doctor.items,
  ].filter((item) => item?.type !== 'spacer' && item?.type !== 'divider'),
}

// flags.
export const SIGN_UP_ENABLED = false
