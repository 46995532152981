// dependencies.
import { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'

// components.
import { Label } from '../Typography'

// utils.
import { theme } from '../../styleguide'
import FilterHeader from './FilterHeader'

/*********************************/
/*                               */
/*    Development Home Screen    */
/*                               */
/*********************************/

// partials

const TabHeader = styled.div(
  {
    alignItems: 'center',
    backgroundColor: theme.colors.smoke,
    borderBottom: (props) => (props.isMobile ? `1px solid ${theme.colors.ash}` : 'none'),
    display: 'flex',
    flexDirection: 'row',
    margin: '-24px -16px 0',
    padding: (props) => (props.isMobile ? '8px' : '24px 16px 40px'),
    width: 'calc(100% + 32px)',
    gap: '16px',
  },
  `@media print { display: none; }`
)

const TabBody = styled.div({
  paddingTop: '24px',
})

// main component.
const Tabs = ({ items, onClick }) => {
  // states.
  const [activeTab, setActiveTab] = useState(0)
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [businessId, setBusinessId] = useState()
  const [doctorId, setDoctorId] = useState()

  // clean filters on tab change
  useEffect(() => {
    setBusinessId('')
    setDoctorId('')
    onClick({ fromDate: '', toDate: '', businessId: '', doctorId: '' })
  }, [activeTab])
  // broadcast values

  useEffect(() => {
    onClick({ fromDate, toDate, businessId, doctorId })
  }, [fromDate, toDate, businessId, doctorId])

  const {
    selectInput,
    search,
    filter,
    exportReport,
    placeholder,
    csvHeaders,
    csvData,
    filename,
    actionLabel,
    onActionClick,
    onChange,
  } = items[activeTab]

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  if (!items.length) return null

  // return content.
  return (
    <>
      <TabHeader>
        {items.map(({ label }, i) => (
          <Label
            key={i}
            color={activeTab === i ? 'terciary' : 'grey'}
            onClick={() => setActiveTab(i)}
          >
            {label}
          </Label>
        ))}
      </TabHeader>

      <FilterHeader
        selectInput={selectInput}
        exportReport={exportReport}
        search={search}
        placeholder={placeholder}
        label={actionLabel}
        header={csvHeaders}
        items={csvData}
        filename={filename}
        onActionClick={onActionClick}
        {...(filter && {
          onFilterClick: (value) => {
            setFromDate(value?.fromDate)
            setToDate(value?.toDate)
            if (items[activeTab]?.selectInput?.name === 'business')
              setBusinessId(value?.selectInputValue)
            if (items[activeTab]?.selectInput?.name === 'doctor')
              setDoctorId(value?.selectInputValue)
          },
        })}
        onChange={onChange}
      />
      <TabBody>
        {items.map(({ component }, i) =>
          activeTab === i ? <Fragment key={i}>{component}</Fragment> : null
        )}
      </TabBody>
    </>
  )
}

export default Tabs
