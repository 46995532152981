// dependencies.
import { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
// components.
import AuthTemplate from '../../templates/AuthScreen'
import { Label } from '../../components/Typography'
// utils.
import firebaseErrorMessage from '../../js/firebase/firebaseErrorTranslator'
import notifyToast from '../../js/notifyToast'
import { useAuth } from '../../js/contexts/AuthContext'
// constants.
import { DEFAULT_FORM } from './constants'
import { SIGN_UP_ENABLED } from '../../constants'

/************************/
/*                      */
/*    Sign In Screen    */
/*                      */
/************************/

// partials.
const StyledButtons = styled.div({
  alignItems: 'center',
  display: 'flex',
  flxWrap: 'wrap',
  gap: '32px',
  justifyContent: 'center',
  margin: '32px auto',
  maxWidth: '360px',
})

// main component.
const SigninScreen = () => {
  // contexts.
  const { signIn } = useAuth()
  // hooks.
  const navigate = useNavigate()
  // states.
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  // handle update value change.
  const handleValueChange = (values) => {
    const { email, password } = values

    setEmail(email)
    setPassword(password)
  }

  // navigate to sign-up page.
  const handleRegister = () => {
    navigate('/signup')
  }

  // navigate to reset-password page.
  const handleReset = () => {
    navigate('/reset-password')
  }

  // handle sign in with email and password.
  const handleSignInClick = (email, password) => {
    signIn(email, password).then((response) => {
      notifyToast(firebaseErrorMessage(response), 'error')
    })
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <AuthTemplate
      description="Para acceder al panel, primero inicie sesión."
      form={DEFAULT_FORM}
      submitLabel="Ingresar"
      topbarTitle="Iniciar sesión"
      onChange={(value) => handleValueChange(value)}
      onSubmit={() => handleSignInClick(email, password)}
    >
      <StyledButtons>
        {SIGN_UP_ENABLED && (
          <Label color="grey" onClick={() => handleRegister()}>
            Crear cuenta
          </Label>
        )}

        <Label color="grey" onClick={() => handleReset()}>
          Olvidó su contraseña?
        </Label>
      </StyledButtons>
    </AuthTemplate>
  )
}

export default SigninScreen
