// dependencies.
import { useEffect, useState } from 'react'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import MedicActData from '../../modals/MedicActData'
import Table from '../../components/Table'
import { Drawer, DrawerWithMap } from '../../components/Modal'
import { Loader } from '../../components/Icon'
import { FilterHeader } from '../../components/Layout'
// utils.
import search from '../../js/search'
import { getPathologyByCode } from '../../js/firebase/firebase'
import { useDoctorsData, useCertificationsData, usePatientsData } from '../../js/hooks/data'

/*************************************/
/*                                   */
/*    Doctor Certifications Screen    */
/*                                   */
/*************************************/

// helpers.
const tableHeader = [
  { label: 'Solicitud', grow: 1 },
  { label: 'Estado' },
  { label: 'Documento', grow: 1 },
  { label: 'Paciente', grow: 2 },
  { label: 'Empresa', grow: 1 },
  { label: 'Médico asignado', grow: 1 },
  { label: 'Realizado' },
  { label: 'Acciones', grow: 2 },
]

// main component.
const DoctorCertificationsScreen = ({ navMenu, navTitle }) => {
  const [filteredValues, setFilteredValues] = useState({})
  // hooks.
  const { doctors } = useDoctorsData()
  const { patients } = usePatientsData()
  const { certificationsTableItems, empty } = useCertificationsData(
    patients,
    doctors,
    null,
    null,
    null,
    filteredValues
  )

  // data objects.
  // states.
  const [loaded, setLoaded] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [mapModalOpen, setMapModalOpen] = useState(false)
  const [searched, setSearched] = useState(certificationsTableItems)

  // ------------------------------------------------------------------------------------------- //
  // SEARCH                                                                                      //
  // ------------------------------------------------------------------------------------------- //

  // search for items in the array.
  const handleSearch = (term) => {
    setSearched(search(certificationsTableItems, term))
  }
  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  const handleMapModalOpen = (open) => {
    setMapModalOpen(open)
  }

  // handle show medic act detail for selected certification modal.
  const handleViewMedicActModal = async (obj) => {
    if (obj.medicAct.pathologyId) {
      const pathology = await getPathologyByCode(obj.medicAct.pathologyId)
      obj.medicAct.pathology = pathology.data().confidentialName
    }

    setModalContent({
      title: 'Acto Médico',
      component: <MedicActData certification={obj} />,
    })

    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // Without initially setting and clearing search on resourceLoaded in this way, causes asset list
  // children to draw incorrectly, with logos swapped out between assets, reason not known.
  // Removing sort removes the need for this.
  useEffect(() => {
    handleSearch('')
  }, [certificationsTableItems])

  // only show content when loaded.
  useEffect(() => {
    !empty && setLoaded(true)
  }, [empty])

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => {
          setModalOpen(false)
          setMapModalOpen(false)
        }}
      >
        <FilterHeader
          placeholder="Buscar por: cédula, nombre, estado..."
          onChange={(term) => handleSearch(term)}
          onFilterClick={(value) => setFilteredValues(value)}
        />

        {loaded ? (
          <Table
            header={tableHeader}
            items={searched}
            //
            enableShowMedicAct
            onShowMedicAct={(obj) => handleViewMedicActModal(obj)}
          />
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>

      <DrawerWithMap
        title={modalContent.title}
        pointsToCheck={modalContent.pointsToCheck}
        isOpen={mapModalOpen}
        onChange={handleMapModalOpen}
      >
        {modalContent.component}
      </DrawerWithMap>
    </>
  )
}

export default DoctorCertificationsScreen
