// dependencies.
import styled from 'styled-components'
// components.
import { Body } from '../Typography'
import Button from './Button'
import { Icon } from '../Icon'
// utils.
import { theme } from '../../styleguide'

/*****************************/
/*                           */
/*    Button Group Module    */
/*                           */
/*****************************/

// helpers.
const buildVariant = (variant) => {
  switch (variant) {
    case 'primary':
      return {
        color: 'white',
        background: 'primary',
      }
    case 'alert':
      return {
        color: 'white',
        background: 'alert',
      }
    case 'warning':
      return {
        color: 'black',
        background: 'warning',
      }
    case 'success':
      return {
        color: 'black',
        background: 'success',
      }
    default:
      return {
        color: 'terciary',
        background: 'white',
      }
  }
}

// partials.
const Wrapper = styled.span({
  alignItems: 'stretch',
  backgroundColor: theme.colors.ash,
  borderRadius: '24px',
  boxShadow: theme.shadows.normal,
  display: 'flex',
  flexDirection: 'row',
  gap: '1px',
  height: '32px',
  justifyContent: 'stretch',
  overflow: 'hidden',
  width: (props) => `${88 * props.grow}px`,
})

const StyledWrapper = styled.span({
  alignItems: 'center',
  display: 'flex',
  borderRadius: 0,
  boxShadow: 'none',
  flexDirection: 'row',
  flexGrow: 1,
  gap: '2px',
  justifyContent: 'center',
  margin: '0 auto',
  padding: 0,
  width: (props) => (props.isSmall ? '32px' : '80px'),
})

const StyledButton = styled(Button)({
  borderRadius: 0,
  boxShadow: 'none',
  flexGrow: 1,
  maxWidth: '88px',
  padding: 0,
})

const StyledBody = styled(Body)({
  flexGrow: 0,
  fontSize: '10px',
  fontWeight: '400',
  lineHeight: '10px',
  padding: 0,
  whiteSpace: 'nowrap',
})

export const Item = ({ icon, label, variant, disabled, href, to, onClick }) => {
  const { color, background } = buildVariant(variant)
  const props = {
    color: disabled ? 'ash' : color,
    background: disabled ? 'smoke' : background,
    padding: '4px',
    disabled,
    href,
    to,
    onClick,
  }

  return (
    <StyledButton {...props}>
      <StyledWrapper isSmall={!label}>
        {label && (
          <StyledBody type="caption" color={disabled ? 'ash' : color} align="center">
            {label}
          </StyledBody>
        )}

        {icon && <Icon name={icon} size={14} color={disabled ? 'ash' : color} />}
      </StyledWrapper>
    </StyledButton>
  )
}

const Group = ({ children }) => {
  const count = [...children].filter((child) => typeof child !== 'undefined' && child !== null)

  return <Wrapper grow={count}>{children}</Wrapper>
}

export default Group
