// dependencies.
import Geocode from 'react-geocode'
// constants.
import { GMAPS_API_KEY } from '../constants'

Geocode.setApiKey(GMAPS_API_KEY)

export const parseAddressToLatLng = (address) => {
  return Geocode.fromAddress(address).then(
    (response) => {
      const { lat, lng } = response.results[0].geometry.location
      return { lat, lng }
    },
    (error) => {
      console.error(error)
    }
  )
}

export const reverseLatLngToAddress = (latStr, lngStr) => {
  const lat = typeof latStr === 'function' ? latStr() : +latStr
  const lng = typeof lngStr === 'function' ? lngStr() : +lngStr

  return Geocode.fromLatLng(lat, lng).then(
    (response) => {
      return response.results[0].formatted_address
    },
    (error) => {
      console.error(error)
    }
  )
}

export const toAddressString = (address) => {
  const { street, number, district, city } = address

  return `${street} ${number}, ${district}, ${city}, Uruguay`
}

export const toAddressObject = (addressString) => {
  // Split the address string by commas
  const parts = addressString.split(',')

  // Extract street and number from the first part using a regular expression
  const streetAndNumber = parts[0].trim()
  const streetRegex = /^(.+?)\s+(\d+)/ // Regular expression with capturing groups
  const match = streetAndNumber.match(streetRegex) // Match the regular expression
  let street, number
  if (match) {
    street = match[1] // Extract the street name from the first capturing group
    number = match[2] // Extract the number from the second capturing group
  } else {
    street = streetAndNumber // If no match found, consider the entire string as street name
  }
  // Extract city and state from the second part
  let districtAndState = ''
  if (parts.length > 1) {
    districtAndState = parts[1].trim()
  }
  const [district, state] = districtAndState.split(/\s+/) // Split by whitespace

  // Extract country from the third part
  let country
  if (parts.length > 2) {
    country = parts[2].trim()
  }

  return {
    street,
    number,
    district,
    state,
    country,
  }
}
