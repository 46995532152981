export const DEFAULT_FORM = [
  {
    iconL: 'user',
    name: 'email',
    placeholder: 'email',
    required: true,
  },
  {
    iconL: 'edit',
    name: 'password',
    type: 'password',
    placeholder: 'contraseña',
    required: true,
  },
]

export const RESET_FORM = [
  {
    iconL: 'user',
    name: 'email',
    placeholder: 'email',
    required: true,
  },
]

export const SIGNUP_FORM = [
  {
    name: 'firstname',
    placeholder: 'Nombre',
    required: true,
  },
  {
    name: 'lastname',
    placeholder: 'Apellido',
    required: true,
  },
  {
    name: 'phone',
    placeholder: 'Teléfono',
    required: true,
  },
  {
    name: 'email',
    placeholder: 'E-mail',
    required: true,
  },
  {
    name: 'password',
    placeholder: 'Contraseña',
    type: 'password',
    required: true,
  },
]

export const ACTIVATE_FORM = [
  {
    iconL: 'edit',
    name: 'password',
    placeholder: 'Contraseña',
    type: 'password',
    required: true,
  },
  {
    iconL: 'edit',
    name: 'password2',
    placeholder: 'Repita la contraseña',
    type: 'password',
    required: true,
  },
]
