// dependencies.
import { useEffect, useState } from 'react'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import Table from '../../components/Table'
import { Drawer, DrawerWithMap } from '../../components/Modal'
import { Form } from '../../components/Form'
import { Loader } from '../../components/Icon'
import { SearchHeader } from '../../components/Layout'
// utils.
import notifyToast from '../../js/notifyToast'
import search from '../../js/search'
import { db, getDoctorById } from '../../js/firebase/firebase'
import { getNameFull } from '../../js/usernameUtils'
import { parseAddressToLatLng, reverseLatLngToAddress, toAddressObject } from '../../js/parseAddress'
import { useAuth } from '../../js/contexts/AuthContext'
import {
  useDoctorsData,
  useBusinessData,
  useCertificationsData,
  usePatientsData,
  usePathologiesData,
} from '../../js/hooks/data'
// constants.
import {
  ASSIGN_CERTIFICATION_FORM,
  CANCEL_CERTIFICATION_FORM,
  CERTIFICATION_FORM,
  NEW_CERTIFICATION_FORM,
} from './constants'
import styled from 'styled-components'
import { Map } from '../../components/Map'

/*************************************/
/*                                   */
/*    Admin Certifications Screen    */
/*                                   */
/*************************************/

// helpers.
const tableHeader = [
  { label: 'Solicitud', grow: 1 },
  { label: 'Estado' },
  { label: 'Documento', grow: 1 },
  { label: 'Paciente', grow: 2 },
  { label: 'Empresa', grow: 2 },
  { label: 'Médico', grow: 1 },
  { label: 'Acciones', grow: 2 },
]

const MapWrapper = styled.div({
  margin: '24px 0 -50px -16px',
  width: 'calc(100% + 32px)',
})

// main component.
const AdminHomeScreen = ({ navMenu, navTitle }) => {
  const [reload, setReload] = useState(false)
  // contexts.
  const { isLoaded } = useAuth()
  // hooks.
  const { pathologiesSelect } = usePathologiesData()
  // data objects.
  const pathologiesSelectOptions = pathologiesSelect()

  const { businessSelect } = useBusinessData()
  const { doctors, doctorsSelect } = useDoctorsData()
  const { patients, patientsSelect } = usePatientsData()
  const { certificationsActiveTableItems, empty } = useCertificationsData(patients, doctors, reload)
  // data objects.
  const businessSelectOptions = businessSelect()
  const doctorsSelectOptions = doctorsSelect()
  const patientsSelectOptions = patientsSelect()
  // states.
  const [loaded, setLoaded] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [mapModalOpen, setMapModalOpen] = useState(false)
  const [searched, setSearched] = useState(certificationsActiveTableItems)
  const [redPoints, setRedPoints] = useState([])
  const [greenPoints, setGreenPoints] = useState([])

  // const [pendingCertificationsPoints, setPendingCertificationsPoints] = useState([])
  // const [certificationsPoints, setCertificationsPoints] = useState([])

  // ------------------------------------------------------------------------------------------- //
  // SEARCH                                                                                      //
  // ------------------------------------------------------------------------------------------- //

  // search for items in the array.
  const handleSearch = (term) => {
    setSearched(search(certificationsActiveTableItems, term))
  }

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  const handleMapModalOpen = (open) => {
    setMapModalOpen(open)
  }

  // handle add new certification modal.
  const handleNewCertificationModal = () => {
    setModalContent({
      title: 'Solicitar certificación',
      component: (
        <Form
          items={NEW_CERTIFICATION_FORM(businessSelectOptions, patientsSelectOptions)}
          onClick={(obj) => handleCreateCertificationClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  const handleSaveCertificationClick = async (obj) => {
    const {
      startDate,
      endDate,
      issuedDate,
      externalCertificateCheck,
      doctorNameExt,
      startDateExt,
      endDateExt,
      homeLeave,
      pathologyId,
      comments,
    } = obj.medicAct
    const updateFields = {
      medicAct: {
        startDate: setStartDate(startDate),
        endDate: setEndDate(endDate),
        issuedDate: setStartDate(issuedDate),
        externalCertificateCheck: externalCertificateCheck || null,
        doctorNameExt: doctorNameExt || null,
        startDateExt: setStartDate(startDateExt),
        endDateExt: setEndDate(endDateExt),
        homeLeave: homeLeave || null,
        pathologyId: pathologyId || null,
        comments: comments || null,
      },
      status: endDate ? 3 : 2,
      updatedDate: new Date(),
    }

    const validate = validateStartEndDate(
      updateFields.medicAct.startDate,
      updateFields.medicAct.endDate
    )

    if (validate) {
      const certificationRef = doc(db, 'certifications', obj.ref.id)
      await setDoc(certificationRef, updateFields, { merge: true })
        .then(() => notifyToast('Certificación actualizada correctamente', 'success'))
        .then(setTimeout(() => setModalOpen(false), 1000))
        .catch(() => notifyToast('Ha ocurrido un error', 'error'))

      setReload(!reload)
    }
  }

  // handle edit selected certification modal.
  const handleEditCertificationModal = async (obj) => {
    obj.patientData.fullname = getNameFull(obj.patientData?.firstname, obj.patientData?.lastname)

    const doctorData = obj.doctorRef ? doctors.filter((d) => d.uid === obj.doctorRef.id) : null
    const selectedDoctor =
      doctorData && doctorData.length > 0
        ? getNameFull(doctorData[0]?.firstname, doctorData[0]?.lastname)
        : null

    let newPoint
    if (!obj.address.lat || !obj.address.lng) {
      // TODO: fotmat address function:
      const address = `${obj.address.street} ${obj.address.number}, ${obj.address.district}, ${obj.address.city}, Uruguay`
      newPoint = await parseAddressToLatLng(address)
    } else {
      newPoint = {
        lat: obj.address.lat,
        lng: obj.address.lng,
      }
    }
    newPoint.title = obj.patientData.fullname
    
    const handleChangeAddress = async ({ lat, lng }) => {
      try {
        const newAddress = await reverseLatLngToAddress(lat, lng)
        return toAddressObject(newAddress)
      } catch (error) {
        console.error(error)
      }
    }

    setModalContent({
      title: 'Editar/Asignar certificación',
      pointsToCheck: Array(newPoint),
      certificationId: obj.ref.id,
      certificationAddress: obj.address,
      handleChangeAddress: ({ lat, lng }) => {
        handleChangeAddress({ lat, lng }).then((res) => (obj.address = res))
      },
      component: (
        <Form
          items={ASSIGN_CERTIFICATION_FORM(doctorsSelectOptions, selectedDoctor)}
          defaultValues={obj}
          onClick={(obj) => handleUpdateCertificationClick(obj)}
        />
      ),
    })

    isLoaded && setMapModalOpen(true)
  }

  // handle edit selected certification modal.
  const handleCancelCertificationModal = (obj) => {
    obj.patientData.fullname = getNameFull(obj.patientData?.firstname, obj.patientData?.lastname)

    setModalContent({
      title: 'Cancelar certificación',
      component: (
        <Form
          items={CANCEL_CERTIFICATION_FORM}
          defaultValues={obj}
          onClick={(obj) => handleCancelCertificationClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new certification.
  const handleCreateCertificationClick = async (obj) => {
    const patientRef = doc(db, 'patients', obj.document)
    const address = `${obj.address.street} ${obj.address.number}, ${obj.address.district}, ${obj.address.city}, Uruguay`
    const { lat, lng } = await parseAddressToLatLng(address)

    await addDoc(collection(db, 'certifications'), {
      address: { ...obj?.address, lat, lng },
      createdDate: new Date(),
      updatedDate: new Date(),
      status: 0,
      patientRef,
      businessRef: obj.businessRef,
      businessName: obj.businessName,
      comments: obj.comments,
    })
      .then(async (certRef) => {
        await setDoc(certRef, { ref: certRef }, { merge: true })

        notifyToast('Certificación solicitada correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // handle assign / edit certification.
  const handleUpdateCertificationClick = async (obj) => {
    const updateFields = {
      address: { ...obj?.address },
      comments: obj.comments,
      updatedDate: new Date(),
    }
    const certificationRef = doc(db, 'certifications', obj.ref.id)

    if (obj.doctorId) {
      const doctorDoc = await getDoctorById(obj.doctorId)
      updateFields.doctorRef = doctorDoc.ref
      updateFields.status = obj.status === 0 ? 1 : obj.status // assigned
    }
    const address = `${obj.address.street} ${obj.address.number}, ${obj.address.district}, ${obj.address.city}, Uruguay`

    const { lat, lng } = await parseAddressToLatLng(address)

    updateFields.address = { ...updateFields?.address, lat, lng }
    updateFields.updatedDate = new Date()
    await setDoc(certificationRef, updateFields, { merge: true })
      .then(() => notifyToast('Certificación actualizada correctamente', 'success'))
      .then(setTimeout(() => setMapModalOpen(false), 1000))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setReload(!reload)
  }

  // handle cancel certification.
  const handleCancelCertificationClick = async (obj) => {
    const certificationRef = doc(db, 'certifications', obj.ref.id)

    await setDoc(certificationRef, { status: 4 }, { merge: true })
      .then(() => notifyToast('Certificación cancelada correctamente', 'success'))
      .then(setTimeout(() => setModalOpen(false), 1000))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setReload(!reload)
  }

  const handleConfirmLatLngClick = async (obj) => {
    modalContent.certificationAddress.lat = obj.lat()
    modalContent.certificationAddress.lng = obj.lng()

    const updateFields = {
      address: { ...modalContent?.certificationAddress },
      updatedDate: new Date(),
    }
    const certificationRef = doc(db, 'certifications', modalContent.certificationId)

    await setDoc(certificationRef, updateFields, { merge: true })
      .then(() => notifyToast('Certificación actualizada correctamente', 'success'))
      .then(setTimeout(() => setMapModalOpen(false), 1000))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setReload(!reload)
  }

  // edit selected certification.
  const handleEditMedicAct = (obj) => {
    setModalContent({
      title: 'Acto Médico',
      component: (
        <Form
          items={CERTIFICATION_FORM(pathologiesSelectOptions)}
          defaultValues={obj}
          onClick={(obj) => handleSaveCertificationClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  const setStartDate = (date) => {
    if (!isValidDate(new Date(`${date} 00:00:00`))) return null
    return new Date(`${date} 00:00:00`)
  }

  const setEndDate = (date) => {
    if (!isValidDate(new Date(`${date} 23:59:59`))) return null
    return new Date(`${date} 23:59:59`)
  }
  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d)
  }
  const validateStartEndDate = (startDate, endDate) => {
    if (!startDate || !endDate) {
      notifyToast('Debe ingresar fechas de inicio y fin válidas', 'warning')
      return false
    }

    const maxEndDate = new Date(startDate)
    maxEndDate.setDate(maxEndDate.getDate() + 31) // Agregar 30 días al startDate

    if (endDate < startDate) {
      notifyToast('La fecha de fin no puede ser anterior a la de inicio', 'warning')
      return false
    }

    if (endDate > maxEndDate) {
      notifyToast('El período de certificación no puede tener más de 30 días', 'warning')
      return false
    }

    return true
  }
  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // Without initially setting and clearing search on resourceLoaded in this way, causes asset list
  // children to draw incorrectly, with logos swapped out between assets, reason not known.
  // Removing sort removes the need for this.
  useEffect(() => {
    handleSearch('')
    certificationsActiveTableItems.map((cert) => {
      if (cert.raw.address && cert.raw.address.lat && cert.raw.address.lng) {
        if (cert.raw.status === 0) {
          const newPoint = {
            lat: cert.raw.address.lat,
            lng: cert.raw.address.lng,
            title: getNameFull(cert.raw.patientData.firstname, cert.raw.patientData.lastname)
          }
          setRedPoints((redPoints) => [...redPoints, newPoint])
        }
        if (cert.raw.status === 1) {
          const newPoint = {
            lat: cert.raw.address.lat,
            lng: cert.raw.address.lng,
            title: getNameFull(cert.raw.patientData.firstname, cert.raw.patientData.lastname)
          }
          setGreenPoints((greenPoints) => [...greenPoints, newPoint])
        }
      }

      return [greenPoints, redPoints]
    })
  }, [certificationsActiveTableItems])

  // only show content when loaded.
  useEffect(() => {
    !empty && setLoaded(true)
  }, [empty])

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={mapModalOpen || modalOpen}
        onOverlayClick={() => {
          setModalOpen(false)
          setMapModalOpen(false)
        }}
      >
        <SearchHeader
          placeholder="Buscar por: cédula, nombre, estado..."
          onClick={() => handleNewCertificationModal()}
          onChange={(term) => handleSearch(term)}
        />

        {loaded ? (
          <>
            <Table
              header={tableHeader}
              items={searched}
              //
              enableEdit
              onEdit={(obj) => handleEditCertificationModal(obj)}
             
              enableMedicAct
              onMedicAct={(obj) => handleEditMedicAct(obj)}
             
              //
              enableCancel
              onCancel={(obj) => handleCancelCertificationModal(obj)}
            />
            <MapWrapper>
              <Map pointsToCheck={redPoints} greenPoints={greenPoints} disableDrawingTools />
            </MapWrapper>
          </>
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>

      <DrawerWithMap
        title={modalContent.title}
        pointsToCheck={modalContent.pointsToCheck}
        isOpen={mapModalOpen}
        onChange={handleMapModalOpen}
        onChangeLatLng={handleConfirmLatLngClick}
        onChangeAddress={modalContent.handleChangeAddress}
        enableMoveMarker
      >
        {modalContent.component}
      </DrawerWithMap>
    </>
  )
}

export default AdminHomeScreen
