// dependencies.
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
// components.
import AuthTemplate from '../../templates/AuthScreen'
// utils.
import notifyToast from '../../js/notifyToast'
import { useAuth } from '../../js/contexts/AuthContext'
// constants.
import { ACTIVATE_FORM } from './constants'

/******************************/
/*                            */
/*    Activate User Screen    */
/*                            */
/******************************/

const ActivateScreen = () => {
  // contexts.
  const { activate } = useAuth()
  // hooks.
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  // states.
  const [values, setValues] = useState({})

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle update value change.
  const handleValueChange = (value) => {
    setValues(value)
  }

  // handle update activate value for user.
  const handleActivateClick = () => {
    if (!values.password) {
      return notifyToast('Debe ingresar una contraseña', 'error')
    }

    if (values.password !== values.password2) {
      return notifyToast('Las contraseñas no coinciden', 'error')
    }

    values.email = searchParams.get('email')
    activate(values)
  }

  // ------------------------------------------------------------------------------------------- //
  // USER SESSION                                                                                //
  // ------------------------------------------------------------------------------------------- //

  useEffect(() => {
    // handle not signed up users.
    if (!searchParams.get('email')) navigate('/signin')
  }, [])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <AuthTemplate
      description="Para activar su cuenta, ingrese una contraseña"
      form={ACTIVATE_FORM}
      submitLabel="Activar cuenta"
      topbarTitle="Activar cuenta"
      onChange={(value) => handleValueChange(value)}
      onSubmit={() => handleActivateClick()}
    />
  )
}

// TODO: create an auth screens tempate.

export default ActivateScreen
