// helpers.
let status = ['No Asignada', 'Asignada', 'Activa', 'Finalizada', 'Cancelada']

// main function.
const certificationStatusFormat = (num, userAccess = null) => {
  if (userAccess === 'superadmin') {
    status = ['No Asignada', 'Asignada', 'Finalizada', 'Finalizada', 'Cancelada']
  } else if (userAccess === 'company') {
    status = ['Solicitada', 'Solicitada', 'Realizada', 'Realizada', 'Cancelada']
  }
  return status[num]
}

export default certificationStatusFormat
