// dependencies.
import { useState } from 'react'
// utils.
import useWindowEvent from './useWindowEvent'

// main function.
const useDimensions = () => {
  // states.
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    isMobile: window.innerWidth <= 992,
    isTablet: window.innerWidth > 992 && window.innerWidth <= 1280,
  })

  // on resize update dimensions.
  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
      isMobile: window.innerWidth <= 992,
      isTablet: window.innerWidth > 992 && window.innerWidth <= 1280,
    })
  }

  // event listeners.
  useWindowEvent('resize', handleResize)

  return dimensions
}

export default useDimensions
