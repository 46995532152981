// dependencies.
import { useEffect, useState } from 'react'
import { doc, setDoc } from 'firebase/firestore'
import styled from 'styled-components'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import BottomDrawer from '../../components/Modal/BottomDrawer'
import CertificationData from '../../modals/CertificationData'
import { Drawer } from '../../components/Modal'
import { Form } from '../../components/Form'
import { List } from '../../components/Card'
import { Loader } from '../../components/Icon'
import { Map } from '../../components/Map'
// utils.
import notifyToast from '../../js/notifyToast'
import { db } from '../../js/firebase/firebase'
import { getNameFull } from '../../js/usernameUtils'
import { parseAddressToLatLng, toAddressString } from '../../js/parseAddress'
import { useAuth } from '../../js/contexts/AuthContext'
import {
  useDoctorCertificationsData,
  usePathologiesData,
  usePatientsData,
} from '../../js/hooks/data'
// constants.
import { CERTIFICATION_FORM } from './constants'

/****************************/
/*                          */
/*    Doctor Home Screen    */
/*                          */
/****************************/

// partials.
const MapWrapper = styled.div({
  margin: '-24px -16px 0',
  width: 'calc(100% + 32px)',
})

const StyledList = styled(List)({
  marginTop: '24px',
})

const DoctorsHomeScreen = ({ navMenu, navTitle }) => {
  const [reload, setReload] = useState(false)
  // contexts.
  const { isLoaded } = useAuth()
  // hooks.
  const { patients } = usePatientsData()
  const { certificationsTable, empty } = useDoctorCertificationsData(patients, reload)
  const { pathologiesSelect } = usePathologiesData()
  // data objects.
  const certificationsTableItems = certificationsTable()
  const pathologiesSelectOptions = pathologiesSelect()
  // states.
  const [loaded, setLoaded] = useState(isLoaded)
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [detailOpen, setDetailOpen] = useState(false)
  const [pointsToCheck, setPointsToCheck] = useState([])
  const [isCondensed, setIsCondensed] = useState()

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  const handleDetailOpen = (open) => {
    setDetailOpen(open)
  }

  // handle edit Doctor Medic Act.
  const handleEditPatientModal = (obj) => {
    setDetailOpen(false)
    setModalContent({
      title: 'Acto Médico',
      component: (
        <Form
          items={CERTIFICATION_FORM(pathologiesSelectOptions)}
          defaultValues={obj}
          onClick={(obj) => handleSaveCertificationClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }
  // handle show detail modal open.
  const handleDetailModal = async (obj) => {
    let marker
    if (!obj.address.lat || !obj.address.lng) {
      const address = toAddressString(obj.address)
      marker = await parseAddressToLatLng(address)
    } else {
      marker = { lat: obj.address.lat, lng: obj.address.lng }
    }
    obj.lat = marker.lat
    obj.lng = marker.lng

    setModalContent({
      title: 'Detalle de Certificación',
      component: (
        <CertificationData onClick={() => handleEditPatientModal(obj)} certification={obj} />
      ),
    })
    setDetailOpen(true)
    setPointsToCheck([marker])
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d)
  }

  const setStartDate = (date) => {
    if (!isValidDate(new Date(`${date} 00:00:00`))) return null
    return new Date(`${date} 00:00:00`)
  }

  const setEndDate = (date) => {
    if (!isValidDate(new Date(`${date} 23:59:59`))) return null
    return new Date(`${date} 23:59:59`)
  }

  const validateStartEndDate = (startDate, endDate) => {
    if (!startDate || !endDate) {
      notifyToast('Debe ingresar fechas de inicio y fin válidas', 'warning')
      return false
    }

    const maxEndDate = new Date(startDate)
    maxEndDate.setDate(maxEndDate.getDate() + 31) // Agregar 30 días al startDate

    if (endDate < startDate) {
      notifyToast('La fecha de fin no puede ser anterior a la de inicio', 'warning')
      return false
    }

    if (endDate > maxEndDate) {
      notifyToast('El período de certificación no puede tener más de 30 días', 'warning')
      return false
    }

    return true
  }

  // handle Medic Act update.
  const handleSaveCertificationClick = async (obj) => {
    const {
      startDate,
      endDate,
      issuedDate,
      externalCertificateCheck,
      doctorNameExt,
      startDateExt,
      endDateExt,
      homeLeave,
      pathologyId,
      comments,
    } = obj.medicAct

    const updateFields = {
      medicAct: {
        startDate: setStartDate(startDate),
        endDate: setEndDate(endDate),
        issuedDate: setStartDate(issuedDate),
        externalCertificateCheck: externalCertificateCheck || null,
        doctorNameExt: doctorNameExt || null,
        startDateExt: setStartDate(startDateExt),
        endDateExt: setEndDate(endDateExt),
        homeLeave: homeLeave || null,
        pathologyId: pathologyId || null,
        comments: comments || null,
      },
      status: 2,
      updatedDate: new Date(),
    }
    const validate = validateStartEndDate(
      updateFields.medicAct.startDate,
      updateFields.medicAct.endDate
    )

    if (validate) {
      const certificationRef = doc(db, 'certifications', obj.ref.id)
      await setDoc(certificationRef, updateFields, { merge: true })
        .then(() => notifyToast('Certificación actualizada correctamente', 'success'))
        .then(setTimeout(() => setModalOpen(false), 1000))
        .catch(() => notifyToast('Ha ocurrido un error', 'error'))

      setReload(!reload)
    }
  }
  // filter only 'pending' certifications.
  useEffect(() => {
    if (!certificationsTableItems || certificationsTableItems.length === 0) return

    !detailOpen &&
      certificationsTableItems.map(async ({ raw }) => {
        let newPoint
        const certification = raw

        if (!certification.address.lat || !certification.address.lng) {
          const address = toAddressString(certification.address)
          newPoint = await parseAddressToLatLng(address)
        } else {
          newPoint = {
            lat: certification.address.lat,
            lng: certification.address.lng,
          }
        }

        newPoint.ref = certification.ref
        newPoint.title = getNameFull(
          certification.patientData.firstname,
          certification.patientData.lastname
        )
        newPoint.raw = raw
        setPointsToCheck((pointsToCheck) => [...pointsToCheck, newPoint])

        return pointsToCheck
      })
  }, [empty, detailOpen])
  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // only show content when loaded.
  useEffect(() => {
    !empty && setLoaded(true)
  }, [empty])

  // reset markers when detailModal closes
  // useEffect(() => {
  //   !detailOpen && setReload(!reload) && setPointsToCheck((pointsToCheck) => [...pointsToCheck, newPoint])
  // }, [detailOpen])
  // console.log(pointsToCheck)

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
        handleIsCondensed={(value) => setIsCondensed(value)}
      >
        {loaded ? (
          <>
            <MapWrapper>
              <Map
                pointsToCheck={pointsToCheck}
                disableDrawingTools
                onMarkerClick={(obj) => handleDetailModal(obj)}
              />
            </MapWrapper>
            <StyledList
              items={certificationsTableItems}
              noItemsMessage="No hay certificaciones pendientes."
              onClick={(obj) => handleDetailModal(obj)}
            />
          </>
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>

      <BottomDrawer
        title={modalContent.title}
        isOpen={detailOpen}
        isCondensed={isCondensed}
        onChange={handleDetailOpen}
      >
        {modalContent.component}
      </BottomDrawer>
    </>
  )
}

export default DoctorsHomeScreen
