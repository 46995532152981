// returns state and district for address.
export const getStateCounty = (address) => {
  const { state, district } = address

  if (!state) return district
  if (!district) return state

  return `${district}, ${state}`
}

// returns address string string from object.
export const getAddress = (address) => {
  const { street, number, suite } = address

  if (!street) return 'No especifica'
  if (!number) return street

  if (suite) return `${street} ${number},  ${suite}`

  return `${street} ${number}`
}
