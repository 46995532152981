// dependencies.
import styled from 'styled-components'
// components.
import { Body } from '../Typography'
import Card from './Card'
// utils.
import { useDimensions } from '../../js/hooks'

/****************************/
/*                          */
/*    Business Card List    */
/*                          */
/****************************/

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
  flexWrap: 'nowrap',
  gap: '16px',
  margin: '0 -16px',
  overflowX: (props) => (props.isMobile ? 'hidden' : 'auto'),
  padding: '0 16px 16px',
  width: 'calc(100% + 32px)',
})

const ContentItem = styled(Body)({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  padding: '14px 8px',
  textAlign: 'center',
  width: '100%',
})

// main component.
const List = ({ items = [], noItemsMessage = 'No hay pendientes', onClick, className }) => {
  // hooks.
  const { isMobile } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper isMobile={isMobile} className={className}>
      {items.length ? (
        items.map((item, i) => <Card key={i} item={item} flat onEdit={() => onClick(item.raw)} />)
      ) : (
        <ContentItem>{noItemsMessage}</ContentItem>
      )}
    </Wrapper>
  )
}

export default List
