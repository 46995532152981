// dependencies.
import { useRef } from 'react'
import styled from 'styled-components'
// components.
import Label from '../Typography/Label'
// utils.
import styleguideHasKey from '../../js/styleguideHasKey'
import { theme } from '../../styleguide'
import { useHover } from '../../js/hooks'

/**************************/
/*                        */
/*    Button Component    */
/*                        */
/**************************/

// partials.
const Wrapper = styled.span(
  {
    alignItems: 'center',
    backgroundColor: (props) => props.background,
    borderRadius: '24px',
    boxShadow: (props) => (props.hovered ? theme.shadows.larger : theme.shadows.normal),
    cursor: (props) => (props.disabled ? 'not-allowed' : 'pointer'),
    display: 'flex',
    justifyContent: 'center',
    opacity: (props) => (props.hovered ? 0.6 : 1),
    textAlign: 'center',
    transition: 'all 200ms ease',
  },
  `@media print { display: none; }`
)

const StyledLabel = styled(Label)({
  borderRadius: '24px',
  padding: (props) => props.padding || '14px 24px',
  width: '100%',
  whiteSpace: 'nowrap',
})

const StyledButton = styled.button({
  appearance: 'none',
  alignItems: 'center',
  background: 'none',
  border: 'none',
  borderRadius: '24px',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  width: '100%',
})

// main component.
const Button = ({
  className,
  variant,
  color = 'white',
  background,
  href,
  to,
  hideLabel,
  padding,
  disabled,
  children,
  onClick,
}) => {
  // refs.
  const hoverRef = useRef(null)
  const isHover = useHover(hoverRef)

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // make sure color is included in the library.
  const { colors } = theme
  const variantBg = disabled ? 'grey' : variant === 'secondary' ? 'terciary' : 'primary'
  const backgroundColor = styleguideHasKey(background, colors, variantBg)

  // build props object.
  const props = {
    background: colors[backgroundColor],
    className,
    disabled,
    hovered: !disabled && isHover,
    ref: hoverRef,
    tabIndex: 0,
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper {...props}>
      {hideLabel ? (
        <StyledButton disabled={disabled} onClick={onClick}>
          {children}
        </StyledButton>
      ) : (
        <StyledLabel
          disabled={disabled}
          padding={padding}
          color={color}
          href={href}
          to={to}
          onClick={onClick}
        >
          {children}
        </StyledLabel>
      )}
    </Wrapper>
  )
}

export default Button
