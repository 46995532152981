// dependencies.
import { initializeApp } from 'firebase/app'
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from 'firebase/firestore'

// Firebase DEVELOP config.
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

// helpers.
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const { v4: uuidv4 } = require('uuid')

const activateUrl = `https://${firebaseConfig.authDomain}/activate`

// get superadmin user by id.
const getAdminById = async (id) => {
  return await getUserById(id, 'superadmin')
}

// get doctor user by id.
const getDoctorById = async (id) => {
  return await getUserById(id, 'doctor')
}

// get auth user by email.
const getUserByEmail = async (email) => {
  const usersRef = collection(db, 'users')
  const userQuery = query(usersRef, where('email', '==', email))
  const querySnapshot = await getDocs(userQuery)

  if (querySnapshot.docs.length === 0) {
    return false
  }

  return querySnapshot.docs[0]
}

// get auth user by id.
const getPatientById = async (id) => {
  const patientRef = collection(db, 'patients')
  const patientQuery = query(patientRef, where('id', '==', id))
  const querySnapshot = await getDocs(patientQuery)
  return querySnapshot.docs.length > 0 ? querySnapshot.docs[0] : false
}

// get auth user by id.
const getUserById = async (id, access = null, isActive = null) => {
  const usersRef = collection(db, 'users')
  let userQuery

  if (access) {
    userQuery = query(usersRef, where('uid', '==', id), where('access', '==', access))
  } else {
    userQuery = query(usersRef, where('uid', '==', id))
  }

  const querySnapshot = await getDocs(userQuery)

  return querySnapshot.docs.length > 0 ? querySnapshot.docs[0] : false
}

const getBusinessByRef = async (ref) => {
  const businessRef = collection(db, 'business')
  const businessQuery = query(businessRef, where('ref', '==', ref))
  const querySnapshot = await getDocs(businessQuery)

  if (querySnapshot.docs.length === 0) {
    return false
  }

  return querySnapshot.docs[0]
}

const getBusinessByRut = async (rut) => {
  const businessRef = collection(db, 'business')
  const businessQuery = query(businessRef, where('rut', '==', rut))
  const querySnapshot = await getDocs(businessQuery)

  if (querySnapshot.docs.length === 0) {
    return false
  }

  return querySnapshot.docs[0]
}

const getPathologyByCode = async (code) => {
  const pathologyRef = collection(db, 'pathologies')
  const pathologyQuery = query(pathologyRef, where('code', '==', code))
  const querySnapshot = await getDocs(pathologyQuery)

  if (querySnapshot.docs.length === 0) {
    return false
  }

  return querySnapshot.docs[0]
}

// --------------------------------------------------------------------------------------------- //
// AUTH FUNCTIONS                                                                                //
// --------------------------------------------------------------------------------------------- //

// SIGN-IN: firebase's signInWithEmailAndPassword().
const signInEmailPassword = async (email, password) => {
  let message = ''

  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    message = err.message
    console.error(err, err.message)
  }

  // return toast message.
  return message
}

// SIGN-IN: firebase's sendSignInLinkToEmail().
const sendSignInEmailLink = async (id, email) => {
  const actionCodeSettings = { url: `${activateUrl}?email=${email}`, handleCodeInApp: true }

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
  } catch (err) {
    console.error(err, err.message)
    await deleteDoc(doc(db, 'users', id))
    throw new Error('Ha ocurrido un error al crear el usuario')
  }
}

// SIGN-IN-RESET: firebase's sendPasswordResetEmail().
const sendPasswordReset = async (email) => {
  let message = ''

  try {
    await sendPasswordResetEmail(auth, email)
    message = 'sent'
  } catch (err) {
    message = err.message
  }

  // return toast message.
  return message
}

// SIGN-UP: firebase's createUserWithEmailAndPassword().
const signUpEmailPassword = async (values) => {
  const { firstname, lastname, email, phone, password } = values

  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user

    await setDoc(doc(db, 'users', user?.uid), {
      uid: user?.uid,
      firstname,
      lastname,
      email,
      phone,
    })
  } catch (err) {
    console.error(err, err.message)
  }
}

// SIGN-UP-ACTIVATE: firebase's createUserWithEmailAndPassword().
const activateUser = async (values) => {
  const { email, password } = values

  try {
    const usersRef = collection(db, 'users')
    const userQuery = query(usersRef, where('email', '==', email), where('isActive', '==', false))
    const querySnapshot = await getDocs(userQuery)

    if (querySnapshot.docs.length === 0) {
      return console.error('No matching user')
    }

    const userDoc = querySnapshot.docs[0]
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const newUser = res.user

    await copyUser(userDoc, newUser.uid)

    // Fetch user
    const user = await getUserById(newUser.uid)

    // Set new user ref
    await setDoc(doc(db, 'users', newUser.uid), { ...user.data(), ref: user.ref })
  } catch (err) {
    console.error(err, err.message)
  }
}

// --------------------------------------------------------------------------------------------- //
// CMS FUNCTIONS                                                                                 //
// --------------------------------------------------------------------------------------------- //

// CMS-CREATE-USER: generate uid, check if email exists, then sendSignInEmailLink().
const createUser = async (values) => {
  const { firstname, lastname, email, phone, access, businessRef } = values

  // generate uid.
  const uid = uuidv4()
  // check if email exists.
  const userAlreadyExists = await getUserByEmail(email)

  if (userAlreadyExists) {
    throw new Error('Ya existe un usuario con el email ingresado')
  }

  try {
    // use the generated uid.
    const userRef = doc(collection(db, 'users'), uid)

    await setDoc(userRef, {
      uid,
      firstname,
      lastname: lastname || '',
      email,
      phone,
      access,
      isActive: false,
      createdDate: new Date(),
      updatedDate: new Date(),
      ...(businessRef && businessRef !== {} && { businessRef }),
    })

    await sendSignInEmailLink(uid, email)

    return userRef
  } catch (err) {
    console.error(err, err.message)
    throw new Error('Ha ocurrido un error al crear el usuario')
  }
}

// CMS-COPY-USER: get the data of temp user and activate user.
const copyUser = async (oldUserDoc, newUserId) => {
  const oldUserData = oldUserDoc.data()
  oldUserData.isActive = true
  oldUserData.uid = newUserId

  // create new user in db.
  await setDoc(doc(db, 'users', newUserId), oldUserData)
  // delete user temp.
  await deleteDoc(doc(db, 'users', oldUserDoc.id))
}

export {
  auth,
  db,
  onAuthStateChanged,
  signOut,
  getAdminById,
  getBusinessByRef,
  getBusinessByRut,
  getDoctorById,
  signInEmailPassword,
  sendSignInEmailLink,
  sendPasswordReset,
  signUpEmailPassword,
  activateUser,
  createUser,
  getPatientById,
  getPathologyByCode,
}
