// dependencies.
import styled from 'styled-components'
// components.
import { FlatCard } from '../components/Card'
// utils.
import certificationLeaveFormat from '../js/certificationLeaveFormat'
import certificationStatusFormat from '../js/certificationStatusFormat'
import dateFormat from '../js/dateFormat'
import { getNameFull } from '../js/usernameUtils'

/*************************/
/*                       */
/*    Show Data Modal    */
/*                       */
/*************************/

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  margin: '0 auto',
  maxWidth: '416px',
  width: '100%',
})

// main component.
const MedicActData = ({ certification }) => {
  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // extract object keys.
  const { address, patientData, medicAct, status } = certification
  const { city, cornerstreet, district, number, state, street, suite, isHospital, indications } = address
  const {
    externalCertificateCheck,
    issuedDate,
    startDateExt,
    startDate,
    endDateExt,
    endDate,
    doctorNameExt,
    homeLeave,
    pathology,
    pathologyId,
    comments,
  } = medicAct

  // build data object.
  const data = [
    {
      icon: 'user',
      title: 'Datos del empleado',
      items: [
        {
          label: 'Nombre completo:',
          value: getNameFull(patientData.firstname, patientData.lastname, patientData.middlename),
        },
        {
          label: 'Cédula de identidad:',
          value: patientData.id,
        },
        {
          label: 'Nro. de Funcionario:',
          value: patientData.employeenumber,
        },
      ],
    },
    {
      icon: 'bookmark',
      title: `Dirección ${isHospital ? '(Hospital)' : ''}`,
      items: [
        {
          label: 'Calle y número:',
          value: `${street}, ${number}`,
        },
        {
          label: 'Apartamento / Oficina:',
          value: suite,
        },
        {
          label: 'Esquina:',
          value: cornerstreet,
        },
        {
          label: 'Ciudad y departamento:',
          value: city && state ? `${city || ''}, ${state || ''}` : `${city || ''} ${state || ''}`,
        },
        {
          label: 'Barrio / Localidad:',
          value: district,
        },
        {
          label: 'Indicaciones:',
          value: indications,
        }
      ],
    },
    {
      icon: 'favorite',
      title: 'Certificación',
      items: [
        {
          label: 'Certificación externa:',
          value: externalCertificateCheck ? 'Sí' : 'No',
        },
        {
          label: 'Médico externo:',
          value: doctorNameExt || '--',
        },
        {
          label: 'Fecha de emisión:',
          value: dateFormat(issuedDate),
        },
        {
          label: 'Estado',
          value: certificationStatusFormat(status),
        },
        {
          label: 'Período:',
          value: `${dateFormat(externalCertificateCheck ? startDateExt : startDate)} - ${dateFormat(
            externalCertificateCheck ? endDateExt : endDate
          )}`,
        },
        {
          label: 'Salida domiciliaria:',
          value: certificationLeaveFormat(homeLeave),
        },
        {
          label: 'Patología:',
          value: pathology || pathologyId,
        },
        {
          label: 'Comentarios:',
          value: comments,
        },
      ],
    },
    {
      icon: 'edit',
      title: 'Notas y comentarios',
      items: [certification.comments],
    },
  ]

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper>
      {data.map((item) => (
        <FlatCard key={item.title} {...item} />
      ))}
    </Wrapper>
  )
}

export default MedicActData
