// dependencies.
import { useEffect, useState } from 'react'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import Table from '../../components/Table'
import { Loader } from '../../components/Icon'
import { FilterHeader } from '../../components/Layout'
// utils.
import search from '../../js/search'
// constants.
import {
  useBusinessCertificationsData,
  usePathologiesData,
  usePatientsData,
  useUserBusinessData,
} from '../../js/hooks/data'

/*************************************/
/*                                   */
/*    Business Certifications Screen    */
/*                                   */
/*************************************/

// helpers.
const tableHeader = [
  { label: 'Solicitud', grow: 1 },
  { label: 'Estado' },
  { label: 'Documento', grow: 1 },
  { label: 'Paciente', grow: 2 },
  { label: 'Inicio' },
  { label: 'Fin' },
  { label: 'Patología', grow: 1 },
  { label: 'Salida' },
  { label: 'Realizado' },
]

// main component.
const BusinessCertificationsScreen = ({ navMenu, navTitle }) => {
  const [filteredValues, setFilteredValues] = useState({})
  // hooks.
  const { business, emptyBusiness} = useUserBusinessData()
  const { patients } = usePatientsData()
  const { pathologies } = usePathologiesData()
  const { certificationsTableItems, empty } = useBusinessCertificationsData(
    business,
    patients,
    pathologies,
    null,
    filteredValues
  )
  // states.
  const [loaded, setLoaded] = useState(false)
  const [searched, setSearched] = useState(certificationsTableItems)

  // ------------------------------------------------------------------------------------------- //
  // SEARCH                                                                                      //
  // ------------------------------------------------------------------------------------------- //

  // search for items in the array.
  const handleSearch = (term) => {
    setSearched(search(certificationsTableItems, term))
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // Without initially setting and clearing search on resourceLoaded in this way, causes asset list
  // children to draw incorrectly, with logos swapped out between assets, reason not known.
  // Removing sort removes the need for this.
  useEffect(() => {
    handleSearch('')
  }, [certificationsTableItems])

  // only show content when loaded.
  useEffect(() => {
    (!empty || !emptyBusiness) && setLoaded(true)
  }, [empty, emptyBusiness])

  // return content.
  return (
    <>
      <AdminTemplate navigation={navMenu} sectionTitle={navTitle}>
        <FilterHeader
          placeholder="Buscar por: cédula, nombre, dirección, estado..."
          onChange={(term) => handleSearch(term)}
          onFilterClick={(value) => setFilteredValues(value)}
        />

        {loaded ? <Table header={tableHeader} items={searched} /> : <Loader />}
      </AdminTemplate>
    </>
  )
}

export default BusinessCertificationsScreen
