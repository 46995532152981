// dependencies.
import { useEffect, useMemo, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { GoogleMap, Marker, DrawingManager } from '@react-google-maps/api'
// components.
import { Button } from '../../components/Button'
// utils.
import { theme } from '../../styleguide'
import { useDimensions } from '../../js/hooks'

/***********************/
/*                     */
/*    Map Component    */
/*                     */
/***********************/

// helpers.
const isPointInPolygon = (point, polygon) => {
  return window.google.maps.geometry.poly.containsLocation(
    new window.google.maps.LatLng(point.lat, point.lng),
    polygon
  )
}

// partials.
const StyledButton = styled(Button)({
  bottom: '32px',
  left: '32px',
  position: 'absolute',
  zIndex: 2,
})

// partials.
const DrawingManagerStyle = createGlobalStyle`
  .gmnoprint button {
    align-items: center;
    appearance: none;
    background: none;
    border: 0px;
    cursor: pointer;
    display: flex;
    height: 40px;
    left: 0px;
    justify-content: center;
    margin: 0px;
    overflow: hidden;
    padding: 0px;
    position: relative;
    text-align: center;
    text-transform: none;
    top: 0px;
    user-select: none;
    width: 40px;
  }
  .gmnoprint button > span > div,
  .gmnoprint button > span > div img {
    min-height: 18px;
    min-width: 18px;
  }
`

// main component.
const Map = ({
  pointsToCheck = [],
  greenPoints = [],
  pointsInside,
  setPointsInside,
  disableDrawingTools = false,
  zoom = 13,
  style,
  onChange = () => {},
  onMarkerClick = () => {},
  enableMoveMarker = false,
  onChangeLatLng = () => {},
}) => {
  // hooks.
  const { height } = useDimensions()
  // data objects.
  const singlePoint = enableMoveMarker && pointsToCheck.length === 1
  // states.
  const [polygon, setPolygon] = useState(null)
  const [isMarkerShown, setMarkerShown] = useState(!singlePoint)
  const [markerPosition, setMarkerPosition] = useState(null)
  const [isLocationConfirmed, setLocationConfirmed] = useState(true)

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle update polygon drawing.
  const handlePolygonComplete = (newPolygon) => {
    if (polygon) polygon.setMap(null) // delete old polygon.
    setPolygon(newPolygon)
  }

  // update marker position
  const onMapClick = (e) => {
    if (singlePoint) {
      setMarkerPosition(e.latLng)
    }
    setLocationConfirmed(false)
    setMarkerShown(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // make sure map is centered correctly.
  let center = useMemo(() => ({ lat: -34.8991965, lng: -56.1265591 }), [])
  if (pointsToCheck.length === 1) center = pointsToCheck[0]

  // build props object.
  const mapProps = {
    mapContainerStyle: {
      height: `${height / 2}px`,
      maxHeight: '100%',
      minHeight: '400px',
      ...style,
    },
    options: {
      fullscreenControl: false,
      keyboardShortcuts: false,
      mapTypeControl: false,
      streetViewControl: false,
    },
    clickableIcons: false,
    zoom,
    center,
    onClick: (e) => onMapClick(e),
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // update points inside the polygon.
  useEffect(() => {
    if (!polygon || pointsToCheck.length === 0) return
    const newPointsInside = pointsToCheck.filter((point) => isPointInPolygon(point, polygon))
    setPointsInside(newPointsInside)
  }, [polygon, pointsToCheck])

  useEffect(() => {
    // Delete last polygon
    if (polygon && pointsInside.length === 0) {
      polygon.setMap(null)
    }
  }, [pointsInside])

  // reset single point.
  useEffect(() => {
    if (singlePoint) {
      setMarkerPosition(pointsToCheck[0])
    }
    setMarkerShown(true)

    return () => {
      setMarkerShown(false)
      setMarkerPosition(null)
    }
  }, [pointsToCheck])

  // propagate value.
  useEffect(() => {
    if (singlePoint && isMarkerShown && markerPosition) onChange(markerPosition)
  }, [singlePoint, isMarkerShown, markerPosition])

  const handleConfirmLocation = () => {
    onChangeLatLng(markerPosition)
    setLocationConfirmed(true)
  }

  // return content.
  return (
    <>
      <GoogleMap {...mapProps}>
        {isMarkerShown && pointsToCheck && pointsToCheck.length > 0
          ? pointsToCheck.map((point, index) => (
              <Marker
                key={index}
                position={singlePoint && markerPosition ? markerPosition : point}
                title={point.title || ''}
                onClick={() => onMarkerClick({ ...point.raw, ...point })}
              />
            ))
          : null}
        {isMarkerShown && greenPoints && greenPoints.length > 0
          ? greenPoints.map((point, index) => (
              <Marker
                key={index}
                position={singlePoint && markerPosition ? markerPosition : point}
                title={point.title || ''}
                onClick={() => onMarkerClick({ ...point.raw, ...point })}
                icon={{
                  url: `http://maps.google.com/mapfiles/ms/icons/green-dot.png`,
                  scaledSize: new window.google.maps.Size(32, 32),
                }}
              />
            ))
          : null}
        {!disableDrawingTools ? (
          <DrawingManager
            options={{
              drawingMode: 'polygon',
              drawingControl: true,
              drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ['polygon'],
              },
              polygonOptions: {
                fillColor: theme.colors.alert,
                fillOpacity: 0.32,
                strokeColor: theme.colors.alert,
                strokeWeight: 2,
                clickable: false,
                editable: true,
                zIndex: 1,
              },
            }}
            onPolygonComplete={handlePolygonComplete}
          />
        ) : null}
        {isMarkerShown && enableMoveMarker && !isLocationConfirmed && (
          <StyledButton onClick={handleConfirmLocation}>Confirmar ubicación</StyledButton>
        )}
      </GoogleMap>

      {/* Global styles */}
      <DrawingManagerStyle />
    </>
  )
}

export default Map
