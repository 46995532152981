// dependencies.
import styled from 'styled-components'
// components.
import { Icon } from '../Icon'
import { Headline, Item, Body } from '..//Typography'
import { theme } from '../../styleguide'

/*****************************/
/*                           */
/*    Flat Card Component    */
/*                           */
/*****************************/

// partials.
const Wrapper = styled.div({
  alignItems: 'flex-start',
  background: theme.colors.white,
  borderRadius: `16px`,
  border: `1px solid ${theme.colors.ash}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: `16px`,
  transition: 'all 200ms ease',
  width: (props) => (props.autoCol ? 'calc(50% - 8px)' : '100%'),
})

const StyledHeadline = styled(Headline)({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  margin: '0 0 8px',
  flexGrow: '0',
})

const ItemsWrapper = styled.ul({
  flexGrow: 1,
  width: '100%',
})

const StyledItem = styled(Item)({
  margin: '4px 0',
})

// main component.
const FlatCard = ({ icon, title, items, autoCol = false, className, children }) => {
  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // only show if there's a title.
  if (!title) return null

  // return content.
  return (
    <Wrapper className={className} autoCol={autoCol}>
      <StyledHeadline>
        <Icon name={icon} size={16} />

        {title}
      </StyledHeadline>

      {items ? (
        <ItemsWrapper>
          {items.map((item, i) => {
            const { label, value } = item

            return label ? (
              <StyledItem key={i} label={label} value={value || ''} />
            ) : (
              <Body key={i}>{item}</Body>
            )
          })}
        </ItemsWrapper>
      ) : (
        children
      )}
    </Wrapper>
  )
}

export default FlatCard
