// dependencies.
import { useEffect, useState } from 'react'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import Table from '../../components/Table'
import Tabs from '../../components/Layout/Tabs'
import { Drawer } from '../../components/Modal'
import { Form } from '../../components/Form'
// utils.
import notifyToast from '../../js/notifyToast'
import search from '../../js/search'
import { createUser, db, getBusinessByRut } from '../../js/firebase/firebase'
import { Loader } from '../../components/Icon'
import { useBusinessData, useCertificationsData, usePatientsData } from '../../js/hooks/data'
// constants.
import { NEW_BUSINESS_FORM, UPDATE_BUSINESS_FORM } from '../Business/constants'
import { SET_BUSINESS_PATIENT_FORM } from './constants'
import { getNameFull } from '../../js/usernameUtils'

/******************************/
/*                            */
/*    Business Home Screen    */
/*                            */
/******************************/

// helpers.
const businessTableHeader = [
  { label: 'Razón Social', grow: 2 },
  { label: 'RUT', grow: 1 },
  { label: 'Dirección', grow: 2 },
  { label: 'Num. Func.' },
  { label: 'Certificaciones' },
  { label: 'Acciones', grow: 2 },
]

const employeesTableHeader = [
  { label: 'Cédula' },
  { label: 'Nro. Func.' },
  { label: 'Nombre Completo', grow: 2 },
  { label: 'Teléfono' },
  { label: 'Email', grow: 2 },
  { label: 'Acciones', grow: 2 },
]

// partials.
const Tab1 = ({ header = [], items = [], loaded, onEdit, onDelete }) =>
  loaded ? (
    <Table
      header={header}
      items={items}
      //
      enableEdit
      onEdit={onEdit}
      //
      enableDelete
      onDelete={onDelete}
    />
  ) : (
    <Loader />
  )

const Tab2 = ({ header = [], items = [], loaded, onEdit }) =>
  loaded ? (
    <Table
      header={header}
      items={items}
      //
      enableEdit
      onEdit={onEdit}
    />
  ) : (
    <Loader />
  )

// main component.
const AdminBusinessScreen = ({ navMenu, navTitle }) => {
  const [reload, setReload] = useState(false)
  // hooks.
  const { patients } = usePatientsData()
  const { certifications } = useCertificationsData(patients)
  const { businessTableItems, emptyBusiness, businessSelect } = useBusinessData(
    patients,
    certifications,
    reload
  )
  const { patientsWithoutBusinessTableItems } = usePatientsData(patients, certifications, reload)
  // data objects.
  const businessSelectOptions = businessSelect()
  // states.
  const [loaded, setLoaded] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [searchedBusiness, setSearchedBusiness] = useState(businessTableItems)
  const [searchedPatients, setSearchedPatients] = useState(patientsWithoutBusinessTableItems)

  // ------------------------------------------------------------------------------------------- //
  // SEARCH                                                                                      //
  // ------------------------------------------------------------------------------------------- //

  // search for items in the array.
  const handleBusinessSearch = (term) => {
    setSearchedBusiness(search(businessTableItems, term))
  }

  const handlePatientSearch = (term) => {
    setSearchedPatients(search(patientsWithoutBusinessTableItems, term))
  }

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  // handle add new business modal.
  const handleNewBusinessModal = () => {
    setModalContent({
      title: 'Agregar nueva empresa',
      component: (
        <Form items={NEW_BUSINESS_FORM} onClick={(obj) => handleCreateBusinessClick(obj)} />
      ),
    })

    setModalOpen(true)
  }

  // handle edit business information modal.
  const handleEditBusinessModal = (obj) => {
    setModalContent({
      title: 'Actualizar empresa',
      component: (
        <Form
          items={UPDATE_BUSINESS_FORM}
          defaultValues={obj}
          onClick={(obj) => handleSaveBusinessClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // handle edit selected employee modal.
  const handleEditEmployeeModal = async (obj) => {
    obj.patientData.fullname = getNameFull(obj.patientData?.firstname, obj.patientData?.lastname)
    setModalContent({
      title: 'Actualizar Empleado',
      component: (
        <Form
          items={SET_BUSINESS_PATIENT_FORM(businessSelectOptions)}
          defaultValues={obj}
          onClick={(obj) => handleUpdateEmployeeClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new business.
  const handleCreateBusinessClick = async (obj) => {
    if (obj.email) {
      const alreadyExists = await getBusinessByRut(obj.rut)
      if (alreadyExists) {
        notifyToast('Ya existe una empresa con ese rut', 'warning')
        return
      }

      await addDoc(collection(db, 'business'), { ...obj })
        .then(async (businessRef) => {
          await setDoc(businessRef, { ref: businessRef }, { merge: true })

          const newUser = {
            firstname: obj.name,
            lastname: '',
            email: obj.email,
            access: 'company',
            phone: obj.address.phone || '',
            businessRef,
          }

          await createUser(newUser)
            .then(() => notifyToast('Empresa guardada correctamente', 'success'))
            .catch(() => notifyToast('Ha ocurrido un error', 'error'))
        })
        .catch(() => notifyToast('Ha ocurrido un error', 'error'))

      setModalOpen(false)
      setReload(!reload)
    }
  }

  // handle business information update.
  const handleSaveBusinessClick = async (obj) => {
    const { id, ...businessObj } = obj
    const businessRef = doc(db, 'business', obj.ref.id)

    await setDoc(businessRef, businessObj)
      .then(() => notifyToast('Empresa editada correctamente', 'success'))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  const handleUpdateEmployeeClick = async (obj) => {
    const employeeRef = doc(db, 'patients', obj.id)
    const updateFields = {
      businessRef: [obj.businessRef],
      updatedDate: new Date(),
    }

    await setDoc(employeeRef, updateFields, { merge: true })
      .then(() => notifyToast('Empleado guardado correctamente', 'success'))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // Without initially setting and clearing search on resourceLoaded in this way, causes asset list
  // children to draw incorrectly, with logos swapped out between assets, reason not known.
  // Removing sort removes the need for this.
  useEffect(() => {
    if (!businessTableItems && !patientsWithoutBusinessTableItems) return
    handleBusinessSearch('')
    handlePatientSearch('')
  }, [businessTableItems, patientsWithoutBusinessTableItems])

  // only show content when loaded.
  useEffect(() => {
    !emptyBusiness && setLoaded(true)
  }, [emptyBusiness])

  const tabLabels = [
    {
      label: 'Empresas',
      component: (
        <Tab1
          loaded={loaded}
          header={businessTableHeader}
          items={searchedBusiness}
          onEdit={(obj) => handleEditBusinessModal(obj)}
          onDelete={(obj) => console.error(obj)}
        />
      ),
      actionLabel: 'Agregar empresa',
      placeholder: 'Buscar por nombre, rut, departamento, localidad, calle...',
      onActionClick: () => handleNewBusinessModal(),
      onChange: (term) => handleBusinessSearch(term),
    },
    {
      label: 'Pacientes sin empresa',
      component: (
        <Tab2
          loaded={loaded}
          header={employeesTableHeader}
          items={searchedPatients}
          onEdit={(obj) => handleEditEmployeeModal(obj)}
        />
      ),
      placeholder: 'Buscar por cédula, nombre, dirección...',
      onChange: (term) => handlePatientSearch(term),
    },
  ]

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        <Tabs items={tabLabels} onClick={(value) => console.warn(value)} />
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default AdminBusinessScreen
