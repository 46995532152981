// dependencies.
import { useEffect, useState } from 'react'

import { doc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import Table from '../../components/Table'
import { Drawer } from '../../components/Modal'
import { Form } from '../../components/Form'
import { Loader } from '../../components/Icon'
import { FilterHeader } from '../../components/Layout'
// utils.
import notifyToast from '../../js/notifyToast'
import search from '../../js/search'
import { db, getPathologyByCode } from '../../js/firebase/firebase'
import { usePathologiesData } from '../../js/hooks/data'
// constants.
import { NEW_PATHOLOGIE_FORM } from './constants'

/**********************************/
/*                                */
/*    Admin Pathologies Screen    */
/*                                */
/**********************************/

// helpers.
const tableHeader = [
  { label: 'Código' },
  { label: 'Nombre público', grow: 2 },
  { label: 'Nombre confidencial', grow: 2 },
  { label: 'Es clínica' },
  { label: 'Activa' },
  { label: 'Acciones', grow: 2 },
]

// main component.
const AdminPathologiesScreen = ({ navMenu, navTitle }) => {
  const [reload, setReload] = useState(false)
  // hooks.
  const { pathologies, pathologiesTable, empty } = usePathologiesData(reload)
  // data object.
  const pathologiesTableitems = pathologiesTable()
  // states.
  const [loaded, setLoaded] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [searched, setSearched] = useState(pathologiesTableitems)

  // ------------------------------------------------------------------------------------------- //
  // SEARCH                                                                                      //
  // ------------------------------------------------------------------------------------------- //

  // search for items in the array.
  const handleSearch = (term) => {
    setSearched(search(pathologiesTableitems, term))
  }

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  // handle add new pathology modal.
  const handleNewPathologyModal = () => {
    setModalContent({
      title: 'Agregar nueva patología',
      component: (
        <Form items={NEW_PATHOLOGIE_FORM} onClick={(obj) => handleCreatePathologyClick(obj)} />
      ),
    })

    setModalOpen(true)
  }

  // handle edit selected pathology modal.
  const handleEditPathologyModal = (obj) => {
    setModalContent({
      title: 'Actualizar patología',
      component: (
        <Form
          items={NEW_PATHOLOGIE_FORM}
          defaultValues={obj}
          onClick={(obj) => handleSavePathologyClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new pathology.
  const handleCreatePathologyClick = async (obj) => {
    const uid = obj.code

    const alreadyExists = await getPathologyByCode(uid)

    if (alreadyExists) {
      notifyToast('Ya existe una patología con el código ingresado', 'warning')
      return
    }
    obj.createdDate = new Date()
    obj.updatedDate = new Date()

    await setDoc(doc(db, 'pathologies', uid), obj)
      .then(() => notifyToast('Patología guardada correctamente', 'success'))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // handle pathology information update.
  const handleSavePathologyClick = async (obj) => {
    obj.updatedDate = new Date()
    const pathologyRef = doc(db, 'pathologies', obj.code)
    await setDoc(pathologyRef, { ...obj })
      .then(() => notifyToast('Patología guardada correctamente', 'success'))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // const handlePathologiesRefresh = {
  //   setLoaded(false)
  // }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // Without initially setting and clearing search on resourceLoaded in this way, causes asset list
  // children to draw incorrectly, with logos swapped out between assets, reason not known.
  // Removing sort removes the need for this.
  useEffect(() => {
    handleSearch('')
  }, [pathologies])

  // only show content when loaded.
  useEffect(() => {
    !empty && setLoaded(true)
  }, [empty])

  // refresh atempt 1

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        <FilterHeader
          label="Agregar patología"
          placeholder="Buscar por: nombre confidencial, nombre público, código..."
          onActionClick={() => handleNewPathologyModal()}
          onChange={(term) => handleSearch(term)}
        />

        {loaded ? (
          <Table
            header={tableHeader}
            items={searched}
            //
            enableEdit
            onEdit={(obj) => handleEditPathologyModal(obj)}
          />
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default AdminPathologiesScreen
