// dependencies.
import { useMemo, useState } from 'react'
import { getDocs } from 'firebase/firestore'
// constants.
import { IS_DEV } from '../../constants'

// main function.
const useFetch = ({ q, access, errorMge, condition, user, userData, name }, deps = []) => {
  // states.
  const [data, setData] = useState([])
  const [empty, setEmpty] = useState(true)

  // fetch firebase database.
  const fetch = async () => {
    if (!user || !q || !access.includes(userData.access) || !name) return
    if (condition) return

    try {
      const doc = await getDocs(q)

      if (doc.empty) {
        console.error(errorMge || `No se encontraron resultados. [${name}]`)
        setEmpty(false)
        return
      }

      const dataCheck = {}
      doc.forEach((dataObj) => {
        dataCheck[dataObj.id] = dataObj.data()
      })

      const dataArr = Object.values(dataCheck)

      // log requests in dev.
      IS_DEV && console.error(q._query.path.segments)

      setData(dataArr)
      setEmpty(false)
    } catch (err) {
      console.error(err, 'An error occured while fetching data.')
    }
  }

  useMemo(() => {
    if ((userData !== {} && !!access.includes(userData.access)) || !name) fetch()

    return () => fetch()
  }, [...deps])

  return { data, empty }
}

export default useFetch
