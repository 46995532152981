// dependencies.
import moment from 'moment'
// constants.
import {
  DATE_FORMAT,
  US_DATE_FORMAT,
  MONTHS_ES,
  MONTHS_SHORT_ES,
  DAYS_ES,
  DAYS_SHORT_ES,
  DAYS_MIN_ES,
} from '../constants'

// us date specific.
export const dateFormatUS = (date, format = US_DATE_FORMAT) => {
  // if no date, then use current date.
  if (!date) return new Date()

  let d

  // parse date input
  if (date instanceof Date && !isNaN(date)) {
    d = date
  } else {
    d = new Date(date)
  }

  // parse date from firebase.
  if (date?.seconds !== null && date?.seconds !== undefined) d = new Date(date.seconds * 1000)

  // init moment.js instance.
  const localLocale = moment(d)

  return localLocale.format(format)
}

// main function.
const dateFormat = (date, format = DATE_FORMAT) => {
  // if no date, then use current date.
  if (!date) return new Date()

  let d

  // parse date input
  if (date instanceof Date && !isNaN(date)) {
    d = date
  } else {
    d = new Date(date)
  }

  // parse date from firebase.
  if (date?.seconds !== null && date?.seconds !== undefined) d = date.seconds * 1000

  // init moment.js instance.
  const localLocale = moment(d)

  // update moment.js locale.
  moment.updateLocale('es', {
    months: MONTHS_ES.split('_'),
    monthsShort: MONTHS_SHORT_ES.split('_'),
    weekdays: DAYS_ES.split('_'),
    weekdaysShort: DAYS_SHORT_ES.split('_'),
    weekdaysMin: DAYS_MIN_ES.split('_'),
  })

  return localLocale.format(format)
}

export default dateFormat
