// dependencies.
import { createContext, useContext, useEffect, useState } from 'react'
import { query, collection, getDocs, where, doc } from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useLoadScript } from '@react-google-maps/api'
import { useNavigate } from 'react-router'
// utils.
import {
  activateUser,
  auth,
  db,
  sendPasswordReset,
  signInEmailPassword,
  signOut,
  signUpEmailPassword,
} from '../firebase/firebase'
import { jsonP, jsonS } from '../jsonMethods'
// constants.
import { GMAPS_API_KEY, GMAP_LIBRARIES } from '../../constants'

// define context.
const AuthContext = createContext()

// define Auth hook.
const useAuth = () => useContext(AuthContext)

/*********************************/
/*                               */
/*    Authentication Provider    */
/*                               */
/*********************************/

const AuthProvider = ({ children }) => {
  // hooks.
  const navigate = useNavigate()
  const { isLoaded } = useLoadScript({ googleMapsApiKey: GMAPS_API_KEY, libraries: GMAP_LIBRARIES })
  // states.
  const [user, loading] = useAuthState(auth)
  const [userData, setUserData] = useState({})

  // init and update values for firebase default methods:
  // send reset password email.
  const sendReset = (email) => sendPasswordReset(email)
  // log user out and remove localstorage data.
  const sendOut = () => {
    setUserData({})
    localStorage.removeItem('medilab-cert.userData')
    signOut(auth)
  }
  // log user in using email and password.
  const signIn = (email, password) => signInEmailPassword(email, password)
  // register new user using email and password.
  const signUp = (values) => signUpEmailPassword(values)
  // activate user account from email validator.
  const activate = (values) => activateUser(values)

  // ------------------------------------------------------------------------------------------- //
  // FETCH DATABASE DATA                                                                         //
  // ------------------------------------------------------------------------------------------- //

  // Fetch data from users firebase's collection. If user changes, update userData object.
  // If user object is empty, trigger sign out, then redirect user to the sign-in screen.
  const fetchUser = async () => {
    if (!user || loading) return

    try {
      const q = query(collection(db, 'users'), where('uid', '==', user?.uid))
      const doc = await getDocs(q)

      if (doc.empty) {
        sendOut()
        navigate('/signin')
        console.error('No matching documents')
        return
      }

      const data = { ...doc.docs[0].data(), ref: doc.docs[0].ref }
      updateUserData(data)
    } catch (err) {
      console.error(err, 'An error occured while fetching user data')
      throw new Error(err)
    }
  }

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // filter data from userData object and update state and localstorage.
  const updateUserData = (data) => {
    const { firstname, lastname, email, uid, access, phone } = data

    setUserData(data)

    localStorage.setItem(
      'medilab-cert.userData',
      jsonS({
        firstname,
        lastname,
        email,
        uid,
        access,
        phone,
        businessId: data.businessRef?.id,
      })
    )
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // build data object.
  useEffect(() => {
    const localStorageData = localStorage.getItem('medilab-cert.userData')

    if (localStorageData && jsonP(localStorageData)) {
      const userDataLocal = jsonP(localStorageData)
      const userRef = doc(collection(db, 'users'), userDataLocal.uid)

      if (userDataLocal.businessId) {
        const businessRef = doc(collection(db, 'business'), userDataLocal.businessId)
        setUserData({ ...userDataLocal, ref: userRef, businessRef })
      } else {
        setUserData({ ...userDataLocal, ref: userRef })
      }
    } else {
      fetchUser()
    }
  }, [user, loading])

  // return values.
  const value = {
    user,
    userData,
    updateUserData,
    signIn,
    signUp,
    sendOut,
    sendReset,
    activate,
    isLoaded,
  }

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
}

export { useAuth }

export default AuthProvider
