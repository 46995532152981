// dependencies.
import { collection, orderBy, query, where } from 'firebase/firestore'
// utils.
import certificationStatusFormat from '../../certificationStatusFormat'
import dateFormat from '../../dateFormat'
import useFetch from '../useFetch'
import { db } from '../../firebase/firebase'
import { getNameFull } from '../../usernameUtils'
import { jsonS } from '../../jsonMethods'
import { useAuth } from '../../contexts/AuthContext'
import { useEffect, useState } from 'react'
// constants.
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../constants'

/************************************/
/*                                  */
/*    Doctor Certifications Data    */
/*                                  */
/************************************/

const useDoctorCertificationsData = (patients, reload, access = ['doctor', 'developer']) => {
  // contexts.
  const { user, userData } = useAuth()

  const [certificationShowHistoryTableItems, setCertificationShowHistoryTableItems] = useState([])

  // ------------------------------------------------------------------------------------------- //
  // FETCH DATABASE DATA                                                                         //
  // ------------------------------------------------------------------------------------------- //

  // database query.
  const q = query(
    collection(db, 'certifications'),
    where('doctorRef', '==', userData.ref),
    where('status', '!=', 4),
    orderBy('status', 'asc'),
    orderBy('createdDate', 'asc')
  )

  // fetch doctor certifications from firebase's collection.
  const { data, empty } = useFetch(
    {
      ...(userData && userData.ref && { q }),
      access,
      user,
      userData,
      name: 'DoctorCertifications',
    },
    [reload]
  )

  const processCertificationData = (certificationsSet = data) => {
    certificationsSet.map((certificationObj) => {
      const patient = patients.filter((p) => p.id === certificationObj.patientRef.id)[0]
      const { firstname, middlename, lastname, address, id } = patient
      const patientData = {
        firstname,
        middlename,
        lastname,
        address,
        id,
      }
      certificationObj.patientData = patientData
      return certificationObj
    })
  }

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // filter active certifications for Doctor Home page.
  const certificationsTable = (certificationsSet = data) => {
    // filter only 'active' certifications.
    const newSet = certificationsSet.filter((certificationObj) => {
      if (
        certificationsSet.length === 0 ||
        typeof certificationObj.status !== 'number' ||
        certificationObj.status !== 1
      )
        return null
      return certificationObj
    })

    // table items.
    return (
      newSet &&
      newSet.map((obj) => {
        const { createdDate, comments, address, patientData } = obj

        const maybeShowName =
          (!!patientData?.firstname || !!patientData?.lastname) &&
          getNameFull(patientData?.firstname, patientData?.lastname)

        return {
          name: maybeShowName || 'Paciente',
          items: [
            {
              icon: 'calendar',
              copy: createdDate ? dateFormat(createdDate, 'D [de] MMMM, YYYY') : '',
              grow: true,
            },
            {
              icon: 'bookmark',
              copy: `${address?.street} ${address?.number}, ${address.city || address.state}`,
              grow: true,
            },
            { icon: 'user', copy: address?.phone },
            { icon: 'title', copy: comments, grow: true },
          ],
          raw: obj,
        }
      })
    )
  }

  // filter certifications history for Doctor History page.
  const certificationShowHistoryTable = (certificationsSet = data) => {
    return certificationsSet.map((certificationObj) => {
      const { createdDate, status, patientData, medicAct } = certificationObj

      const newSet = data.filter((obj) => {
        return jsonS(obj.patientRef) === jsonS(certificationObj.patientRef) && obj.patientRef
      })

      // table items.
      return {
        items: [
          {
            copy: createdDate ? dateFormat(createdDate, DATE_TIME_FORMAT) : '',
            grow: 1,
            label: 'Solicitada:',
            icon: 'calendar',
          },
          {
            copy: certificationStatusFormat(status, userData?.access),
            type: 'pill',
            pillBackground: status,
            icon: 'bookmark',
          },
          {
            copy: patientData.id,
            grow: 1,
            label: 'Documento:',
            icon: 'user',
          },
          {
            copy: getNameFull(
              patientData?.firstname,
              patientData?.lastname,
              patientData?.middlename
            ),
            grow: 2,
            icon: 'user',
          },
          {
            copy:
              medicAct && medicAct.issuedDate ? dateFormat(medicAct.issuedDate, DATE_FORMAT) : '',
            label: 'Realizado:',
            icon: 'calendar',
          },
        ],
        disableMedicAct: !!certificationObj.medicAct,
        disableShowHistory: !newSet.length,
        disableShowMedicAct: !certificationObj.medicAct,
        disableEdit: (status === 1 && !certificationObj.medicAct) || status >= 3,
        raw: { ...certificationObj, certifications: newSet },
      }
    })
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  useEffect(() => {
    if (!data || !patients) return
    processCertificationData()
  }, [data, patients])

  useEffect(() => {
    if (!data) return
    setCertificationShowHistoryTableItems(certificationShowHistoryTable())
  }, [data])

  // return values.
  return {
    certifications: data,
    certificationsTable,
    certificationShowHistoryTable,
    certificationShowHistoryTableItems,
    empty,
  }
}

export default useDoctorCertificationsData
