// dependencies.
import { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
// components.
import { Body, Label } from '../../components/Typography'
import { Icon } from '../../components/Icon'
// utils.
import setBackgroundColor from '../../js/setBackgroundColor'
import { theme } from '../../styleguide'
import { useAuth } from '../../js/contexts/AuthContext'
import { useDimensions } from '../../js/hooks'
import { useNavigate } from 'react-router'

/******************************/
/*                            */
/*    Navigation Component    */
/*                            */
/******************************/

// partials.
const NavigationWrapper = styled.div(
  {
    alignItems: 'flex-start',
    background: (props) => props.background,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    left: 0,
    maxHeight: (props) => (props.isMobile ? 'calc(100vh - 48px)' : 'calc(100vh - 72px)'),
    minWidth: (props) => (props.condensed ? '56px' : '184px'),
    overflow: 'auto',
    padding: '0 16px 56px',
    position: 'fixed',
    top: (props) => (props.isMobile ? '48px' : '72px'),
    transition: 'all 200ms ease',
    width: (props) => (props.condensed ? '56px' : '184px'),
  },
  `@media print { display: none; }`
)

const TitleWrapper = styled.div({
  alignItems: 'center',
  cursor: (props) => (props.isMobile ? 'default' : 'pointer'),
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '80px',
  padding: '30px 0',
  width: '100%',
})

const Title = styled(Body)({
  textTransform: 'uppercase',
  width: (props) => (props.condensed ? 0 : '100%'),
})

const Item = styled(Label)({
  alignItems: 'center',
  cursor: (props) => (props.active ? 'text' : 'pointer'),
  display: 'flex',
  padding: '14px 0',
  position: 'relative',
  width: '100%',
})

const SubTitle = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  margin: '16px 0 8px',
  opacity: 0.6,
  pointerEvents: 'none',
  width: '100%',
})

const Divider = styled.hr({
  backgroundColor: theme.opacity.white10,
  border: 'none',
  display: 'block',
  height: '2px',
  minHeight: '1px',
  pointerEvents: 'none',
  width: '100%',
})

const Spacer = styled.span({
  display: 'block',
  height: '100%',
  margin: '8px 0',
  pointerEvents: 'none',
  width: '100%',
})

const ActiveIndicator = styled.span({
  backgroundColor: theme.colors.primary,
  borderRadius: '0 4px 4px 0',
  display: 'block',
  height: '8px',
  left: '-16px',
  position: 'absolute',
  width: '8px',
})

// main component.
const Navigation = ({
  className,
  title,
  items,
  footNote,
  expanded,
  condensed = false,
  background,
  onClick,
}) => {
  // hooks.
  const navigate = useNavigate()
  const { isMobile } = useDimensions()
  const { userData } = useAuth()
  // states.
  const [activePath, setActivePath] = useState('')

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle redirect depending on user access.
  const handleAccess = (pathname) => {
    if (!pathname.startsWith('/admin', 0) && userData.access === 'superadmin') navigate('/admin')
    if (!pathname.startsWith('/empresas', 0) && userData.access === 'company') navigate('/empresas')
    if (!pathname.startsWith('/doctores', 0) && userData.access === 'doctor') navigate('/doctores')
    if (!pathname.startsWith('/doctor-admin', 0) && userData.access === 'doctor-admin') navigate('/doctor-admin')
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // set active item and redirect user to the right panel.
  useEffect(() => {
    if (typeof window === 'undefined') return

    const urlObject = new URL(window.location.href)

    handleAccess(urlObject?.pathname)
    setActivePath(urlObject?.pathname)
  }, [userData])

  // only show content if mobile or open.
  if (isMobile && !expanded) return null

  // return content.
  return (
    <NavigationWrapper
      background={setBackgroundColor(userData.access)}
      condensed={condensed}
      isMobile={isMobile}
    >
      <TitleWrapper condensed={condensed} isMobile={isMobile} onClick={onClick}>
        <Title color="white" condensed={condensed}>
          {!condensed && title}
        </Title>
        {!isMobile && (
          <Icon name={condensed ? 'caret-right' : 'caret-left'} color="white" size={16} />
        )}
      </TitleWrapper>

      {items.map((item, i) => {
        const isActive = item.path === activePath

        if (item.type === 'divider') return <Divider key={i} />
        if (item.type === 'spacer') return <Spacer key={i} />
        if (item.type === 'blockspacer')
          return (
            <Fragment key={i}>
              <Divider />
              {!condensed && (
                <>
                  <SubTitle color="white">
                    <Icon name={item.icon} color="white" size={16} />
                    {item.label}
                  </SubTitle>
                  <Divider />
                </>
              )}
            </Fragment>
          )

        return (
          <Item
            key={i}
            size="smaller"
            color={isActive ? 'primary' : 'white'}
            to={!isActive && item.path}
            active={isActive}
            condensed={condensed}
          >
            {isActive && <ActiveIndicator />}
            {condensed ? (
              <Icon key={i} name={item.icon} color={isActive ? 'primary' : 'white'} size={20} />
            ) : (
              item.label
            )}
          </Item>
        )
      })}
    </NavigationWrapper>
  )
}

export default Navigation
