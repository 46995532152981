// dependencies.
import { useEffect, useState } from 'react'
import { collection, orderBy, query, where } from 'firebase/firestore'
// utils.
import getDataKeys from '../../getDataKeys'
import useFetch from '../useFetch'
import { db } from '../../firebase/firebase'
import { getNameFull } from '../../usernameUtils'
import { useAuth } from '../../contexts/AuthContext'

/********************************/
/*                              */
/*    Business Patients Data    */
/*                              */
/********************************/

const useBusinessPatientsData = (
  business,
  certifications,
  reload,
  access = ['company', 'developer']
) => {
  // contexts.
  const { user, userData } = useAuth()
  // states.
  const [businessRefs, setBusinessRefs] = useState([])
  const [patientsTableItems, sePatientsTableItems] = useState([])

  // ------------------------------------------------------------------------------------------- //
  // FETCH DATABASE DATA                                                                         //
  // ------------------------------------------------------------------------------------------- //

  // database query.
  const q =
    businessRefs &&
    businessRefs.length !== 0 &&
    query(
      collection(db, 'patients'),
      where('businessRef', 'array-contains', businessRefs[0]),
      orderBy('lastname', 'asc')
    )

  // fetch business patients from firebase's collection.
  const { data, empty } = useFetch(
    {
      ...(businessRefs && businessRefs.length !== 0 && { q }),
      condition: !businessRefs,
      access,
      user,
      userData,
      name: 'BusinessPatients',
    },
    [businessRefs, reload]
  )

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // process patients list for select input for Business Home page.
  const patientsSelect = (patientsSet = data) => {
    return patientsSet.map((patientObj) => {
      const { firstname, lastname, id, middlename, comments, address, businessRef } = patientObj

      // select options.
      return {
        label: getNameFull(firstname, lastname),
        address,
        document: id,
        businessRef,
        patientData: {
          id,
          firstname,
          lastname,
          middlename,
          comments,
          phone: address.phone,
          email: address.email,
        },
      }
    })
  }

  // process patients list for select input for Business Employee page.
  const patientsTable = (certificationsSet = certifications, patientsSet = data) => {
    const newSet = patientsSet.map((patientObj) => {
      const certificationsArr = certificationsSet.filter(
        (key) => JSON.stringify(key.patientRef) === JSON.stringify(patientObj.ref)
      )

      return {
        ...patientObj,
        certifications: certificationsArr,
        disableShowHistory: !certificationsArr.length,
      }
    })

    // table items.
    return (
      newSet &&
      newSet.map((obj) => {
        const { id, employeenumber, firstname, middlename, lastname, disableShowHistory } = obj

        return {
          items: [
            { copy: id, label: 'Documento:', triggerDetail: true, icon: 'wallet' },
            { copy: employeenumber, label: 'Número de Funcionario:', icon: 'bookmark' },
            {
              copy: getNameFull(firstname, lastname, middlename),
              grow: 2,
              label: 'Nombre:',
              icon: 'user',
            },
          ],
          disableShowHistory,
          raw: obj,
        }
      })
    )
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // build businessRefs object.
  useEffect(() => {
    setBusinessRefs(getDataKeys(business))
  }, [business])

  // build patient with certification history items object.
  useEffect(() => {
    if (!business || !certifications || !data) return
    sePatientsTableItems(patientsTable(certifications))
  }, [business, certifications, data])

  // return values.
  return { patients: data, patientsSelect, patientsTable, patientsTableItems, empty }
}

export default useBusinessPatientsData
