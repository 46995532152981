// dependencies.
import { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
// components.
import { Body } from '../../components/Typography'
import { ScreenWrapper, Topbar, Wrapper } from '../../components/Layout'
// utils.
import { useAuth } from '../../js/contexts/AuthContext'

/***********************/
/*                     */
/*    Splash Screen    */
/*                     */
/***********************/

// partials.
const StyledBody = styled(Body)({
  padding: '15px',
  margin: 'auto',
  textAlign: 'center',
  maxWidth: '360px',
})

// main component.
const SplashScreen = () => {
  // contexts.
  const { user, userData } = useAuth()
  // hooks.
  const navigate = useNavigate()

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  const access = userData?.access

  useEffect(() => {
    // handle logged out users.
    if (!user) navigate('/signin')

    // redirect user to the right panel depending on 'access'.
    if (access === 'superadmin' || access === 'admin') navigate('/admin')
    else if (access === 'company') navigate('/empresas')
    else if (access === 'doctor') navigate('/doctores')
    else if (access === 'developer') navigate('/dev')
    else if (access === 'doctor-admin') navigate('/doctor-admin')
  }, [user, access])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <ScreenWrapper>
      <Topbar label="Medilab Certificaciones" />

      <Wrapper background="white">
        <StyledBody color="grey">Bienvenido</StyledBody>
      </Wrapper>
    </ScreenWrapper>
  )
}

export default SplashScreen
