// dependencies.
import styled from 'styled-components'
// components.
import { Headline, Label } from '../../components/Typography'
import { Icon } from '../../components/Icon'
// utils.
import setBackgroundColor from '../../js/setBackgroundColor'
import { useAuth } from '../../js/contexts/AuthContext'
import { useDimensions } from '../../js/hooks'
// constants.
import { PUBLIC } from '../../constants'

/**************************/
/*                        */
/*    Topbar Component    */
/*                        */
/**************************/

// partials.
const TopbarWrapper = styled.div(
  {
    alignItems: 'center',
    background: (props) => props.background,
    boxSizing: 'border-box',
    display: 'flex',
    gap: '16px',
    justifyContent: (props) => (props.isMobile ? 'space-between' : 'flex-start'),
    padding: (props) => (props.isMobile ? '8px 16px' : '16px 16px 8px'),
    position: 'fixed',
    width: '100%',
    zIndex: (props) => (props.isMobile ? 0 : 10),
  },
  `@media print { display: none; }`
)

const MenuLogoWrapper = styled.div({
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  gap: '16px',
})

const StyledImg = styled.img({
  height: (props) => (props.isMobile ? '32px' : '48px'),
  margin: (props) => (props.condensed ? '0 8px 0 -8px' : 0),
  minWidth: (props) => (props.isMobile ? '56px' : props.condensed ? '36px' : '165px'),
  objectFit: 'contain',
  objectPosition: 'left center',
  transition: 'all 100ms ease',
  width: (props) => (props.isMobile ? '56px' : props.condensed ? '36px' : '165px'),
})

const ActionItem = styled.button({
  alignItems: 'center',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  gap: '16px',
})

const ActionWrapper = ({ children, onClick }) => {
  if (onClick)
    return (
      <ActionItem onClick={onClick} tabindex={0}>
        {children}
      </ActionItem>
    )
  return <>{children}</>
}

// main component.
const Topbar = ({
  background,
  label,
  iconL,
  iconR,
  actionLabel,
  condensed,
  onActionLPress,
  onActionRPress,
}) => {
  // contexts.
  const { userData } = useAuth()
  // hooks.
  const { isMobile } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // build props object.
  const commonProps = { color: 'white' }

  // return content.
  return (
    <TopbarWrapper background={setBackgroundColor(userData.access)} isMobile={isMobile}>
      <MenuLogoWrapper>
        <StyledImg
          src={`${PUBLIC}/${condensed ? 'logo-condensed.png' : 'logo.png'}`}
          condensed={condensed}
          isMobile={isMobile}
          height={isMobile ? 24 : 48}
        />
      </MenuLogoWrapper>

      {!!iconL && (
        <ActionWrapper onClick={onActionLPress}>
          <Icon name={iconL} {...commonProps} size={24} />
        </ActionWrapper>
      )}

      <Headline size="smaller" {...commonProps}>
        {label}
      </Headline>

      {(!!actionLabel || !!iconR) && (
        <ActionWrapper onClick={onActionRPress}>
          {!!actionLabel && <Label {...commonProps}>{actionLabel}</Label>}

          {!!iconR && <Icon name={iconR} {...commonProps} size={24} />}
        </ActionWrapper>
      )}
    </TopbarWrapper>
  )
}

export default Topbar
