// dependencies.
import { useEffect, useState } from 'react'
import styled from 'styled-components'
// components.
import Drawer from './Drawer'
import { Map } from '../Map'
// utils.
import { theme } from '../../styleguide'
import { useDimensions } from '../../js/hooks'

// helpers.
const baseUnit = theme.fontSize.x16

const mapContainerStyle = {
  height: '100%',
  maxHeight: '100vh',
  minHeight: '100%',
  width: '50%',
}

// partials.
const Wrapper = styled.div({
  alignItems: 'flex-start',
  background: theme.colors.white,
  filter: `drop-shadow(${theme.shadows.normal})`,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  maxWidth: (props) =>
    `${props.isOpen ? (props.isMobile ? baseUnit * 30 : baseUnit * 60) : baseUnit * 30}px`,
  position: 'fixed',
  right: (props) => (props.isOpen ? 0 : `-${baseUnit * 60}px`),
  top: 0,
  transition: 'right 400ms ease, max-width 800ms ease',
  width: '100%',
  zIndex: 10,
})

// main component.
const DrawerWithMap = ({
  title,
  pointsToCheck,
  children,
  isOpen,
  onChange,
  onChangeLatLng,
  onChangeAddress = () => {},
  enableMoveMarker = false,
}) => {
  // hooks.
  const { isMobile } = useDimensions()
  // status.
  const [open, setOpen] = useState(isOpen)

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // update value object.
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  // propagate value.
  useEffect(() => {
    onChange(open)
  }, [open])

  // return content.
  return (
    <Wrapper isOpen={open} isMobile={isMobile}>
      {!isMobile && pointsToCheck && pointsToCheck.length > 0 ? (
        <Map
          pointsToCheck={pointsToCheck}
          style={mapContainerStyle}
          zoom={17}
          disableDrawingTools
          onChangeLatLng={(obj) => onChangeLatLng(obj)}
          onChange={(obj) => onChangeAddress(obj)}
          enableMoveMarker={enableMoveMarker}
        />
      ) : null}

      <Drawer title={title} isOpen={isOpen} onChange={onChange}>
        {children}
      </Drawer>
    </Wrapper>
  )
}

export default DrawerWithMap
