// dependencies.
import { collection, orderBy, query, where } from 'firebase/firestore'
// utils.
import useFetch from '../useFetch'
import { db } from '../../firebase/firebase'
import { getNameFull } from '../../usernameUtils'
import { useAuth } from '../../contexts/AuthContext'

/**********************/
/*                    */
/*    Doctors Data    */
/*                    */
/**********************/

const useAdminDoctorsData = (reload, access = ['superadmin', 'developer']) => {
  // contexts.
  const { user, userData } = useAuth()

  // ------------------------------------------------------------------------------------------- //
  // FETCH DATABASE DATA                                                                         //
  // ------------------------------------------------------------------------------------------- //

  // database query.
  const q = query(
    collection(db, 'users'),
    where('access', '==', 'doctor'),
    orderBy('lastname', 'asc')
  )

  // fetch all doctors from firebase's collection.
  const { data, empty } = useFetch({ q, access, user, userData, name: 'Doctors' }, [reload])

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // filter doctors for Admin Doctors page.
  const doctorsTable = (doctorsSet = data) => {
    return doctorsSet.map((doctorObj) => {
      const { firstname, lastname, phone, email, isActive } = doctorObj

      // table items.
      return {
        items: [
          { copy: getNameFull(firstname, lastname), grow: 2, label: 'Nombre', icon: 'user' },
          { copy: phone, label: 'Teléfono:', icon: 'wallet' },
          { copy: email, grow: 2, label: 'E-Mail:', icon: 'copy' },
          {
            copy: isActive ? 'Si' : 'No',
            label: 'Es activo:',
            icon: 'title',
            type: 'pill',
            pillBackground: isActive ? 1 : 0,
          },
        ],
        disableDelete: isActive,
        raw: doctorObj,
      }
    })
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return values.
  return { doctors: data, doctorsTable, empty }
}

export default useAdminDoctorsData
