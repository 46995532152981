// theme for colors, font sizes, and shadows.
export const theme = {
  fontSize: {
    x48: 48,
    x40: 40,
    x32: 32,
    x24: 24,
    x20: 20,
    x18: 18,
    x16: 16,
    x14: 14,
    x12: 12,
  },
  lineHeight: {
    x48: 56,
    x40: 46,
    x32: 40,
    x24: 32,
    x20: 24,
    x18: 22,
    x16: 20,
    x14: 18,
    x12: 16,
  },
  colors: {
    primary: '#00aaff',
    secondary: '#61dfd6',
    terciary: '#172964',
    black: '#111113',
    asphalt: '#313335',
    grey: '#757575',
    ash: '#CDCFD1',
    smoke: '#F5F5F5',
    white: '#FFFFFF',
    alert: '#D21919',
    success: '#3FC434',
    warning: '#F0BE00',
  },
  admin: {
    admin: '#172964',
    company: '#141D3C',
    doctor: '#111113',
    developer: '#0f3b2d',
  },
  opacity: {
    black50: 'rgba(0, 0, 0, 0.5)',
    white10: 'rgba(255, 255, 255, 0.1)',
  },
  shadows: {
    larger: '0px 8px 8px rgba(17, 17, 19, 0.1)',
    normal: '0px 4px 8px rgba(17, 17, 19, 0.1)',
    smaller: '0px 1px 8px rgba(17, 17, 19, 0.1)',
    error: '0px 0px 10px #D21919',
    focus: '0px 0px 4px #61dfd6',
  },
}

// icon sprite map by name.
export const icons = {
  'caret-right': [0, 0],
  'caret-left': [0, 1],
  'caret-up': [0, 2],
  'caret-down': [0, 3],
  'return-right': [0, 4],
  'return-left': [0, 5],
  'sign-out': [0, 6],
  'sign-in': [0, 7],
  download: [0, 8],
  upload: [0, 9],
  external: [0, 10],
  warning: [1, 0],
  success: [1, 1],
  info: [1, 2],
  alert: [1, 3],
  na: [1, 4],
  check: [2, 0],
  calendar: [2, 1],
  chart: [2, 2],
  dollar: [2, 3],
  wallet: [2, 4],
  hot: [2, 5],
  image: [2, 6],
  title: [2, 7],
  user: [2, 8],
  edit: [3, 0],
  copy: [3, 1],
  close: [3, 2],
  delete: [3, 3],
  search: [3, 4],
  plus: [3, 5],
  add: [3, 6],
  view: [3, 7],
  bookmark: [3, 8],
  trash: [3, 9],
  favorite: [3, 10],
  filter: [3, 11],
  flash: [3, 12],
  rotate: [3, 13],
  home: [4, 0],
  doctor: [4, 1],
  settings: [4, 2],
  admin: [4, 3],
  business: [4, 4],
  certification: [4, 5],
  pathology: [4, 6],
  history: [4, 7],
  employees: [4, 8],
  location: [4, 9],
}

// constants for dimensions.
export const constants = {
  WRAPPER_HEIGHT: window.innerHeight,
  WRAPPER_MAXWIDTH: '100%',
  TABLE_CELL_MIN_WIDTH: '112px',
  TABLE_CELL_MID_WIDTH: '176px',
  TABLE_CELL_MAX_WIDTH: '232px',
}
