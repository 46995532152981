// dependencies.
import { useEffect, useState } from 'react'
import { doc, updateDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import Table from '../../components/Table'
import { Drawer } from '../../components/Modal'
import { Form } from '../../components/Form'
import { Loader } from '../../components/Icon'
import { FilterHeader } from '../../components/Layout'
// utils.
import notifyToast from '../../js/notifyToast'
import search from '../../js/search'
import { createUser, db, getAdminById, sendSignInEmailLink } from '../../js/firebase/firebase'
import { useAdminsData } from '../../js/hooks/data'
// constants.
import { NEW_ADMIN_FORM } from './constants'

/****************************/
/*                          */
/*    Admin Users Screen    */
/*                          */
/****************************/

// helpers.
const tableHeader = [
  { label: 'Nombre Completo', grow: 2 },
  { label: 'Teléfono' },
  { label: 'Email', grow: 2 },
  { label: 'Acciones', grow: 2 },
]

// main component.
const AdminUsersScreen = ({ navMenu, navTitle }) => {
  const [reload, setReload] = useState(false)
  // hooks.
  const { admins, adminsTable, empty } = useAdminsData(reload)
  // data objects.
  const adminsTableItems = adminsTable()
  // states.
  const [loaded, setLoaded] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [searched, setSearched] = useState(adminsTableItems)

  // ------------------------------------------------------------------------------------------- //
  // SEARCH                                                                                      //
  // ------------------------------------------------------------------------------------------- //

  // search for items in the array.
  const handleSearch = (term) => {
    setSearched(search(adminsTableItems, term))
  }

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  // handle add new admin modal.
  const handleNewAdminModal = () => {
    setModalContent({
      title: 'Agregar nuevo administrador',
      component: <Form items={NEW_ADMIN_FORM} onClick={(obj) => handleCreateAdminClick(obj)} />,
    })
    setModalOpen(true)
  }

  // handle edit selected admin modal.
  const handleEditAdminModal = (obj) => {
    setModalContent({
      title: 'Actualizar administrador',
      component: (
        <Form
          items={NEW_ADMIN_FORM}
          defaultValues={obj}
          onClick={(obj) => handleSaveAdminClick(obj)}
        />
      ),
    })
    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new admin.
  const handleCreateAdminClick = async (obj) => {
    obj.access = 'superadmin'

    await createUser(obj)
      .then(() => notifyToast('Administrador creado correctamente', 'success'))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // handle admin information update.
  const handleSaveAdminClick = async (obj) => {
    const userRef = doc(db, 'users', obj.uid)
    const { firstname, lastname, phone, email } = obj

    const adminDoc = await getAdminById(obj.uid)

    if (adminDoc.data().isActive && adminDoc.data().email !== email) {
      notifyToast('No se puede modificar el email', 'error')
      return
    }

    await updateDoc(userRef, { firstname, lastname, phone, email })
      .then(() => {
        console.warn('Datos guardados correctamente.')

        if (email !== adminDoc.data().email) {
          sendSignInEmailLink(obj.uid, obj.email)
        }
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // Without initially setting and clearing search on resourceLoaded in this way, causes asset list
  // children to draw incorrectly, with logos swapped out between assets, reason not known.
  // Removing sort removes the need for this.
  useEffect(() => {
    handleSearch('')
  }, [admins])

  // only show content when loaded.
  useEffect(() => {
    !empty && setLoaded(true)
  }, [empty])

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        <FilterHeader
          label="Agregar administrador"
          placeholder="Buscar por: nombre, apellido, teléfono, email..."
          onActionClick={() => handleNewAdminModal()}
          onChange={(term) => handleSearch(term)}
        />

        {loaded ? (
          <Table
            header={tableHeader}
            items={searched}
            //
            enableEdit
            onEdit={(obj) => handleEditAdminModal(obj)}
            //
            enableDelete
            onDelete={(obj) => console.error(obj)}
          />
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default AdminUsersScreen
