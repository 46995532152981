// dependencies.
import styled from 'styled-components'
// components.
import { Headline, Body } from '../components/Typography'
import { Item } from '../components/List'
// utils.
import certificationStatusFormat from '../js/certificationStatusFormat'
import dateFormat from '../js/dateFormat'
import { getNameFull } from '../js/usernameUtils'

/****************************/
/*                          */
/*    Show History Modal    */
/*                          */
/****************************/

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  margin: '0 auto',
  maxWidth: '416px',
  width: '100%',
})

const StyledHeadline = styled(Headline)({
  margin: '24px 0 8px',
})

// main component.
const PatientHistory = ({ patient }) => {
  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // build full name string.
  const patientFullName = getNameFull(patient?.firstname, patient?.lastname, patient?.middlename)

  // build certification item content object.
  const certificationContent = (status, createdDate, medicAct) => {
    if (status === 0 || status === 1) {
      return {
        statusStr: certificationStatusFormat(status),
        dateStr: `Solicitado el ${dateFormat(createdDate)}`,
        icon: { color: 'alert', name: 'warning' },
      }
    }
    if (status === 2 || status === 3) {
      return {
        statusStr: certificationStatusFormat(status),
        dateStr: `${dateFormat(medicAct?.startDateExt || medicAct?.startDate)} - ${dateFormat(
          medicAct?.endDateExt || medicAct?.endDate
        )}`,
        icon: { color: 'terciary', name: 'check' },
      }
    }
    if (status === 4) {
      return {
        statusStr: certificationStatusFormat(status),
        icon: { color: 'warning', name: 'trash' },
      }
    }
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper>
      <StyledHeadline>Certificaciones</StyledHeadline>
      <Headline size="smallest">{patientFullName}</Headline>

      {patient.certifications ? (
        patient.certifications.map(({ id, createdDate, medicAct, status }, index) => {
          const { statusStr, dateStr, icon } = certificationContent(status, createdDate, medicAct)

          return (
            <Item
              key={index}
              index={index + 1}
              status={statusStr}
              date={dateStr}
              icon={icon}
              isLast={index === patient.certifications.length - 1}
            />
          )
        })
      ) : (
        <Body>No se encontraron certificados.</Body>
      )}
    </Wrapper>
  )
}

export default PatientHistory
