// dependencies.
import { useEffect, useState } from 'react'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import MedicActData from '../../modals/MedicActData'
import Table from '../../components/Table'
import { Drawer, DrawerWithMap } from '../../components/Modal'
import { Form } from '../../components/Form'
import { Loader } from '../../components/Icon'
import { FilterHeader } from '../../components/Layout'
// utils.
import notifyToast from '../../js/notifyToast'
import search from '../../js/search'
import { db, getDoctorById, getPathologyByCode } from '../../js/firebase/firebase'
import { getNameFull } from '../../js/usernameUtils'
import {
  parseAddressToLatLng,
  reverseLatLngToAddress,
  toAddressString,
  toAddressObject,
} from '../../js/parseAddress'
import { useAuth } from '../../js/contexts/AuthContext'
import {
  useDoctorsData,
  useBusinessData,
  useCertificationsData,
  usePatientsData,
  usePathologiesData,
} from '../../js/hooks/data'
// constants.
import {
  ASSIGN_CERTIFICATION_FORM,
  CANCEL_CERTIFICATION_FORM,
  NEW_CERTIFICATION_FORM,
  CERTIFICATION_FORM,
} from './constants'

/*************************************/
/*                                   */
/*    Admin Certifications Screen    */
/*                                   */
/*************************************/

// helpers.
const tableHeader = [
  { label: 'Solicitud', grow: 1 },
  { label: 'Estado' },
  { label: 'Documento', grow: 1 },
  { label: 'Paciente', grow: 2 },
  { label: 'Empresa', grow: 1 },
  { label: 'Médico asignado', grow: 1 },
  { label: 'Realizado' },
  { label: 'Acciones', grow: 2 },
]

// main component.
const AdminCertificationsScreen = ({ navMenu, navTitle }) => {
  const [reload, setReload] = useState(false)
  const [filteredValues, setFilteredValues] = useState({})
  // contexts.
  const { isLoaded } = useAuth()
  // hooks.
  const { businessSelect } = useBusinessData()
  const { doctors, doctorsSelect } = useDoctorsData()
  const { patients, patientsSelect } = usePatientsData()
  const { certificationsTableItems, empty } = useCertificationsData(
    patients,
    doctors,
    reload,
    null,
    null,
    filteredValues
  )
  const { pathologiesSelect } = usePathologiesData()
  // data objects.
  const pathologiesSelectOptions = pathologiesSelect()
  const businessSelectOptions = businessSelect()
  const doctorsSelectOptions = doctorsSelect()
  const patientsSelectOptions = patientsSelect()
  // states.
  const [loaded, setLoaded] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [mapModalOpen, setMapModalOpen] = useState(false)
  const [searched, setSearched] = useState(certificationsTableItems)

  // ------------------------------------------------------------------------------------------- //
  // SEARCH                                                                                      //
  // ------------------------------------------------------------------------------------------- //

  // search for items in the array.
  const handleSearch = (term) => {
    setSearched(search(certificationsTableItems, term))
  }

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  const handleMapModalOpen = (open) => {
    setMapModalOpen(open)
  }

  // handle add new certification modal.
  const handleNewCertificationModal = () => {
    setModalContent({
      title: 'Solicitar certificación',
      component: (
        <Form
          items={NEW_CERTIFICATION_FORM(businessSelectOptions, patientsSelectOptions)}
          onClick={(obj) => handleCreateCertificationClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // handle edit selected certification modal.
  const handleEditCertificationModal = async (obj) => {
    obj.patientData.fullname = getNameFull(obj.patientData?.firstname, obj.patientData?.lastname)

    let newPoint
    if (!obj.address.lat || !obj.address.lng) {
      newPoint = await parseAddressToLatLng(toAddressString(obj.address))
    } else {
      newPoint = { lat: obj.address.lat, lng: obj.address.lng }
    }
    newPoint.title = obj.patientData.fullname

    const handleChangeAddress = async ({ lat, lng }) => {
      try {
        const newAddress = await reverseLatLngToAddress(lat, lng)
        return toAddressObject(newAddress)
      } catch (error) {
        console.error(error)
      }
    }

    obj.doctorId = obj.doctorRef?.id
    setModalContent({
      title: 'Editar/Asignar certificación',
      pointsToCheck: Array(newPoint),
      certificationId: obj.ref.id,
      certificationAddress: obj.address,
      handleChangeAddress: ({ lat, lng }) => {
        handleChangeAddress({ lat, lng }).then((res) => (obj.address = res))
      },
      component: (
        <Form
          items={ASSIGN_CERTIFICATION_FORM(doctorsSelectOptions)}
          defaultValues={obj}
          onClick={(obj) => handleUpdateCertificationClick(obj)}
        />
      ),
    })

    isLoaded && setMapModalOpen(true)
  }

  // handle edit selected certification modal.
  const handleCancelCertificationModal = (obj) => {
    obj.patientData.fullname = getNameFull(obj.patientData?.firstname, obj.patientData?.lastname)
    // obj.doctorId = obj.doctor?.id
    setModalContent({
      title: 'Cancelar certificación',
      component: (
        <Form
          items={CANCEL_CERTIFICATION_FORM}
          defaultValues={obj}
          onClick={(obj) => handleCancelCertificationClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // handle show medic act detail for selected certification modal.
  const handleViewMedicActModal = async (obj) => {
    if (obj.medicAct.pathologyId) {
      const pathology = await getPathologyByCode(obj.medicAct.pathologyId)
      obj.medicAct.pathology = pathology.data().confidentialName
    }

    setModalContent({
      title: 'Acto Médico',
      component: <MedicActData certification={obj} />,
    })

    setModalOpen(true)
  }

  // edit selected certification.
  const handleEditMedicAct = (obj) => {
    setModalContent({
      title: 'Acto Médico',
      component: (
        <Form
          items={CERTIFICATION_FORM(pathologiesSelectOptions)}
          defaultValues={obj}
          onClick={(obj) => handleSaveCertificationClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }
  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d)
  }
  const setStartDate = (date) => {
    if (!isValidDate(new Date(`${date} 00:00:00`))) return null
    return new Date(`${date} 00:00:00`)
  }

  const setEndDate = (date) => {
    if (!isValidDate(new Date(`${date} 23:59:59`))) return null
    return new Date(`${date} 23:59:59`)
  }

  const validateStartEndDate = (startDate, endDate) => {
    if (!startDate || !endDate) {
      notifyToast('Debe ingresar fechas de inicio y fin válidas', 'warning')
      return false
    }

    const maxEndDate = new Date(startDate)
    maxEndDate.setDate(maxEndDate.getDate() + 31) // Agregar 30 días al startDate

    if (endDate < startDate) {
      notifyToast('La fecha de fin no puede ser anterior a la de inicio', 'warning')
      return false
    }

    if (endDate > maxEndDate) {
      notifyToast('El período de certificación no puede tener más de 30 días', 'warning')
      return false
    }

    return true
  }

  const handleSaveCertificationClick = async (obj) => {
    const {
      startDate,
      endDate,
      issuedDate,
      externalCertificateCheck,
      doctorNameExt,
      startDateExt,
      endDateExt,
      homeLeave,
      pathologyId,
      comments,
    } = obj.medicAct
    const updateFields = {
      medicAct: {
        startDate: setStartDate(startDate),
        endDate: setEndDate(endDate),
        issuedDate: setStartDate(issuedDate),
        externalCertificateCheck: externalCertificateCheck || null,
        doctorNameExt: doctorNameExt || null,
        startDateExt: setStartDate(startDateExt),
        endDateExt: setEndDate(endDateExt),
        homeLeave: homeLeave || null,
        pathologyId: pathologyId || null,
        comments: comments || null,
      },
      status: endDate ? 3 : 2,
      updatedDate: new Date(),
    }

    const validate = validateStartEndDate(
      updateFields.medicAct.startDate,
      updateFields.medicAct.endDate
    )

    if (validate) {
      const certificationRef = doc(db, 'certifications', obj.ref.id)
      await setDoc(certificationRef, updateFields, { merge: true })
        .then(() => notifyToast('Certificación actualizada correctamente', 'success'))
        .then(setTimeout(() => setModalOpen(false), 1000))
        .catch(() => notifyToast('Ha ocurrido un error', 'error'))

      setReload(!reload)
    }
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new certification.
  const handleCreateCertificationClick = async (obj) => {
    if (obj) delete obj.selectPatient
    const patientRef = doc(db, 'patients', obj.document)
    const address = `${obj.address.street} ${obj.address.number}, ${obj.address.district}, ${obj.address.city}, Uruguay`
    const { lat, lng } = await parseAddressToLatLng(address)

    await addDoc(collection(db, 'certifications'), {
      address: { ...obj?.address, lat, lng },
      createdDate: new Date(),
      updatedDate: new Date(),
      status: 0,
      patientRef,
      businessRef: obj.businessRef,
      businessName: obj.businessName,
      comments: obj.comments,
    })
      .then(async (certRef) => {
        await setDoc(certRef, { ref: certRef }, { merge: true })
        notifyToast('Certificación solicitada correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // handle assign / edit certification.
  const handleUpdateCertificationClick = async (obj) => {
    const updateFields = {
      address: { ...obj?.address },
      comments: obj.comments,
      updatedDate: new Date(),
    }
    const certificationRef = doc(db, 'certifications', obj.ref.id)

    if (obj.doctorId) {
      const doctorDoc = await getDoctorById(obj.doctorId)
      updateFields.doctorRef = doctorDoc.ref
      updateFields.status = obj.status === 0 ? 1 : obj.status // assigned
    }
    const address = `${obj.address.street} ${obj.address.number}, ${obj.address.district}, ${obj.address.city}, Uruguay`

    const { lat, lng } = await parseAddressToLatLng(address)

    updateFields.address = { ...updateFields?.address, lat, lng }
    updateFields.updatedDate = new Date()
    await setDoc(certificationRef, updateFields, { merge: true })
      .then(() => notifyToast('Certificación actualizada correctamente', 'success'))
      .then(setTimeout(() => setMapModalOpen(false), 1000))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setReload(!reload)
  }

  // handle cancel certification.
  const handleCancelCertificationClick = async (obj) => {
    const certificationRef = doc(db, 'certifications', obj.ref.id)

    await setDoc(certificationRef, { status: 4 }, { merge: true })
      .then(() => notifyToast('Certificación cancelada correctamente', 'success'))
      .then(setTimeout(() => setModalOpen(false), 1000))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setReload(!reload)
  }

  const handleConfirmLatLngClick = async (obj) => {
    modalContent.certificationAddress.lat = obj.lat()
    modalContent.certificationAddress.lng = obj.lng()

    const updateFields = {
      address: { ...modalContent?.certificationAddress },
      updatedDate: new Date(),
    }
    const certificationRef = doc(db, 'certifications', modalContent.certificationId)

    await setDoc(certificationRef, updateFields, { merge: true })
      .then(() => notifyToast('Certificación actualizada correctamente', 'success'))
      .then(setTimeout(() => setMapModalOpen(false), 1000))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setReload(!reload)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // Without initially setting and clearing search on resourceLoaded in this way, causes asset list
  // children to draw incorrectly, with logos swapped out between assets, reason not known.
  // Removing sort removes the need for this.
  useEffect(() => {
    handleSearch('')
  }, [certificationsTableItems])

  // only show content when loaded.
  useEffect(() => {
    !empty && setLoaded(true)
  }, [empty])

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={mapModalOpen || modalOpen}
        onOverlayClick={() => {
          setModalOpen(false)
          setMapModalOpen(false)
        }}
      >
        <FilterHeader
          placeholder="Buscar por: cédula, nombre, estado..."
          onActionClick={() => handleNewCertificationModal()}
          onChange={(term) => handleSearch(term)}
          onFilterClick={(value) => setFilteredValues(value)}
          header={tableHeader}
          items={searched}
          filename="certificaciones"
          exportReport
        />

        {loaded ? (
          <Table
            header={tableHeader}
            items={searched}
            //
            enableShowMedicAct
            onShowMedicAct={(obj) => handleViewMedicActModal(obj)}
            //
            enableEdit
            onEdit={(obj) => handleEditCertificationModal(obj)}
            //
            enableMedicAct
            onMedicAct={(obj) => handleEditMedicAct(obj)}
            //
            enableCancel
            onCancel={(obj) => handleCancelCertificationModal(obj)}
          />
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>

      <DrawerWithMap
        title={modalContent.title}
        pointsToCheck={modalContent.pointsToCheck}
        isOpen={mapModalOpen}
        onChange={handleMapModalOpen}
        onChangeLatLng={handleConfirmLatLngClick}
        onChangeAddress={modalContent.handleChangeAddress}
        enableMoveMarker
      >
        {modalContent.component}
      </DrawerWithMap>
    </>
  )
}

export default AdminCertificationsScreen
