// main function.
const getDataKeys = (array, key = 'ref') => {
  // prevent empty arrays.
  if (!array || array.length === 0) return null

  // return only valid keys.
  return array.map((arr) => typeof arr[key] !== 'undefined' && arr[key]).filter(Boolean)
}

export default getDataKeys
