// dependencies.
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { doc } from 'firebase/firestore'
// components.
import Checkbox from './Checkbox'
import Input from './Input'
import { Button } from '../Button'
import { Headline } from '../Typography'
// utils.
import { db } from '../../js/firebase/firebase'
import { useDimensions } from '../../js/hooks'

/************************/
/*                      */
/*    Form Component    */
/*                      */
/************************/

// helpers.
const getRequiredFields = (fields) => {
  return fields.reduce((acc, field) => {
    if (field.required && field.name) {
      acc.push(field.name)
    }
    if (field.items) {
      const subFields = field.items.flat()
      acc.push(...getRequiredFields(subFields))
    }
    return acc
  }, [])
}

const findValueByKey = (obj, key) => {
  for (const prop in obj) {
    if (prop === key && obj[prop] !== null) {
      return obj[prop]
    } else if (typeof obj[prop] === 'object' && obj[prop] !== null) {
      for (const prop2 in obj[prop]) {
        if (prop2 === key && obj[prop][prop2] !== null) {
          return obj[prop][prop2]
        }
      }
    }
  }
  return null
}

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: (props) => (props.isMobile ? '8px' : '16px'),
  margin: '0 auto',
  maxWidth: '416px',
  width: '100%',
})

const InputGroup = styled.div({
  display: 'flex',
  gap: (props) => (props.isMobile ? '8px' : '16px'),
  margin: '0',
  maxWidth: '416px',
  width: '100%',
})

const SectionLabel = styled(Headline)({
  margin: (props) => (props.isMobile ? '16px 0 0' : '24px 0 8px'),
})

const SubmitButton = styled(Button)({
  margin: '64px 0 8px',
})

const FormInput = ({ item, defaultValue, isMobile, onChange, filtered = null }) => {
  const { disabled, required, label, name, options, placeholder, type } = item
  const commonProps = { defaultValue, disabled, required, name, onChange }

  if (type === 'headline') {
    return <SectionLabel isMobile={isMobile}>{label}</SectionLabel>
  }
  if (type === 'checkbox') {
    return <Checkbox {...commonProps} label={placeholder} />
  }
  if (name === 'selectPatient') {
    return <Input {...commonProps} options={filtered} placeholder={placeholder} type={type} />
  }
  return <Input {...commonProps} options={options} placeholder={placeholder} type={type} />
}

// main component.
const Form = ({ items, defaultValues, submitLabel = 'Guardar', onClick }) => {
  // hooks.
  const { isMobile } = useDimensions()
  const [isSubmitting, setIsSubmitting] = useState(false)

  // states.
  const [values, setValues] = useState(() => {
    const initialValues = items.reduce((initialEmptyValues, item) => {
      // construct an object with all keys from items with null values.
      if (item.name) initialEmptyValues[item.name] = null

      // if it's an array field, initialize each sub-item's keys with null values.
      if (item.type === 'array') {
        initialEmptyValues[item.name] = {}
        item.items.forEach((subItem) => {
          if (item.name && subItem.name) initialEmptyValues[item.name][subItem.name] = null
          if (subItem.length > 1) {
            subItem.forEach((subSubItem) => {
              if (item.name && subSubItem.name)
                initialEmptyValues[item.name][subSubItem.name] = null
            })
          }
        })
      }

      return initialEmptyValues
    }, {})

    return defaultValues ?? initialValues
  })
  const [patientsArr, setPatientsArr] = useState([])
  const [filteredPatient, setFilteredPatient] = useState([])
  const [disableSubmit, setDisableSubmit] = useState(true)

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //


  const handleSubmit = async () => {
    if (isSubmitting) {
      return // Prevent multiples submissions.
    }

    setIsSubmitting(true)

    try {
      await onClick(values)
    } catch (error) {
      console.error('Error al enviar el formulario:', error)
    } finally {
      setIsSubmitting(false)
    }
  }
  // handle form data update.
  const handleInputChange = (item, value, index, parent = null) => {
    if (item.name === 'fullname' && !!item.options) {
      if (value) {
        const selected = items[index].options.filter((option) => option.label === value)
        const updatedValues = { ...values, ...selected[0], fullname: selected[0]?.label }

        setValues(updatedValues)
      }
    } else if (item.name === 'businessName') {
      if (value) {
        if (items[index].options) {
          const business = items[index].options.filter((b) => b.name === value)

          if (business.length > 0) {
            const businessRef = doc(db, 'business', value)
            const employees = patientsArr.filter((employee) =>
              employee.businessRef.some((ref) => ref.id === businessRef.id)
            )
            const updatedValues = { ...values, businessRef, businessName: business[0].label }

            setValues(updatedValues)
            setFilteredPatient(employees)
          }
        } else {
          setValues(value)
        }
      }
    } else if (item.name === 'selectPatient') {
      if (patientsArr.length === 0 && items[index]?.options) {
        setPatientsArr(items[index].options)
      } else if (filteredPatient.length > 0) {
        const selectedPatient = filteredPatient.filter((patient) => patient.document === value)[0]
        const { street, number, suite, cornerstreet, city, state, district, phone, indications } =
          selectedPatient.address
        const updatedValues = {
          ...values,
          fullname: selectedPatient.patientData.firstname,
          document: selectedPatient.document,
          patientData: selectedPatient.patientData,
          address: {
            street,
            number,
            suite,
            cornerstreet,
            city,
            state,
            district,
            phone,
            indications
          },
        }

        setValues(updatedValues)
      }
    } else {
      const buildValue =
        parent && values && parent in values
          ? { [parent]: { ...values[parent], [item.name || index]: value } }
          : parent
          ? { [parent]: {} }
          : { [item.name || index]: value }

      setValues({ ...values, ...buildValue })
    }
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // check required items.
  useEffect(() => {
    if (!items) return
    const requiredFields = getRequiredFields(items)
    const checkValue = requiredFields.every((key) => findValueByKey(values, key))
    setDisableSubmit(!checkValue)
  }, [items, values])

  // only show if there's items.
  if (!items) return null

  // return content.
  return (
    <Wrapper isMobile={isMobile}>
      {items.map((item, i) => {
        const shouldShowContent =
          (values &&
            values.medicAct &&
            values.medicAct?.externalCertificateCheck &&
            item.medicAct?.showOnExternalCertification) ||
          !item?.showOnExternalCertification

        if (item.type === 'array') {
          return (
            shouldShowContent &&
            item.items.length > 0 &&
            item.items.map((subItem, j) => {
              if (subItem.length > 1) {
                return (
                  <InputGroup key={j} isMobile={isMobile}>
                    {subItem.map((groupItem, k) => (
                      <FormInput
                        key={k}
                        item={groupItem}
                        defaultValue={
                          (values &&
                            item.name in values &&
                            groupItem.name in values[item.name] &&
                            values[item.name][groupItem.name]) ||
                          ''
                        }
                        isMobile={isMobile}
                        onChange={(value) => handleInputChange(groupItem, value, k, item.name)}
                      />
                    ))}
                  </InputGroup>
                )
              }
              const shouldShowContent =
                (values &&
                  values.medicAct &&
                  values.medicAct?.externalCertificateCheck &&
                  subItem?.showOnExternalCertification) ||
                !subItem?.showOnExternalCertification
              return (
                shouldShowContent && (
                  <FormInput
                    key={j}
                    item={subItem}
                    defaultValue={
                      (values &&
                        item.name in values &&
                        subItem.name in values[item.name] &&
                        values[item.name][subItem.name]) ||
                      ''
                    }
                    isMobile={isMobile}
                    onChange={(value) => handleInputChange(subItem, value, j, item.name)}
                  />
                )
              )
            })
          )
        }

        return (
          shouldShowContent && (
            <FormInput
              key={i}
              item={item}
              defaultValue={(values && item.name in values && values[item.name]) || ''}
              isMobile={isMobile}
              onChange={(value) => handleInputChange(item, value, i)}
              filtered={item.name === 'selectPatient' ? filteredPatient : null}
            />
          )
        )
      })}

<SubmitButton disabled={disableSubmit || isSubmitting} onClick={handleSubmit}>
        {isSubmitting ? 'Enviando...' : submitLabel}
      </SubmitButton>
    </Wrapper>
  )
}

export default Form
