// dependencies.
import { useEffect, useState } from 'react'
import { doc, updateDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import Table from '../../components/Table'
import { Drawer } from '../../components/Modal'
import { Form } from '../../components/Form'
import { FilterHeader } from '../../components/Layout'
// utils.
import notifyToast from '../../js/notifyToast'
import search from '../../js/search'
import { createUser, db, getDoctorById, sendSignInEmailLink } from '../../js/firebase/firebase'
import { Loader } from '../../components/Icon'
import { useAdminDoctorsData } from '../../js/hooks/data'
// constants.
import { EDIT_DOCTOR_FORM, NEW_DOCTOR_FORM } from './constants'

/******************************/
/*                            */
/*    Admin Doctors Screen    */
/*                            */
/******************************/

// helpers.
const tableHeader = [
  { label: 'Nombre Completo', grow: 2 },
  { label: 'Teléfono' },
  { label: 'Email', grow: 2 },
  { label: 'Activo' },
  { label: 'Acciones', grow: 2 },
]

// main component.
const AdminDoctorsScreen = ({ navMenu, navTitle }) => {
  const [reload, setReload] = useState(false)
  // hooks.
  const { doctors, doctorsTable, empty } = useAdminDoctorsData(reload)
  // data objects.
  const doctorsTableItems = doctorsTable()
  // states.
  const [loaded, setLoaded] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [searched, setSearched] = useState(doctorsTableItems)

  // ------------------------------------------------------------------------------------------- //
  // SEARCH                                                                                      //
  // ------------------------------------------------------------------------------------------- //

  // search for items in the array.
  const handleSearch = (term) => {
    setSearched(search(doctorsTableItems, term))
  }

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  // handle add new doctor modal.
  const handleNewDoctorModal = () => {
    setModalContent({
      title: 'Agregar nuevo médico',
      component: <Form items={NEW_DOCTOR_FORM} onClick={(obj) => handleCreateDoctorClick(obj)} />,
    })

    setModalOpen(true)
  }

  // handle edit selected doctor modal.
  const handleEditDoctorModal = (obj) => {
    setModalContent({
      title: 'Actualizar médico',
      component: (
        <Form
          items={EDIT_DOCTOR_FORM(!obj.ref)} // If not user ref, not user created
          defaultValues={obj}
          onClick={(obj) => handleSaveDoctorClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new doctor.
  const handleCreateDoctorClick = async (obj) => {
    obj.access = 'doctor'

    await createUser(obj)
      .then(() => notifyToast('Doctor creado correctamente', 'success'))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // handle doctor information update.
  const handleSaveDoctorClick = async (obj) => {
    const userRef = doc(db, 'users', obj.uid)
    const { firstname, lastname, phone, email, isActive } = obj

    const doctorDoc = await getDoctorById(obj.uid)

    if (doctorDoc.data().isActive && doctorDoc.data().email !== email) {
      notifyToast('No se puede modificar el email', 'error')
      return
    }

    await updateDoc(userRef, { firstname, lastname, phone, email, isActive })
      .then(() => {
        notifyToast('Médico actualizado correctamente', 'success')

        if (email !== doctorDoc.data().email) {
          sendSignInEmailLink(obj.uid, obj.email)
        }
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // Without initially setting and clearing search on resourceLoaded in this way, causes asset list
  // children to draw incorrectly, with logos swapped out between assets, reason not known.
  // Removing sort removes the need for this.
  useEffect(() => {
    handleSearch('')
  }, [doctors])

  // only show content when loaded.
  useEffect(() => {
    !empty && setLoaded(true)
  }, [empty])

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        <FilterHeader
          label="Agregar médico"
          placeholder="Buscar por nombre..."
          onActionClick={() => handleNewDoctorModal()}
          onChange={(term) => handleSearch(term)}
        />

        {loaded ? (
          <Table
            header={tableHeader}
            items={searched}
            //
            enableEdit
            onEdit={(obj) => handleEditDoctorModal(obj)}
            //
            enableDelete
            onDelete={(obj) => console.error(obj)}
          />
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default AdminDoctorsScreen
