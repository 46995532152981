// dependencies.
import styled from 'styled-components'
import { useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
} from 'recharts'
// components.
import AdminTemplate from '../templates/AdminPanel'
import { FlatCard } from '../components/Card'
import Tabs from '../components/Layout/Tabs'
import { Item } from '../components/List'
// utils.
import { theme } from '../styleguide'
import {
  useAdminsData,
  useBusinessData,
  useCertificationsData,
  useDoctorsData,
  usePathologiesData,
  usePatientsData,
} from '../js/hooks/data'
import certificationStatusFormat from '../js/certificationStatusFormat'
import dateFormat from '../js/dateFormat'
import { getNameFull } from '../js/usernameUtils'

/*********************************/
/*                               */
/*    Development Home Screen    */
/*                               */
/*********************************/

// helpers

// build certification item content object.
const certificationContent = (status, createdDate, updatedDate, medicAct) => {
  if (status === 0) {
    return {
      statusStr: certificationStatusFormat(status),
      dateStr: `Solicitado el ${dateFormat(createdDate)}`,
      icon: { color: 'alert', name: 'warning' },
    }
  }
  if (status === 1) {
    return {
      statusStr: certificationStatusFormat(status),
      dateStr: `Solicitado el ${dateFormat(createdDate)}`,
      icon: { color: 'success', name: 'check' },
    }
  }
  if (status === 2 || status === 3) {
    return {
      statusStr: 'Realizada',
      dateStr: `${dateFormat(medicAct?.startDateExt || medicAct?.startDate)} - ${dateFormat(
        medicAct?.endDateExt || medicAct?.endDate
      )}`,
      icon: { color: 'terciary', name: 'check' },
    }
  }
  if (status === 4) {
    return {
      statusStr: certificationStatusFormat(status),
      dateStr: `Cancelada el ${dateFormat(updatedDate)}`,
      icon: { color: 'warning', name: 'trash' },
    }
  }
}

// tab globales
const Tab1 = ({
  adminsCount,
  businessCount,
  certificationsCount,
  doctorsCount,
  patientsCount,
  pathologiesCount,
  certifications = [],
  filteredDates,
}) => {
  const formatDateRange =
    filteredDates.from && filteredDates.to
      ? `${dateFormat(filteredDates.from)} - ${dateFormat(filteredDates.to)}`
      : ''

  const data = [
    {
      name: `Realizadas (${certificationsCount.done || '0'})`,
      value: [certificationsCount.all, certificationsCount.all - certificationsCount.done],
      fill: theme.colors.primary,
    },
  ]
  const adminsCard = {
    icon: 'admin',
    title: 'Administradores:',
    items: [adminsCount] || '0',
  }
  const businessCard = {
    icon: 'business',
    title: 'Empresas:',
    items: [businessCount] || '0',
  }

  const certificationsCard = {
    icon: 'certification',
    title: `Certificaciones (${certificationsCount.all || '0'})`,
    items: [
      {
        label: 'Solicitadas:',
        value: certificationsCount.pending || '0',
      },
      {
        label: 'Asignadas:',
        value: certificationsCount.assigned || '0',
      },
      {
        label: 'Realizadas:',
        value: certificationsCount.done || '0',
      },
      {
        label: 'Canceladas:',
        value: certificationsCount.cancelled || '0',
      },
    ],
  }

  const doctorsCard = {
    icon: 'doctor',
    title: `Médicos (${doctorsCount.all})`,
    items: [
      {
        label: 'Activos:',
        value: doctorsCount.active || '0',
      },
      {
        label: 'Inactivos:',
        value: doctorsCount.inactive || '0',
      },
    ],
  }

  const patientsCard = {
    icon: 'employees',
    title: 'Pacientes:',
    items: [patientsCount] || '0',
  }

  const pathologiesCard = {
    icon: 'pathology',
    title: 'Patologías:',
    items: [pathologiesCount] || '0',
  }

  const certificationsByDate = [
    {
      name: `${formatDateRange} (${certificationsCount.all || '0'})`,
      Solicitadas: certificationsCount.pending,
      Asignadas: certificationsCount.assigned,
      Realizadas: certificationsCount.done,
      Canceladas: certificationsCount.cancelled,
    },
  ]

  return (
    <>
      <Row>
        <FlatCard {...adminsCard} />
        <FlatCard {...businessCard} />
        <FlatCard {...patientsCard} />
        <FlatCard {...pathologiesCard} />
      </Row>
      <Row>
        <FlatCard {...certificationsCard} />
        <FlatCard {...doctorsCard} />
      </Row>

      <Row>
        <FlatCard
          icon="certification"
          title={`Certificaciones ${formatDateRange ? `de ${formatDateRange}` : 'Historicas'}`}
          autoCol
        >
          <ResponsiveContainer width={400} aspect={2}>
            <BarChart
              data={certificationsByDate}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Legend />
              <Bar dataKey="Solicitadas" fill="#D2191999" label={{ position: 'top' }} />
              <Bar dataKey="Asignadas" fill="#3FC43499" label={{ position: 'top' }} />
              <Bar dataKey="Realizadas" fill="#00aaff99" label={{ position: 'top' }} />
              <Bar dataKey="Canceladas" fill="#F0BE0099" label={{ position: 'top' }} />
            </BarChart>
          </ResponsiveContainer>
        </FlatCard>
        <FlatCard
          icon="certification"
          title={`Realizadas ${formatDateRange ? `de ${formatDateRange}` : 'Historicas'}`}
          autoCol
        >
          <ResponsiveContainer width={400} aspect={2}>
            <RadialBarChart
              cx="50%"
              cy="50%"
              innerRadius="70%"
              outerRadius="100%"
              barSize={20}
              data={data}
              startAngle={90}
              endAngle={450}
            >
              <RadialBar minAngle={15} background clockWise={false} dataKey="value" />
              <Legend
                iconSize={10}
                width={120}
                height={140}
                layout="vertical"
                verticalAlign="middle"
                align="right"
              />
            </RadialBarChart>
          </ResponsiveContainer>
        </FlatCard>
      </Row>
      <Row>
        <FlatCard icon="employees" title="Actividad en el Período">
          {certifications.map((certificationObj, i) => {
            // build full name string.
            const patientFullName = getNameFull(
              certificationObj.patientData?.firstname,
              certificationObj.patientData?.lastname,
              certificationObj.patientData?.middlename
            )
            const doctorFullName = getNameFull(
              certificationObj.doctorData?.firstname,
              certificationObj.doctorData?.lastname,
              certificationObj.doctorData?.middlename
            )
            const businessFullName = certificationObj.businessName

            const copy = `Empresa: ${businessFullName}, Paciente: ${patientFullName}${
              doctorFullName ? `, Médico: ${doctorFullName}` : ''
            }`

            const { statusStr, dateStr, icon } = certificationContent(
              certificationObj.status,
              certificationObj.createdDate,
              certificationObj.updatedDate,
              certificationObj.medicAct
            )

            return (
              <Item
                key={i}
                index={i + 1}
                status={statusStr}
                copy={copy}
                date={dateStr}
                icon={icon}
                isLast={i === certifications.length - 1}
              />
            )
          })}
        </FlatCard>
      </Row>
    </>
  )
}

const Tab2 = ({ certificationsCount, filteredDates }) => {
  const formatDateRange =
    filteredDates.from && filteredDates.to
      ? `${dateFormat(filteredDates.from)} - ${dateFormat(filteredDates.to)}`
      : ''

  const data = [
    {
      name: `Realizadas (${certificationsCount.done || '0'})`,
      value: [certificationsCount.all, certificationsCount.all - certificationsCount.done] || '0',
      fill: theme.colors.primary,
    },
  ]

  const certificationsCard = {
    icon: 'certification',
    title: `Certificaciones (${certificationsCount.all || '0'})`,
    items: [
      {
        label: 'Solicitadas:',
        value: certificationsCount.pending || '0',
      },
      {
        label: 'Asignadas:',
        value: certificationsCount.assigned || '0',
      },
      {
        label: 'Realizadas:',
        value: certificationsCount.done || '0',
      },
      {
        label: 'Canceladas:',
        value: certificationsCount.cancelled || '0',
      },
    ],
  }

  const certificationsByDate = [
    {
      name: `Abril (${certificationsCount.all || '0'})`,
      Solicitadas: certificationsCount.pending,
      Asignadas: certificationsCount.assigned,
      Realizadas: certificationsCount.done,
      Canceladas: certificationsCount.cancelled,
    },
  ]

  return (
    <>
      <Row>
        <FlatCard {...certificationsCard} />

        <FlatCard
          icon="certification"
          title={`Certificaciones ${formatDateRange ? `de ${formatDateRange}` : 'Historicas'}`}
          autoCol
        >
          <ResponsiveContainer width={400} aspect={2}>
            <BarChart
              data={certificationsByDate}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Legend />
              <Bar dataKey="Solicitadas" fill="#D2191999" label={{ position: 'top' }} />
              <Bar dataKey="Asignadas" fill="#3FC43499" label={{ position: 'top' }} />
              <Bar dataKey="Realizadas" fill="#00aaff99" label={{ position: 'top' }} />
              <Bar dataKey="Canceladas" fill="#F0BE0099" label={{ position: 'top' }} />
            </BarChart>
          </ResponsiveContainer>
        </FlatCard>
        <FlatCard
          icon="certification"
          title={`Realizadas ${formatDateRange ? `de ${formatDateRange}` : 'Historicas'}`}
          autoCol
        >
          <ResponsiveContainer width={400} aspect={2}>
            <RadialBarChart
              cx="50%"
              cy="50%"
              innerRadius="70%"
              outerRadius="100%"
              barSize={20}
              data={data}
              startAngle={90}
              endAngle={450}
            >
              <RadialBar minAngle={15} background clockWise={false} dataKey="value" />
              <Legend
                iconSize={10}
                width={120}
                height={140}
                layout="vertical"
                verticalAlign="middle"
                align="right"
              />
            </RadialBarChart>
          </ResponsiveContainer>
        </FlatCard>
      </Row>
    </>
  )
}

const Tab3 = ({ certifications, certificationsCount, filteredDates }) => {
  const formatDateRange =
    filteredDates.from && filteredDates.to
      ? `${dateFormat(filteredDates.from)} - ${dateFormat(filteredDates.to)}`
      : ''

  // build certification array by status
  const certificationsStatus = (status = 0, certificationsSet = certifications) => {
    return certificationsSet.filter((certificationObj) => {
      if (
        certificationsSet.length === 0 ||
        certificationObj.status !== status ||
        typeof certificationObj.status !== 'number'
      ) {
        return null
      }
      return certificationObj
    })
  }

  const assignedCertifications = certificationsStatus(1)
  const doneCertifications = certificationsStatus(2)
  const activeCertifications = certificationsStatus(3)
  const canceledCertifications = certificationsStatus(4)

  const data = [
    {
      name: `Realizadas (${certificationsCount.done || '0'})`,
      value: [certificationsCount.all, certificationsCount.all - certificationsCount.done] || '0',
      fill: theme.colors.primary,
    },
  ]

  const certificationsCard = {
    icon: 'certification',
    title: `Certificaciones (${certificationsCount.all || '0'})`,
    items: [
      {
        label: 'Solicitadas:',
        value: certificationsCount.pending || '0',
      },
      {
        label: 'Asignadas:',
        value: certificationsCount.assigned || '0',
      },
      {
        label: 'Realizadas:',
        value: certificationsCount.done || '0',
      },
      {
        label: 'Canceladas:',
        value: certificationsCount.cancelled || '0',
      },
    ],
  }

  const certificationsByDate = [
    {
      name: `Abril (${certificationsCount.all || '0'})`,
      Solicitadas: certificationsCount.pending || '0',
      Asignadas: certificationsCount.assigned || '0',
      Realizadas: certificationsCount.done || '0',
      Canceladas: certificationsCount.cancelled || '0',
    },
  ]

  return (
    <>
      <Row>
        <FlatCard {...certificationsCard} />

        <FlatCard
          icon="certification"
          title={`Certificaciones ${formatDateRange ? `de ${formatDateRange}` : 'Historicas'}`}
          autoCol
        >
          <ResponsiveContainer width={400} aspect={2}>
            <BarChart
              data={certificationsByDate}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Legend />
              <Bar dataKey="Solicitadas" fill="#D2191999" label={{ position: 'top' }} />
              <Bar dataKey="Asignadas" fill="#3FC43499" label={{ position: 'top' }} />
              <Bar dataKey="Realizadas" fill="#00aaff99" label={{ position: 'top' }} />
              <Bar dataKey="Canceladas" fill="#F0BE0099" label={{ position: 'top' }} />
            </BarChart>
          </ResponsiveContainer>
        </FlatCard>
        <FlatCard
          icon="certification"
          title={`Realizadas ${formatDateRange ? `de ${formatDateRange}` : 'Historicas'}`}
          autoCol
        >
          <ResponsiveContainer width={400} aspect={2}>
            <RadialBarChart
              cx="50%"
              cy="50%"
              innerRadius="70%"
              outerRadius="100%"
              barSize={20}
              data={data}
              startAngle={90}
              endAngle={450}
            >
              <RadialBar minAngle={15} background clockWise={false} dataKey="value" />
              <Legend
                iconSize={10}
                width={120}
                height={140}
                layout="vertical"
                verticalAlign="middle"
                align="right"
              />
            </RadialBarChart>
          </ResponsiveContainer>
        </FlatCard>
      </Row>
      <Row>
        <FlatCard icon="employees" title="Actividad en el Período">
          {[
            ...doneCertifications,
            ...activeCertifications,
            ...assignedCertifications,
            ...canceledCertifications,
          ].map((certificationObj, i) => {
            // build full name string.
            const patientFullName = getNameFull(
              certificationObj.patientData?.firstname,
              certificationObj.patientData?.lastname,
              certificationObj.patientData?.middlename
            )

            const { statusStr, dateStr, icon } = certificationContent(
              certificationObj.status,
              certificationObj.createdDate,
              certificationObj.updatedDate,
              certificationObj.medicAct
            )

            return (
              <Item
                key={i}
                index={i + 1}
                status={statusStr}
                copy={patientFullName}
                date={dateStr}
                icon={icon}
                isLast={
                  i ===
                  [
                    ...doneCertifications,
                    ...activeCertifications,
                    ...assignedCertifications,
                    ...canceledCertifications,
                  ].length -
                    1
                }
              />
            )
          })}
        </FlatCard>
        <FlatCard icon="employees" title="Certificaciones realizadas">
          {[...doneCertifications, ...activeCertifications].map((certificationObj, i) => {
            if (
              certificationObj.status === 0 ||
              certificationObj.status === 1 ||
              certificationObj.status === 4
            )
              return null

            // build full name string.
            const patientFullName = getNameFull(
              certificationObj.patientData?.firstname,
              certificationObj.patientData?.lastname,
              certificationObj.patientData?.middlename
            )

            const { statusStr, dateStr, icon } = certificationContent(
              certificationObj.status,
              certificationObj.createdDate,
              certificationObj.updatedDate,
              certificationObj.medicAct
            )

            return (
              <Item
                key={i}
                index={i + 1}
                status={statusStr}
                copy={patientFullName}
                date={dateStr}
                icon={icon}
                isLast={i === [...doneCertifications, ...activeCertifications].length - 1}
              />
            )
          })}
        </FlatCard>
      </Row>
    </>
  )
}

// partials.
const Row = styled.div({
  display: 'flex',
  gap: '16px',
  margin: '0 auto 16px',
  maxWidth: '100%',
  width: '100%',
})

// main component.
const DevelopmentHomeScreen = ({ navMenu, navTitle }) => {
  // states.
  const [filteredValues, setFilteredValues] = useState({})
  // hooks.
  const { adminsCount } = useAdminsData()
  const { patients, patientsCount } = usePatientsData()
  const { businessCount, businessSelect } = useBusinessData()
  const { doctors, doctorsCount, doctorsSelect } = useDoctorsData()
  const { certificationsCount, filteredCertificationsByDate, filteredCertificationsByDateCount } =
    useCertificationsData(
      patients,
      doctors,
      null,
      filteredValues?.businessId,
      filteredValues?.doctorId,
      filteredValues
    )

  const { pathologiesCount } = usePathologiesData()
  // data object
  const businessSelectOptions = businessSelect()
  const doctorsSelectOptions = doctorsSelect()

  const tabProps = {
    adminsCount,
    businessCount,
    certificationsCount:
      filteredCertificationsByDateCount.all !== certificationsCount.all
        ? filteredCertificationsByDateCount
        : certificationsCount,
    doctorsCount,
    patientsCount,
    pathologiesCount,
    certifications: filteredCertificationsByDate.all,
    filteredDates: { from: filteredValues.fromDate, to: filteredValues.toDate },
  }

  const tabLabels = [
    {
      label: 'Globales',
      component: (
        <Tab1
          {...tabProps}
          certificationsCount={
            filteredCertificationsByDateCount.all !== certificationsCount.all
              ? filteredCertificationsByDateCount
              : certificationsCount
          }
        />
      ),
      search: false,
      filter: true,
      exportReport: true,
    },
    {
      label: 'Empresas',
      component: <Tab2 {...tabProps} />,
      selectInput: {
        name: 'business',
        options: businessSelectOptions,
        iconL: 'business',
        showLabel: true,
        placeholder: 'Empresa',
      },
      search: false,
      filter: true,
      exportReport: true,
    },
    {
      label: 'Medicos',
      component: <Tab3 {...tabProps} />,
      selectInput: {
        name: 'doctor',
        options: doctorsSelectOptions,
        iconL: 'doctor',
        showLabel: true,
        placeholder: 'Médico',
      },
      search: false,
      filter: true,
      exportReport: true,
    },
  ]

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <AdminTemplate navigation={navMenu} sectionTitle={navTitle}>
      <Tabs items={tabLabels} onClick={(value) => setFilteredValues(value)} />
    </AdminTemplate>
  )
}

export default DevelopmentHomeScreen
