// dependencies.
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
// providers.
import AuthProvider from './js/contexts/AuthContext'
// components.
import App from './App'

// define the root for the app.
const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

// render app.
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </HelmetProvider>
)
