// dependencies.
import { collection, query, where } from 'firebase/firestore'
// utils.
import useFetch from '../useFetch'
import { db } from '../../firebase/firebase'
import { getNameFull } from '../../usernameUtils'
import { useAuth } from '../../contexts/AuthContext'

/*********************/
/*                   */
/*    Admins Data    */
/*                   */
/*********************/

const useAdminsData = (reload, access = ['superadmin', 'developer']) => {
  // contexts.
  const { user, userData } = useAuth()

  // ------------------------------------------------------------------------------------------- //
  // FETCH DATABASE DATA                                                                         //
  // ------------------------------------------------------------------------------------------- //

  // database query.
  const q = query(collection(db, 'users'), where('access', '==', 'superadmin'))

  // fetch all admin users from firebase's collection.
  const { data, empty } = useFetch({ q, access, user, userData, name: 'Admins' }, [reload])

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // filter admins for Admin Admins page.
  const adminsTable = (adminsSet = data) => {
    return adminsSet.map((adminObj) => {
      const { firstname, lastname, phone, email } = adminObj

      // table items.
      return {
        items: [
          { copy: getNameFull(firstname, lastname), grow: 2, label: 'Nombre:', icon: 'user' },
          { copy: phone, label: 'Teléfono:', icon: 'wallet' },
          { copy: email, grow: 2, label: 'E-Mail:', icon: 'copy' },
        ],
        raw: adminObj,
      }
    })
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return values.
  return { admins: data, adminsCount: data.length, adminsTable, empty }
}

export default useAdminsData
