// dependencies.
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import PatientData from '../../modals/PatientData'
import PatientHistory from '../../modals/PatientHistory'
import Table from '../../components/Table'
import { Drawer, DrawerWithMap } from '../../components/Modal'
import { Form } from '../../components/Form'
import { Loader } from '../../components/Icon'
import { SearchHeader } from '../../components/Layout'
// utils.
import notifyToast from '../../js/notifyToast'
import search from '../../js/search'
import { db, getBusinessByRef, getPatientById } from '../../js/firebase/firebase'
import { getNameFull } from '../../js/usernameUtils'
import {
  parseAddressToLatLng,
  reverseLatLngToAddress,
  toAddressString,
  toAddressObject,
} from '../../js/parseAddress'
import { useAuth } from '../../js/contexts/AuthContext'
import { useCertificationsData, usePatientsData } from '../../js/hooks/data'
// constants.
import { DELETE_PATIENT_FORM, NEW_PATIENT_FORM, UPDATE_PATIENT_FORM } from './constants'
import { NEW_CERTIFICATION_FORM_EMPLOYEE } from '../Business/constants'

/********************************/
/*                              */
/*    Admin Employees Screen    */
/*                              */
/********************************/

// helpers.
const tableHeader = [
  { label: 'Cédula' },
  { label: 'Nro. Func.' },
  { label: 'Nombre Completo', grow: 2 },
  { label: 'Teléfono' },
  { label: 'Email', grow: 2 },
  { label: 'Acciones', grow: 2 },
]

// main component.
const AdminEmployeesScreen = ({ navMenu, navTitle }) => {
  const [reload, setReload] = useState(false)
  // contexts.
  const { isLoaded } = useAuth()
  // hooks.
  const params = useParams()
  const { patients, empty } = usePatientsData()
  const { certifications } = useCertificationsData(patients)
  const { patientsTableItems } = usePatientsData(certifications, params.businessId, reload)
  // states.
  const [loaded, setLoaded] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [mapModalOpen, setMapModalOpen] = useState(false)
  const [searched, setSearched] = useState(patientsTableItems)
  const [enableMoveMarker, setEnableMoveMarker] = useState(false)
  // ------------------------------------------------------------------------------------------- //
  // SEARCH                                                                                      //
  // ------------------------------------------------------------------------------------------- //

  // search for items in the array.
  const handleSearch = (term) => {
    setSearched(search(patientsTableItems, term))
  }

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  const handleMapModalOpen = (open) => {
    setMapModalOpen(open)
  }

  // handle add new employee modal.
  const handleCreateEmployeeModal = () => {
    setModalContent({
      title: 'Agregar nuevo Empleado',
      component: (
        <Form items={NEW_PATIENT_FORM} onClick={(obj) => handleCreateEmployeeClick(obj)} />
      ),
    })

    setModalOpen(true)
  }

  // handle edit selected employee modal.
  const handleEditEmployeeModal = async (obj) => {
    setEnableMoveMarker(true)
    obj.patientData.fullname = getNameFull(obj.patientData?.firstname, obj.patientData?.lastname)

    let newPoint
    if (!obj.address.lat || !obj.address.lng) {
      newPoint = await parseAddressToLatLng(toAddressString(obj.address))
    } else {
      newPoint = { lat: obj.address.lat, lng: obj.address.lng }
    }
    newPoint.title = obj.patientData.fullname

    const handleChangeAddress = async ({ lat, lng }) => {
      try {
        const newAddress = await reverseLatLngToAddress(lat, lng)
        return toAddressObject(newAddress)
      } catch (error) {
        console.error(error)
      }
    }

    setModalContent({
      title: 'Actualizar Empleado',
      pointsToCheck: Array(newPoint),
      employeeId: obj.ref.id,
      employeeAddress: obj.address,
      handleChangeAddress: ({ lat, lng }) => {
        handleChangeAddress({ lat, lng }).then((res) => (obj.address = res))
      },
      component: (
        <Form
          items={UPDATE_PATIENT_FORM}
          defaultValues={obj}
          onClick={(obj) => handleUpdateEmployeeClick(obj)}
        />
      ),
    })

    isLoaded && setMapModalOpen(true)
  }

  // handle delete selected employee modal.
  const handleDeleteEmployeeModal = async (obj) => {
    setModalContent({
      title: 'Eliminar Empleado',
      component: (
        <Form
          items={DELETE_PATIENT_FORM}
          defaultValues={obj}
          onClick={(obj) => handleDeleteEmployeeClick(obj)}
        />
      ),
    })

    isLoaded && setModalOpen(true)
  }

  // handle show request certification for selected employee modal.
  const handleNewCertificationModal = async (obj) => {
    setEnableMoveMarker(false)
    const { firstname, lastname, id, address } = obj
    const defaultValues = {
      fullname: getNameFull(firstname, lastname),
      document: id,
      address,
    }

    let newPoint
    if (!obj.address.lat || !obj.address.lng) {
      newPoint = await parseAddressToLatLng(toAddressString(obj.address))
    } else {
      newPoint = { lat: obj.address.lat, lng: obj.address.lng }
    }
    newPoint.title = defaultValues.fullname

    const handleChangeAddress = async ({ lat, lng }) => {
      try {
        const newAddress = await reverseLatLngToAddress(lat, lng)
        return toAddressObject(newAddress)
      } catch (error) {
        console.error(error)
      }
    }

    setModalContent({
      title: 'Solicitar certificación',
      pointsToCheck: Array(newPoint),
      handleChangeAddress: ({ lat, lng }) => {
        handleChangeAddress({ lat, lng }).then((res) => (obj.address = res))
      },
      component: (
        <Form
          items={NEW_CERTIFICATION_FORM_EMPLOYEE}
          defaultValues={defaultValues}
          onClick={(obj) => handleCreateCertificationClick(obj)}
        />
      ),
    })

    isLoaded && setMapModalOpen(true)
  }

  // handle show certification history for selected patient modal.
  const handleViewHistoryModal = (obj) => {
    setModalContent({
      title: 'Historial del Empleado',
      component: <PatientHistory patient={obj} />,
    })

    setModalOpen(true)
  }

  // handle show employee data for selected employee modal.
  const handleViewEmployeeModal = (obj) => {
    const { firstname, lastname } = obj

    setModalContent({
      title: getNameFull(firstname, lastname),
      component: <PatientData employee={obj} />,
    })

    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //
  // handle new employee.
  const handleCreateEmployeeClick = async (obj) => {
    const employeeRef = doc(db, 'patients', obj.id)
    const businessRef = doc(db, 'business', params.businessId)

    const alreadyExists = await getPatientById(obj.id)
    if (alreadyExists) {
      notifyToast('Ya existe un empleado en el sistema con la cédula ingresada', 'warning')
      return
    }

    await setDoc(employeeRef, { ...obj, businessRef: [businessRef], businessRefHistory: [businessRef], ref: employeeRef })
      .then(() => notifyToast('Empleado guardado correctamente', 'success'))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // handle employee information update.
  const handleUpdateEmployeeClick = async (obj) => {
    const employeeRef = doc(db, 'patients', obj.id)
    const updateFields = {
      firstname: obj.firstname,
      middlename: obj.middlename,
      lastname: obj.lastname,
      employeenumber: obj.employeenumber,
      address: obj.address,
      comments: obj.comments,
      updatedDate: new Date(),
    }

    await setDoc(employeeRef, updateFields, { merge: true })
      .then(() => notifyToast('Empleado guardado correctamente', 'success'))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setMapModalOpen(false)
    setReload(!reload)
  }

  // handle employee information update.
  const handleDeleteEmployeeClick = async (obj) => {
    const employeeRef = doc(db, 'patients', obj.id)

    const index = obj.businessRef.findIndex((business) => business.id === params.businessId)
    if (index > -1) {
      obj.businessRef.splice(index, 1)

      const updateFields = {
        businessRef: obj.businessRef,
        updatedDate: new Date(),
      }

      await setDoc(employeeRef, updateFields, { merge: true })
        .then(() => notifyToast('Empleado eliminado correctamente', 'success'))
        .catch(() => notifyToast('Ha ocurrido un error', 'error'))
    }

    setModalOpen(false)
    setReload(!reload)
  }

  // handle request new certification.
  const handleCreateCertificationClick = async (obj) => {
    const patientRef = doc(db, 'patients', obj.document)
    const businessRef = doc(db, 'business', params.businessId)
    const business = await getBusinessByRef(businessRef)
    const address = `${obj.address.street} ${obj.address.number}, ${obj.address.district}, ${obj.address.city}, Uruguay`
    const { lat, lng } = await parseAddressToLatLng(address)

    await addDoc(collection(db, 'certifications'), {
      address: { ...obj?.address, lat, lng },
      createdDate: new Date(),
      updatedDate: new Date(),
      status: 0,
      patientRef,
      businessRef,
      businessName: business.data().name,
      comments: obj.comments,
    })
      .then(async (certRef) => {
        await setDoc(certRef, { ref: certRef }, { merge: true })
        notifyToast('Certificación solicitada correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))
  }

  const handleConfirmLatLngClick = async (obj) => {
    modalContent.employeeAddress.lat = obj.lat()
    modalContent.employeeAddress.lng = obj.lng()

    const updateFields = {
      address: { ...modalContent?.employeeAddress },
      updatedDate: new Date(),
    }

    const employeeRef = doc(db, 'patients', modalContent.employeeId)

    await setDoc(employeeRef, updateFields, { merge: true })
      .then(() => notifyToast('Empleado actualizado correctamente', 'success'))
      .then(setTimeout(() => setMapModalOpen(false), 1000))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // Without initially setting and clearing search on resourceLoaded in this way, causes asset list
  // children to draw incorrectly, with logos swapped out between assets, reason not known.
  // Removing sort removes the need for this.
  useEffect(() => {
    handleSearch('')
  }, [patientsTableItems])

  // only show content when loaded.
  useEffect(() => {
    !empty && setLoaded(true)
  }, [empty])

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={mapModalOpen || modalOpen}
        onOverlayClick={() => {
          setModalOpen(false)
          setMapModalOpen(false)
        }}
      >
        <SearchHeader
          label="Agregar empleado"
          placeholder="Buscar por: cédula, nombre, dirección..."
          onClick={() => handleCreateEmployeeModal()}
          onChange={(term) => handleSearch(term)}
        />

        {loaded ? (
          <Table
            header={tableHeader}
            items={searched}
            //
            onDetail={(obj) => handleViewEmployeeModal(obj)}
            //
            enableRequest
            onRequest={(obj) => handleNewCertificationModal(obj)}
            //
            enableShowHistory
            onShowHistory={(obj) => handleViewHistoryModal(obj)}
            //
            enableEdit
            onEdit={(obj) => handleEditEmployeeModal(obj)}
            //
            enableDelete
            onDelete={(obj) => handleDeleteEmployeeModal(obj)}
          />
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>

      <DrawerWithMap
        title={modalContent.title}
        pointsToCheck={modalContent.pointsToCheck}
        isOpen={mapModalOpen}
        onChange={handleMapModalOpen}
        onChangeLatLng={handleConfirmLatLngClick}
        onChangeAddress={modalContent.handleChangeAddress}
        enableMoveMarker={enableMoveMarker}
      >
        {modalContent.component}
      </DrawerWithMap>
    </>
  )
}

export default AdminEmployeesScreen
