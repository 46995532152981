// dependencies.
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
// components.
import Head from '../components/Meta/Head'
import { Body } from '../components/Typography'
import { Input } from '../components/Form'
import { ScreenWrapper, Topbar, Wrapper } from '../components/Layout'
import { ToastContainer } from 'react-toastify'
// utils.
import setBackgroundColor from '../js/setBackgroundColor'
import { useAuth } from '../js/contexts/AuthContext'

/******************************/
/*                            */
/*    Auth Screen Template    */
/*                            */
/******************************/

// partials.
const StyledBody = styled(Body)({
  margin: 'auto',
  maxWidth: '360px',
  padding: '15px',
  textAlign: 'center',
})

const FormWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  margin: '0 auto',
  maxWidth: '360px',
  width: '100%',
})

// main component.
const AuthTemplate = ({
  description,
  form,
  submitLabel,
  topbarIcon,
  topbarTitle,
  onChange,
  onSubmit,
  onTopbarAction,
  children,
}) => {
  // contexts.
  const { user, userData } = useAuth()
  // hooks.
  const navigate = useNavigate()
  // states.
  const [values, setValues] = useState({})

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle update value change.
  const handleValueChange = (value, index) => {
    setValues({ ...values, [index]: value })
  }

  // ------------------------------------------------------------------------------------------- //
  // USER SESSION                                                                                //
  // ------------------------------------------------------------------------------------------- //

  useEffect(() => {
    // handle logged in users.
    if (user) navigate('/')
  }, [user])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // propagate value.
  useEffect(() => {
    if (!onChange) return
    onChange(values)
  }, [values])

  // return content.
  return (
    <>
      <Head color={setBackgroundColor(userData.access)} />

      <ScreenWrapper background={setBackgroundColor(userData.access)}>
        <Topbar iconL={topbarIcon} label={topbarTitle} onActionLPress={onTopbarAction} />

        <Wrapper background="white" actionLabel={submitLabel} onClick={onSubmit}>
          <StyledBody color="grey">{description}</StyledBody>

          <FormWrapper>
            {form.map((input, i) => (
              <Input
                key={i}
                iconL={input.iconL || false}
                prefix={input.prefix || false}
                name={input.name || i}
                placeholder={input.placeholder}
                type={input.type || 'text'}
                defaultValue={input.defaultValue || ''}
                onChange={(value) => handleValueChange(value, input.name || i)}
              />
            ))}
          </FormWrapper>

          {children}
        </Wrapper>
      </ScreenWrapper>

      <ToastContainer />
    </>
  )
}

export default AuthTemplate
