// dependencies.
import { useEffect, useState } from 'react'
import { collection, orderBy, query } from 'firebase/firestore'
// utils.
import useFetch from '../useFetch'
import { db } from '../../firebase/firebase'
import { getNameFull } from '../../usernameUtils'
import { useAuth } from '../../contexts/AuthContext'

/***********************/
/*                     */
/*    Patients Data    */
/*                     */
/***********************/

const usePatientsData = (
  certifications,
  businessId,
  reload,
  access = ['superadmin', 'doctor', 'company', 'developer', 'doctor-admin']
) => {
  // contexts.
  const { user, userData } = useAuth()
  // states.
  const [patientsTableItems, sePatientsTableItems] = useState([])
  const [patientsWithoutBusinessTableItems, setPatientsWithoutBusinessTableItems] = useState([])

  // ------------------------------------------------------------------------------------------- //
  // FETCH DATABASE DATA                                                                         //
  // ------------------------------------------------------------------------------------------- //

  // database query.
  const q = query(collection(db, 'patients'), orderBy('lastname', 'asc'))

  // fetch all patients from firebase's collection.
  const { data, empty } = useFetch({ q, access, user, userData, name: 'Patients' }, [reload])

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // process patients list for select input for Admin Home page.
  const patientsSelect = (patientsSet = data) => {
    return patientsSet.map((patientObj) => {
      const { firstname, lastname, id, middlename, comments, address, businessRef } = patientObj

      // select options.
      return {
        label: getNameFull(firstname, lastname),
        name: id,
        address,
        document: id,
        businessRef,
        patientData: {
          id,
          firstname: firstname || '',
          lastname: lastname || '',
          middlename: middlename || '',
          comments: comments || '',
          phone: address.phone,
          email: address.email,
        },
      }
    })
  }

  // filter patients for Admin Employees page.
  const patientsTable = (businessId = null, certificationsSet = [], patientsSet = data) => {
    // select business specific patients.
    const filterPatients = patientsSet.filter((p) =>
      p?.businessRef?.some((ref) => ref.id === businessId)
    )

    // build patientData object.
    const newSet = filterPatients.map((patientObj) => {
      const certificationsArr = certificationsSet.filter(
        (key) => JSON.stringify(key.patientRef) === JSON.stringify(patientObj.ref)
      )
      const { firstname, lastname, id, middlename, comments, address, businessRef } = patientObj

      return {
        ...patientObj,
        patientData: {
          id,
          firstname: firstname || '',
          lastname: lastname || '',
          middlename: middlename || '',
          comments: comments || '',
          phone: address.phone,
          email: address.email,
        },
        businessRef,
        certifications: certificationsArr,
        disableShowHistory: !certificationsArr.length,
      }
    })

    // table items.
    return (
      newSet &&
      newSet.map((obj) => {
        const { id, employeenumber, firstname, middlename, lastname, disableShowHistory, address } =
          obj

        return {
          items: [
            { copy: id, label: 'Documento:', triggerDetail: true },
            { copy: employeenumber, label: 'Nro. Func.:' },
            { copy: getNameFull(firstname, lastname, middlename), grow: 2 },
            { copy: address.phone },
            { copy: address.email, grow: 2 },
          ],
          disableShowHistory,
          raw: obj,
        }
      })
    )
  }

  const patientsWithoutBusinessTable = (certificationsSet = [], patientsSet = data) => {
    // select business specific patients.
    const filterPatients = patientsSet.filter((p) => {
      if (!p.businessRef || p.businessRef.length === 0) return p
      return null
    })

    // build patientData object.
    const newSet = filterPatients.map((patientObj) => {
      const certificationsArr = certificationsSet.filter(
        (key) => JSON.stringify(key.patientRef) === JSON.stringify(patientObj.ref)
      )
      const { firstname, lastname, id, middlename, comments, address, businessRef } = patientObj

      return {
        ...patientObj,
        patientData: {
          id,
          firstname: firstname || '',
          lastname: lastname || '',
          middlename: middlename || '',
          comments: comments || '',
          phone: address.phone,
          email: address.email,
        },
        businessRef,
        certifications: certificationsArr,
        disableShowHistory: !certificationsArr.length,
      }
    })

    // table items.
    return (
      newSet &&
      newSet.map((obj) => {
        const { id, employeenumber, firstname, middlename, lastname, disableShowHistory, address } =
          obj

        return {
          items: [
            { copy: id, label: 'Documento:', triggerDetail: true },
            { copy: employeenumber, label: 'Nro. Func.:' },
            { copy: getNameFull(firstname, lastname, middlename), grow: 2 },
            { copy: address.phone },
            { copy: address.email, grow: 2 },
          ],
          disableShowHistory,
          raw: obj,
        }
      })
    )
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // build patient with certification history items object.
  useEffect(() => {
    if (!businessId || !certifications || !data) return
    sePatientsTableItems(patientsTable(businessId, certifications))
    setPatientsWithoutBusinessTableItems(patientsWithoutBusinessTable(certifications))
  }, [businessId, certifications, data])

  // return values.
  return {
    patients: data,
    patientsCount: data.length,
    patientsSelect,
    patientsTable,
    patientsTableItems,
    patientsWithoutBusinessTable,
    patientsWithoutBusinessTableItems,
    empty,
  }
}

export default usePatientsData
