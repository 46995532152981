// helpers.
const message = ['Puede', 'No puede', 'Consulta médica']

// main function.
const certificationLeaveFormat = (str) => {
  if (str === 'authorized') return message[0]
  if (str === 'unauthorized') return message[1]
  if (str === 'appointment') return message[2]
}

export default certificationLeaveFormat
