// dependencies.
import { useEffect, useState } from 'react'
import styled from 'styled-components'
// components.
import { Icon } from '../Icon'
import { Headline } from '../Typography'
// utils.
import { theme } from '../../styleguide'
import { useDimensions } from '../../js/hooks'

// helpers.
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.div({
  alignItems: 'center',
  background: theme.colors.white,
  filter: `drop-shadow(${theme.shadows.normal})`,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: (props) =>
    props.isMobile ? (props.fullScreen ? '100%' : 'calc(50% - 56px)') : 'calc(50% - 72px)',
  left: (props) => (props.isMobile ? 0 : props.isCondensed ? '56px' : '184px'),
  padding: `${baseUnit / 2}px`,
  position: 'fixed',
  bottom: (props) =>
    props.isOpen ? 0 : props.isMobile ? 'calc(-50% + 56px)' : 'calc(-50% + 72px)',
  right: 0,
  transition: 'bottom 400ms ease, height 400ms ease',
  zIndex: 10,
})

const ContentWrapper = styled.div({
  overflowY: (props) => (props.isMobile && !props.isFullScreen ? 'hidden' : 'auto'),
  padding: 0,
  width: '100%',
})

const Header = styled.div({
  alignItems: 'center',
  background: theme.colors.white,
  display: 'flex',
  gap: `${baseUnit}px`,
  height: `${baseUnit * 4}px`,
  padding: `0 ${baseUnit / 2}px ${baseUnit / 2}px`,
  width: '100%',
})

const CloseElement = styled.button({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
})

// main component.
const BottomDrawer = ({ title, children, isOpen, isCondensed, onChange }) => {
  // hooks
  const { isMobile } = useDimensions()

  // states.
  const [open, setOpen] = useState(isOpen)
  const [fullScreen, setFullScreen] = useState(false)

  let startY

  const handleTouchStart = (e) => {
    startY = e.touches[0].pageY
  }

  const handleTouchMove = (e) => {
    const deltaY = e.touches[0].pageY - startY

    if (deltaY > 200) {
      setFullScreen(false)
    } else if (deltaY < -50) {
      setFullScreen(true)
    }
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // update value object.
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  // propagate value.
  useEffect(() => {
    onChange(open)

    if (!open) setFullScreen(false)
  }, [open])

  // return content.
  return (
    <Wrapper isOpen={open} isMobile={isMobile} isCondensed={isCondensed} fullScreen={fullScreen}>
      <Header>
        <Headline size="smallest">{title}</Headline>
        <CloseElement onClick={() => setOpen(false)}>
          <Icon name="close" size={24} />
        </CloseElement>
      </Header>

      {open && (
        <ContentWrapper
          isMobile={isMobile}
          isFullScreen={fullScreen}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          {children}
        </ContentWrapper>
      )}
    </Wrapper>
  )
}

export default BottomDrawer
