// dependencies.
import { useEffect, useState } from 'react'
import { collection, doc, addDoc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import PatientData from '../../modals/PatientData'
import PatientHistory from '../../modals/PatientHistory'
import Table from '../../components/Table'
import { Drawer } from '../../components/Modal'
import { Form } from '../../components/Form'
import { Loader } from '../../components/Icon'
import { FilterHeader } from '../../components/Layout'
// utils.
import notifyToast from '../../js/notifyToast'
import search from '../../js/search'
import { db, getPatientById } from '../../js/firebase/firebase'
import { getNameFull } from '../../js/usernameUtils'
import { useAuth } from '../../js/contexts/AuthContext'
import {
  useUserBusinessData,
  useBusinessCertificationsData,
  useBusinessPatientsData,
} from '../../js/hooks/data'
// constants.
import {
  NEW_CERTIFICATION_FORM_EMPLOYEE,
  NEW_PATIENT_FORM,
  UPDATE_PATIENT_FORM,
  DELETE_PATIENT_FORM,
} from './constants'
import { parseAddressToLatLng } from '../../js/parseAddress'

/***********************************/
/*                                 */
/*    Business Employees Screen    */
/*                                 */
/***********************************/

// helpers.
const tableHeader = [
  { label: 'Cédula' },
  { label: 'Nro. Func.' },
  { label: 'Nombre Completo', grow: 2 },
  { label: 'Acciones', grow: 2 },
]

// main component.
const BusinessEmployeesScreen = ({ navMenu, navTitle }) => {
  const [reload, setReload] = useState(false)
  // contexts.
  const { userData } = useAuth()
  // hooks.
  const { business } = useUserBusinessData()
  // data objects.
  const { patients } = useBusinessPatientsData(business)
  const { certifications } = useBusinessCertificationsData(business, patients)
  const { patientsTableItems, empty } = useBusinessPatientsData(business, certifications, reload)
  // states.
  const [loaded, setLoaded] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [searched, setSearched] = useState(patientsTableItems)

  // ------------------------------------------------------------------------------------------- //
  // SEARCH                                                                                      //
  // ------------------------------------------------------------------------------------------- //

  // search for items in the array.
  const handleSearch = (term) => {
    setSearched(search(patientsTableItems, term))
  }

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  // handle add new employee modal.
  const handleNewEmployeeModal = () => {
    setModalContent({
      title: 'Agregar nuevo Empleado',
      component: (
        <Form items={NEW_PATIENT_FORM} onClick={(obj) => handleCreateEmployeeClick(obj)} />
      ),
    })

    setModalOpen(true)
  }

  // handle edit selected employee modal.
  const handleEditEmployeeModal = (obj) => {
    setModalContent({
      title: 'Actualizar Empleado',
      component: (
        <Form
          items={UPDATE_PATIENT_FORM}
          defaultValues={obj}
          onClick={(obj) => handleUpdateEmployeeClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // handle delete selected employee modal.
  const handleDeleteEmployeeModal = async (obj) => {
    setModalContent({
      title: 'Eliminar Empleado',
      component: (
        <Form
          items={DELETE_PATIENT_FORM}
          defaultValues={obj}
          onClick={(obj) => handleDeleteEmployeeClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // handle show request certification for selected employee modal.
  const handleNewCertificationModal = (obj) => {
    const { firstname, lastname, id, address } = obj
    const defaultValues = {
      fullname: getNameFull(firstname, lastname),
      document: id,
      address,
    }

    setModalContent({
      title: 'Solicitar certificación',
      component: (
        <Form
          items={NEW_CERTIFICATION_FORM_EMPLOYEE}
          defaultValues={defaultValues}
          onClick={(obj) => handleSaveCertificationClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // handle show certification history for selected patient modal.
  const handleViewHistoryModal = (obj) => {
    setModalContent({
      title: 'Historial del Empleado',
      component: <PatientHistory patient={obj} />,
    })

    setModalOpen(true)
  }

  // handle show employee data for selected employee modal.
  const handleViewEmployeeModal = (obj) => {
    const { firstname, lastname } = obj

    setModalContent({
      title: getNameFull(firstname, lastname),
      component: <PatientData employee={obj} />,
    })

    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle new employee.
  const handleCreateEmployeeClick = async (obj) => {
    const employeeRef = doc(db, 'patients', obj.id)
    const businessRef = doc(db, 'business', userData.businessRef?.id)

    const alreadyExists = await getPatientById(obj.id)
    if (alreadyExists) {
      notifyToast('Ya existe un empleado en el sistema con la cédula ingresada', 'warning')
      return
    }

    obj.isActive = true

    await setDoc(employeeRef, { ...obj, businessRef: [businessRef], businessRefHistory: [businessRef], ref: employeeRef })
      .then(() => notifyToast('Empleado guardado correctamente', 'success'))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // handle employee information update.
  const handleDeleteEmployeeClick = async (obj) => {
    const employeeRef = doc(db, 'patients', obj.id)

    const index = obj.businessRef.findIndex((business) => business.id === userData.businessRef?.id)
    if (index > -1) {
      obj.businessRef.splice(index, 1)

      const updateFields = {
        businessRef: obj.businessRef,
        updatedDate: new Date(),
      }

      await setDoc(employeeRef, updateFields, { merge: true })
        .then(() => notifyToast('Empleado eliminado correctamente', 'success'))
        .catch(() => notifyToast('Ha ocurrido un error', 'error'))
    }

    setModalOpen(false)
    setReload(!reload)
  }

  // handle employee information update.
  const handleUpdateEmployeeClick = async (obj) => {
    const employeeRef = doc(db, 'patients', obj.id)
    const updateFields = {
      firstname: obj.firstname,
      middlename: obj.middlename,
      lastname: obj.lastname,
      employeenumber: obj.employeenumber,
      address: obj.address,
      comments: obj.comments,
      updatedDate: new Date(),
    }
    await setDoc(employeeRef, updateFields, { merge: true })
      .then(() => notifyToast('Empleado guardado correctamente', 'success'))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // handle request new certification.
  const handleSaveCertificationClick = async (obj) => {
    const patientRef = doc(db, 'patients', obj.document)
    const address = `${obj.address.street} ${obj.address.number}, ${obj.address.district}, ${obj.address.city}, Uruguay`
    const { lat, lng } = await parseAddressToLatLng(address)

    await addDoc(collection(db, 'certifications'), {
      address: { ...obj?.address, lat, lng },
      createdDate: new Date(),
      updatedDate: new Date(),
      status: 0,
      patientRef,
      businessRef: business[0].ref,
      businessName: business[0].name,
      comments: obj.comments,
    })
      .then(async (certRef) => {
        await setDoc(certRef, { ref: certRef }, { merge: true })
        notifyToast('Certificación solicitada correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // Without initially setting and clearing search on resourceLoaded in this way, causes asset list
  // children to draw incorrectly, with logos swapped out between assets, reason not known.
  // Removing sort removes the need for this.
  useEffect(() => {
    handleSearch('')
  }, [patientsTableItems])

  // only show content when loaded.
  useEffect(() => {
    !empty && setLoaded(true)
  }, [empty])

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        <FilterHeader
          label="Agregar Empleado"
          placeholder="Buscar por: cédula, nombre, dirección, nro. funcionario..."
          onActionClick={() => handleNewEmployeeModal()}
          onChange={(term) => handleSearch(term)}
        />

        {loaded ? (
          <Table
            header={tableHeader}
            items={searched}
            //
            onDetail={(obj) => handleViewEmployeeModal(obj)}
            //
            enableRequest
            onRequest={(obj) => handleNewCertificationModal(obj)}
            //
            enableShowHistory
            onShowHistory={(obj) => handleViewHistoryModal(obj)}
            //
            enableEdit
            onEdit={(obj) => handleEditEmployeeModal(obj)}
            //
            enableDelete
            onDelete={(obj) => handleDeleteEmployeeModal(obj)}
          />
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default BusinessEmployeesScreen
