// dependencies.
import { useEffect, useState } from 'react'
import { collection, orderBy, query, where } from 'firebase/firestore'
import moment from 'moment'
// utils.
import certificationStatusFormat from '../../certificationStatusFormat'
import dateFormat from '../../dateFormat'
import getDataKeys from '../../getDataKeys'
import useFetch from '../useFetch'
import { db } from '../../firebase/firebase'
import { getNameFull } from '../../usernameUtils'
import { useAuth } from '../../contexts/AuthContext'
// constants.
import { DATE_TIME_FORMAT } from '../../../constants'
import certificationLeaveFormat from '../../certificationLeaveFormat'

/**************************************/
/*                                    */
/*    Business Certifications Data    */
/*                                    */
/**************************************/

const useBusinessCertificationsData = (
  business,
  patients,
  pathologies,
  reload,
  dates,
  access = ['superadmin', 'company', 'developer']
) => {
  // contexts.
  const { user, userData } = useAuth()
  // states.
  const [businessPatientsRefs, setBusinessPatientsRefs] = useState([])
  const [certificationsTableItems, setCertificationsTableItems] = useState([])
  const [activeCertificationsTableItems, setActiveCertificationsTableItems] = useState([])

  // ------------------------------------------------------------------------------------------- //
  // FETCH DATABASE DATA                                                                         //
  // ------------------------------------------------------------------------------------------- //

  // database query.
  const q =
    business &&
    business.length !== 0 &&
    query(
      collection(db, 'certifications'),
      where('businessRef', '==', business[0].ref),
      orderBy('createdDate', 'desc')
    )

  // fetch all certifications with patients from firebase's collection.
  const { data, empty } = useFetch(
    {
      ...(businessPatientsRefs && businessPatientsRefs.length !== 0 && { q }),
      condition: !businessPatientsRefs,
      access,
      user,
      userData,
      name: 'BusinessCertifications',
    },
    [businessPatientsRefs, reload]
  )

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // filter certifications by date

  const filteredCertifications = (certificationsSet = data, dates) => {
    if (!dates?.fromDate || !dates?.toDate) return data

    let newSet = []
    if (!!dates.fromDate && !!dates.toDate)
      newSet = certificationsSet.filter((certificationObj) =>
        moment(certificationObj.createdDate.seconds * 1000).isBetween(
          moment(dates?.fromDate),
          moment(dates?.toDate)
        )
      )
    return newSet
  }

  // filter pending and active certifications for Business Home page.
  const certificationsTable = (
    certificationsSet = data,
    patientsSet = patients,
    excludeDone = false,
    patientRefs = businessPatientsRefs
  ) => {
    // filter only 'pending' and 'active' certifications.
    const newSet = certificationsSet.filter((certificationObj) => {
      if (excludeDone) {
        if (
          certificationsSet.length === 0 ||
          certificationObj.status >= 3 ||
          typeof certificationObj.status !== 'number'
        ) {
          return null
        }
      } else {
        if (certificationsSet.length === 0 || typeof certificationObj.status !== 'number') {
          return null
        }
      }

      return certificationObj
    })

    return (
      newSet &&
      newSet.map((obj) => {
        const { createdDate, status, medicAct } = obj
        const patient = patientsSet.filter((p) => p.id === obj.patientRef.id)[0]
        const pathology =
          medicAct && medicAct.pathologyId
            ? pathologies.filter((p) => p.code === medicAct.pathologyId)
            : null
        const checkRef = patientRefs.map((ref) => ref.id).includes(obj.patientRef.id)

        if (!patient || !checkRef) return {}

        // table items.
        return {
          items: [
            {
              copy: createdDate ? dateFormat(createdDate, DATE_TIME_FORMAT) : '',
              grow: 1,
              label: 'Solicitada:',
              icon: 'calendar',
            },
            {
              copy: certificationStatusFormat(status, 'company'),
              type: 'pill',
              pillBackground: status === 1 ? 0 : status,
              icon: 'bookmark',
            },
            {
              copy: patient.id,
              grow: 1,
              icon: 'user',
            },
            {
              copy: getNameFull(patient?.firstname, patient?.lastname, patient?.middlename),
              grow: 2,
              icon: 'user',
            },
            {
              copy: medicAct && medicAct.startDate ? dateFormat(medicAct.startDate) : '--',
              label: 'Comienza:',
              icon: 'calendar',
            },
            {
              copy: medicAct && medicAct.endDate ? dateFormat(medicAct.endDate) : '--',
              label: 'Termina:',
              icon: 'calendar',
            },
            {
              copy: pathology ? pathology[0].publicName : '',
              label: 'Patología:',
              type: 'caption',
              grow: 1,
              icon: 'pathology',
            },
            {
              copy: medicAct && certificationLeaveFormat(medicAct.homeLeave),
              label: 'Salida:',
              type: 'pill',
              pillBackground: 5,
              icon: 'home',
            },
            {
              copy: medicAct && medicAct.issuedDate ? dateFormat(medicAct.issuedDate) : '--',
              label: 'Realizado:',
              icon: 'calendar',
            },
          ],
          raw: { ...obj, ...patient },
        }
      })
    )
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // build businessPatientsRefs object.
  useEffect(() => {
    setBusinessPatientsRefs(getDataKeys(patients))
  }, [patients])

  // build active certification table items object.
  useEffect(() => {
    if (!patients || !data || !pathologies || businessPatientsRefs?.length === 0) return
    setCertificationsTableItems(
      certificationsTable(
        filteredCertifications(data, dates),
        patients,
        false,
        businessPatientsRefs
      )
    )
    setActiveCertificationsTableItems(
      certificationsTable(data, patients, true, businessPatientsRefs)
    )
  }, [patients, data, dates, pathologies, businessPatientsRefs])

  // return values.
  return {
    certifications: data,
    certificationsTable,
    certificationsTableItems,
    activeCertificationsTableItems,
    empty,
  }
}

export default useBusinessCertificationsData
