// dependencies.
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
// components.
import AuthTemplate from '../../templates/AuthScreen'
import { Label } from '../../components/Typography'
// utils.
import notifyToast from '../../js/notifyToast'
import { useAuth } from '../../js/contexts/AuthContext'
// constants.
import { SIGNUP_FORM } from './constants'
import { SIGN_UP_ENABLED } from '../../constants'

/************************/
/*                      */
/*    Sign Up Screen    */
/*                      */
/************************/

// partials.
const StyledButtons = styled.div({
  alignItems: 'center',
  display: 'flex',
  flxWrap: 'wrap',
  gap: '32px',
  justifyContent: 'center',
  margin: '32px auto',
  maxWidth: '360px',
})

// main components.
const SignupScreen = () => {
  // contexts.
  const { signUp } = useAuth()
  // hooks.
  const navigate = useNavigate()
  // states.
  const [values, setValues] = useState({})

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle update value change.
  const handleValueChange = (value) => {
    setValues(value)
  }

  // navigate to sign-in page.
  const handleSigninClick = () => {
    navigate('/signin')
  }

  // handle sign un with email, password, and user data.
  const handleRegisterClick = () => {
    const { firstname, lastname, email, phone, password } = values

    if (!firstname) return notifyToast('Por favor ingrese su Nombre', 'error')
    if (!lastname) return notifyToast('Por favor ingrese su Apellido', 'error')
    if (!email) return notifyToast('Por favor ingrese un Email', 'error')
    if (!phone) return notifyToast('Por favor ingrese su Teléfono', 'error')
    if (!password) return notifyToast('Por favor ingrese su Contraseña', 'error')

    signUp(values)
  }

  // ------------------------------------------------------------------------------------------- //
  // USER SESSION                                                                                //
  // ------------------------------------------------------------------------------------------- //

  useEffect(() => {
    // handle not signed up users.
    if (!SIGN_UP_ENABLED) navigate('/signin')
  }, [])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <AuthTemplate
      description="Crear nueva cuenta."
      form={SIGNUP_FORM}
      submitLabel="Crear cuenta"
      topbarIcon="caret-left"
      topbarTitle="Reestablecer"
      onChange={(value) => handleValueChange(value)}
      onSubmit={() => handleRegisterClick()}
      onTopbarAction={() => handleSigninClick()}
    >
      <StyledButtons>
        <Label color="grey" onClick={() => handleSigninClick()}>
          Iniciar sesión?
        </Label>
      </StyledButtons>
    </AuthTemplate>
  )
}

// TODO: create an auth screens tempate.

export default SignupScreen
