// dependencies.
import { useEffect, useState } from 'react'
import { collection, orderBy, query } from 'firebase/firestore'
import moment from 'moment'
// utils.
import certificationStatusFormat from '../../certificationStatusFormat'
import dateFormat from '../../dateFormat'
import getDataKeys from '../../getDataKeys'
import useFetch from '../useFetch'
import { db } from '../../firebase/firebase'
import { getNameFull } from '../../usernameUtils'
import { useAuth } from '../../contexts/AuthContext'
// constants.
import { DATE_FORMAT, DATE_TIME_FORMAT, US_DATE_FORMAT } from '../../../constants'

/*****************************/
/*                           */
/*    Certifications Data    */
/*                           */
/*****************************/

const useCertificationsData = (
  patients,
  doctors,
  reload,
  business,
  doctor,
  dates,
  access = ['superadmin', 'doctor', 'developer', 'doctor-admin']
) => {
  // contexts.
  const { user, userData } = useAuth()
  // hooks.
  const [patientRefs, setPatientRefs] = useState([])
  const [certificationsTableItems, setCertificationsTableItems] = useState([])
  const [certificationsByStatus, setCertificationsByStatus] = useState({})
  const [certificationsActiveTableItems, setCertificationsActiveTableItems] = useState([])
  const [filteredCertificationsByDate, setFilteredCertificationsByDate] = useState([])

  // ------------------------------------------------------------------------------------------- //
  // FETCH DATABASE DATA                                                                         //
  // ------------------------------------------------------------------------------------------- //

  // database query.
  const q = query(collection(db, 'certifications'), orderBy('createdDate', 'desc'))

  // fetch all certifications with patients from firebase's collection.
  const { data, empty } = useFetch(
    {
      ...(patientRefs && patientRefs.length !== 0 && { q }),
      condition: !patientRefs,
      access,
      user,
      userData,
      name: 'Certifications',
    },
    [patientRefs, reload]
  )

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // filter certifications with status 0.
  const certificationsStatus = (status = 0, certificationsSet = data) => {
    return certificationsSet.filter((certificationObj) => {
      if (
        certificationsSet.length === 0 ||
        certificationObj.status !== status ||
        typeof certificationObj.status !== 'number'
      ) {
        return null
      }
      return certificationObj
    })
  }

  // add patientData to certifications.
  const processCertificationData = (certificationsSet = data) => {
    certificationsSet
      .map((certificationObj) => {
        if (!certificationObj.patientRef.id) return null

        const patient = patients.filter((p) => p.id === certificationObj.patientRef.id)[0]
        const { firstname, middlename, lastname, address, id } = patient
        const patientData = { firstname, middlename, lastname, address, id }
        const doctorData =
          doctors && certificationObj.doctorRef
            ? doctors.find((d) => d.uid === certificationObj.doctorRef.id)
            : null

        certificationObj.patientData = patientData
        certificationObj.doctorData = {
          firstname: doctorData?.firstname || '',
          lastname: doctorData?.lastname || '',
        }

        return certificationObj
      })
      .filter((el) => el !== null)
  }

  const filteredCertifications = (certificationsSet = data, dates, businessId, doctorId) => {
    if ((!dates?.fromDate || !dates?.toDate) && !businessId && !doctorId)
      return {
        all: certificationsSet,
        pending: certificationsStatus(0, certificationsSet),
        assigned: certificationsStatus(1, certificationsSet),
        done: [
          ...certificationsStatus(2, certificationsSet),
          ...certificationsStatus(3, certificationsSet),
        ],
        cancelled: certificationsStatus(4, certificationsSet),
      }

    let newSet = []

    if (businessId) {
      const newSetByBusiness = certificationsSet.filter(
        (certificationObj) => certificationObj.businessRef.id === businessId
      )
      newSet = newSetByBusiness

      if (!!dates.fromDate && !!dates.toDate)
        newSet = newSetByBusiness.filter((certificationObj) =>
          moment(certificationObj.createdDate.seconds * 1000).isBetween(
            moment(dates?.fromDate),
            moment(dates?.toDate)
          )
        )
    }

    if (doctorId) {
      const newSetByDoctor = certificationsSet.filter((certificationObj) => {
        if (certificationObj.status === 0) return null
        return certificationObj?.doctorRef?.id === doctorId
      })
      newSet = newSetByDoctor

      if (!!dates.fromDate && !!dates.toDate)
        newSet = newSetByDoctor.filter((certificationObj) => {
          const dateToCheck = certificationObj.medicAct?.issuedDate
            ? certificationObj.medicAct.issuedDate.seconds * 1000
            : certificationObj.createdDate.seconds * 1000

          return moment(dateToCheck).isBetween(moment(dates.fromDate), moment(dates.toDate))
        })
    }
    if (!!dates.fromDate && !!dates.toDate && !businessId && !doctorId)
      newSet = certificationsSet.filter((certificationObj) =>
        moment(certificationObj.createdDate.seconds * 1000).isBetween(
          moment(dates?.fromDate),
          moment(dates?.toDate)
        )
      )

    return {
      all: newSet,
      pending: certificationsStatus(0, newSet),
      assigned: certificationsStatus(1, newSet),
      done: [...certificationsStatus(2, newSet), ...certificationsStatus(3, newSet)],
      cancelled: certificationsStatus(4, newSet),
    }
  }

  // filter certifications for Admin Certifications page.
  const certificationsTable = (doctorsSet = doctors, certificationsSet = data) => {
    return certificationsSet.map((certificationObj) => {
      const { createdDate, status, medicAct, doctorRef, patientData, businessName } =
        certificationObj
      const doctorData = doctorRef ? doctorsSet.filter((d) => d.uid === doctorRef.id) : null

      return {
        items: [
          {
            copy: createdDate ? dateFormat(createdDate, DATE_TIME_FORMAT) : '',
            grow: 1,
            label: 'Solicitada:',
            icon: 'calendar',
          },
          {
            copy: certificationStatusFormat(status, userData?.access),
            icon: 'bookmark',
            type: 'pill',
            pillBackground: status,
          },
          {
            copy: patientData.id,
            grow: 1,
            label: 'Documento:',
            icon: 'user',
          },
          {
            copy: getNameFull(patientData?.firstname, patientData?.lastname),
            grow: 2,
            label: 'Nombre:',
            icon: 'user',
          },
          {
            copy: businessName,
            grow: 1,
            label: 'Empresa:',
            icon: 'business',
            type: 'caption',
          },
          {
            copy:
              doctorData && doctorData.length > 0
                ? getNameFull(doctorData[0]?.firstname, doctorData[0]?.lastname)
                : '',
            grow: 1,
            label: 'Doctor:',
            icon: 'favorite',
          },
          {
            copy:
              medicAct && medicAct.issuedDate ? dateFormat(medicAct.issuedDate, DATE_FORMAT) : '',
            label: 'Realizado:',
            icon: 'calendar',
          },
        ],
        disableShowMedicAct: !certificationObj.medicAct,
        disableEdit: (status >= 1 && certificationObj.medicAct) || status >= 3,
        disableCancel: status >= 3,
        raw: certificationObj,
      }
    })
  }

  // filter ended and canceled certifications for Business Home page.
  const certificationsActiveTable = (certificationsSet = data, doctorsSet = doctors) => {
    if (!certificationsSet) return null

    // filter only 'pending' and 'active' certifications.
    const newSet = certificationsSet.filter((certificationObj) => {
      if (
        certificationsSet.length === 0 ||
        typeof certificationObj.status !== 'number' ||
        certificationObj.status > 1
      ) {
        return null
      }
      return certificationObj
    })

    return (
      newSet &&
      newSet.map((obj) => {
        const { createdDate, status, medicAct, patientData, businessName, doctorRef } = obj

        if (medicAct && medicAct.startDate)
          obj.medicAct.startDate = dateFormat(medicAct.startDate, US_DATE_FORMAT)
        if (medicAct && medicAct.endDate)
          obj.medicAct.endDate = dateFormat(medicAct.endDate, US_DATE_FORMAT)
        if (medicAct && medicAct.issuedDate)
          obj.medicAct.issuedDate = dateFormat(medicAct.issuedDate, US_DATE_FORMAT)

          const doctorData = doctorRef ? doctorsSet.filter((d) => d.uid === doctorRef.id) : null

        return {
          items: [
            {
              copy: createdDate ? dateFormat(createdDate, DATE_TIME_FORMAT) : '',
              grow: 1,
              label: 'Solicitada:',
              icon: 'calendar',
            },
            {
              copy: certificationStatusFormat(status, userData?.access),
              label: 'Estado:',
              icon: 'bookmark',
              type: 'pill',
              pillBackground: status,
            },
            {
              copy: patientData.id,
              grow: 1,
              label: 'Documento:',
              icon: 'user',
            },
            {
              copy: `${patientData?.firstname} ${patientData?.lastname}`,
              grow: 2,
              label: 'Nombre:',
              icon: 'user',
            },
            {
              copy: businessName,
              grow: 2,
              label: 'Empresa:',
              icon: 'info',
              type: 'caption',
            },
            {
              copy:
                doctorData && doctorData.length > 0
                  ? getNameFull(doctorData[0]?.firstname, doctorData[0]?.lastname)
                  : '',
              grow: 1,
              label: 'Doctor:',
              icon: 'favorite',
            },
          ],
          raw: obj,
        }
      })
    )
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // build patientRefs object.
  useEffect(() => {
    setPatientRefs(getDataKeys(patients))
  }, [patients])

  // build certification by status items object.
  useEffect(() => {
    if (!data) return
    setCertificationsByStatus({
      pending: certificationsStatus(0),
      assigned: certificationsStatus(1),
      done: [...certificationsStatus(2), certificationsStatus(3)],
      cancelled: certificationsStatus(4),
    })
  }, [data])

  // build certifications object.
  useEffect(() => {
    if (!patients || !data) return
    processCertificationData()
  }, [data, patients, doctor])

  // build certification table items object.
  useEffect(() => {
    if (!doctors || !data) return
    setCertificationsTableItems(certificationsTable(doctors, filteredCertificationsByDate.all))
  }, [doctors, data, filteredCertificationsByDate])

  // build active certification table items object.
  useEffect(() => {
    if (!patients || !doctors || !data) return
    setCertificationsActiveTableItems(certificationsActiveTable())
  }, [patients, doctors, data])

  // re-render once there are filtered dates

  useEffect(() => {
    setFilteredCertificationsByDate(filteredCertifications(data, dates, business, doctor))
  }, [data, dates, business, doctor])

  // return values.
  return {
    certifications: data,
    certificationsCount: {
      all: data.length,
      pending: certificationsByStatus?.pending?.length,
      assigned: certificationsByStatus?.assigned?.length,
      done: certificationsByStatus?.done?.length,
      cancelled: certificationsByStatus?.cancelled?.length,
    },
    certificationsActiveTable,
    certificationsTable,
    certificationsTableItems,
    certificationsActiveTableItems,
    filteredCertificationsByDate,
    filteredCertificationsByDateCount: {
      all: filteredCertificationsByDate?.all?.length,
      pending: filteredCertificationsByDate?.pending?.length,
      assigned: filteredCertificationsByDate?.assigned?.length,
      done: filteredCertificationsByDate?.done?.length,
      cancelled: filteredCertificationsByDate?.cancelled?.length,
    },
    empty,
  }
}

export default useCertificationsData
