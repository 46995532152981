// dependencies.
import styled from 'styled-components'
// components.
import Navigation from './Navigation'
import BottomBar from './BottomBar'
// utils.
import styleguideHasKey from '../../js/styleguideHasKey'
import { theme } from '../../styleguide'
import { useDimensions } from '../../js/hooks'

/***********************************/
/*                                 */
/*    Content Wrapper Component    */
/*                                 */
/***********************************/

// helpers.
const topbar = {
  m: 48,
  d: 72,
}

const nav = {
  m: 56,
  d: 184,
}

// partials.
const Wrapper = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    margin: 0,
    minHeight: (props) =>
      props.isMobile ? `${props.height - topbar.m}px` : `${props.height - topbar.d}px`,
    marginLeft: (props) => (props.isMobile ? 0 : props.condensed ? `${nav.m}px` : `${nav.d}px`),
    position: 'relative',
    top: (props) => (props.isMobile ? `${topbar.m}px` : `${topbar.d}px`),
    width: (props) =>
      props.isMobile
        ? '100%'
        : props.condensed
        ? `calc(100% - ${nav.m}px)`
        : `calc(100% - ${nav.d}px)`,
    transition: 'all 200ms ease',
    ...(props) => props.isMobile && props.showNavigation && { overflow: 'hidden' },
  },
  `@media print {
    margin-left: 0;
    top: 0;
    width: 100%;
  }`
)

const BoxWrapper = styled.div(
  {
    alignItems: 'center',
    background: (props) => props.background,
    borderRadius: (props) => (props.isMobile ? '16px 16px 0 0' : '16px 0 0 0'),
    boxShadow: theme.shadows.normal,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 0,
    minWidth: (props) => (props.isMobile ? '100vw' : 'unset'),
    overflow: 'hidden',
    padding: (props) => (props.isMobile ? `24px 16px ${nav.m + 8}px` : '24px 16px 48px'),
    width: '100%',
  },
  `@media print {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }`
)

const Content = styled.div({
  flex: 1,
  flexDirection: 'column',
  width: '100%',
})

// main component.
const ContentWrapper = ({
  className,
  background,
  navigation,
  showNavigation,
  condensed,
  title,
  children,
  onCondenseClick,
}) => {
  // hooks.
  const { height, isMobile } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // make sure color is included in the library.
  const { colors } = theme
  const backgroundColor = styleguideHasKey(background, colors, 'smoke')

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <>
      {navigation.length && isMobile ? (
        <BottomBar items={navigation} />
      ) : (
        <Navigation
          title={title}
          items={navigation}
          condensed={condensed}
          expanded={showNavigation}
          onClick={isMobile ? () => {} : onCondenseClick}
        />
      )}
      <Wrapper
        isMobile={isMobile}
        condensed={condensed}
        height={height}
        showNavigation={showNavigation}
      >
        <BoxWrapper className={className} isMobile={isMobile} background={colors[backgroundColor]}>
          <Content>{children}</Content>
        </BoxWrapper>
      </Wrapper>
    </>
  )
}

export default ContentWrapper
