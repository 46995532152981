// dependencies.
import { collection, orderBy, query } from 'firebase/firestore'
// utils.
import useFetch from '../useFetch'
import { db } from '../../firebase/firebase'
import { useAuth } from '../../contexts/AuthContext'

/**************************/
/*                        */
/*    Pathologies Data    */
/*                        */
/**************************/

const usePathologiesData = (reload, access = ['superadmin', 'doctor', 'company', 'developer']) => {
  // contexts.
  const { user, userData } = useAuth()

  // ------------------------------------------------------------------------------------------- //
  // FETCH DATABASE DATA                                                                         //
  // ------------------------------------------------------------------------------------------- //

  // database query.
  const q = query(collection(db, 'pathologies'), orderBy('code', 'asc'))

  // fetch all patholodies from firebase's collection.
  const { data, empty } = useFetch({ q, access, user, userData, name: 'Pathologies' }, [reload])

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // process pathology for table list.
  const pathologiesSelect = (pathologiesSet = data) => {
    // set in the select only the active pathologies
    return pathologiesSet.reduce(function (filtered, pathologieObj) {
      if (pathologieObj.isActive) {
        const pathologie = {
          label: pathologieObj.confidentialName,
          name: pathologieObj.code,
        }
        filtered.push(pathologie)
      }
      return filtered
    }, [])
  }

  // filter pathologies for Admin Pathologies page.
  const pathologiesTable = (pathologiesSet = data) => {
    return pathologiesSet.map((pathologyObj) => {
      const { publicName, confidentialName, code, isClinical, isActive } = pathologyObj

      // table items.
      return {
        items: [
          { copy: code, label: 'Número:', icon: 'bookmark' },
          { copy: publicName, grow: 2, label: 'Nombre Público:', icon: 'title' },
          { copy: confidentialName, grow: 2, label: 'Nombre Confidencial:', icon: 'view' },
          {
            copy: isClinical ? 'Si' : 'No',
            label: 'Es Clínica:',
            icon: 'favorite',
            type: 'pill',
            pillBackground: 5,
          },
          {
            copy: isActive ? 'Si' : 'No',
            label: 'Activa:',
            icon: 'title',
            type: 'pill',
            pillBackground: isActive ? 1 : 0,
          },
        ],
        disableEdit: false,
        raw: pathologyObj,
      }
    })
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return values.
  return {
    pathologies: data,
    pathologiesCount: data.length,
    pathologiesSelect,
    pathologiesTable,
    empty,
  }
}

export default usePathologiesData
