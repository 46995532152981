// dependencies.
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { doc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import { Form } from '../../components/Form'
import { Loader } from '../../components/Icon'
import { Map } from '../../components/Map'
// utils.
import notifyToast from '../../js/notifyToast'
import { db, getDoctorById } from '../../js/firebase/firebase'
import { parseAddressToLatLng } from '../../js/parseAddress'
import { useAuth } from '../../js/contexts/AuthContext'
import { useDoctorsData, useCertificationsData, usePatientsData } from '../../js/hooks/data'
// constants.
import { ASSIGN_MULTI_CERTIFICATION_FORM } from './constants'

/*****************************/
/*                           */
/*    Admin Zoning Screen    */
/*                           */
/*****************************/

// partials.
const MapWrapper = styled.div({
  margin: '-24px -16px 0',
  width: 'calc(100% + 32px)',
})

// main component.
const DoctorAdminZoningScreen = ({ navMenu, navTitle }) => {
  // contexts.
  const { isLoaded } = useAuth()
  const [reload, setReload] = useState(false)

  // hooks.
  const { doctors, doctorsSelect } = useDoctorsData()
  const { patients } = usePatientsData()
  const { certifications } = useCertificationsData(patients, doctors, reload)
  // data object
  const doctorsSelectOptions = doctorsSelect()
  // states.
  const [pointsToCheck, setPointsToCheck] = useState([])
  const [pointsInside, setPointsInside] = useState([])

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle assign / edit certification.
  const handleUpdateCertificationClick = async (obj) => {
    if (obj.doctorId) {
      const { doctorId } = obj
      const doctorDoc = await getDoctorById(doctorId)

      const updateFields = {
        doctorRef: doctorDoc.ref,
        status: 1, // assigned
        updatedDate: new Date(),
      }
      const certificationsToAssign = certifications.filter(
        (c) => (!c.doctorRef || c.doctorRef === null) && c.status === 0
      )

      // Assign all pending certifications to the selected doctor.
      certificationsToAssign.map(async (cert) => {
        const certificationRef = doc(db, 'certifications', cert.ref.id)
        await setDoc(certificationRef, updateFields, { merge: true })
          .then(() => notifyToast('Certificación asignada correctamente', 'success'))
          .catch(() => notifyToast('Ha ocurrido un error', 'error'))
      })
      setReload(!reload)
    }
  }

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // filter only 'pending' certifications.
  useEffect(() => {
    setPointsToCheck([]);
    setPointsInside([]);

    if (!certifications || certifications.length === 0) return

    const certificationsToAssign = certifications.filter(
      (c) => (!c.doctorRef || c.doctorRef === null) && c.status === 0
    )

    certificationsToAssign.map(async (certification) => {
      let newPoint

      if (!certification.address.lat || !certification.address.lng) {
        // TODO: fotmat address function:
        const address = `${certification.address.street} ${certification.address.number}, ${certification.address.district}, ${certification.address.city}, Uruguay`
        newPoint = await parseAddressToLatLng(address)
      } else {
        newPoint = {
          lat: certification.address.lat,
          lng: certification.address.lng,
        }
      }
      newPoint.ref = certification.ref

      setPointsToCheck((pointsToCheck) => [...pointsToCheck, newPoint])

      return pointsToCheck
    })
  }, [certifications])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <AdminTemplate navigation={navMenu} sectionTitle={navTitle}>
      {isLoaded ? (
        <>
          <MapWrapper>
            <Map
              pointsToCheck={pointsToCheck}
              pointsInside={pointsInside}
              disableDrawingTools
            />
          </MapWrapper>
          <Form
            items={ASSIGN_MULTI_CERTIFICATION_FORM(doctorsSelectOptions)}
            onClick={(obj) => handleUpdateCertificationClick(obj)}
          />
        </>
      ) : (
        <Loader />
      )}
    </AdminTemplate>
  )
}

export default DoctorAdminZoningScreen
