export const NEW_BUSINESS_FORM = [
  { type: 'headline', label: 'Datos de la empresa:' },
  { name: 'name', placeholder: 'Nombre Fiscal' },
  { name: 'rut', placeholder: 'RUT' },
  { type: 'headline', label: 'Usuario:' },
  { name: 'email', placeholder: 'Email' },
  { type: 'headline', label: 'Dirección y contacto:' },
  {
    type: 'array',
    name: 'address',
    items: [
      { name: 'branch', placeholder: 'Nombre / Alias de la sucursal' },
      { name: 'phone', placeholder: 'Teléfono de la sucursal' },
      [
        { name: 'street', placeholder: 'Calle' },
        { name: 'number', placeholder: 'Número' },
      ],
      [
        { name: 'suite', placeholder: 'Apartamento / Oficina' },
        { name: 'cornerstreet', placeholder: 'Esquina' },
      ],
      [
        { name: 'city', placeholder: 'Ciudad' },
        { name: 'state', placeholder: 'Departamento' },
      ],
      { name: 'district', placeholder: 'Barrio / Localidad', required: true },
    ],
  },
  { type: 'headline', label: 'Comentarios:' },
  { name: 'comments', placeholder: 'Notas o comentarios' },
]

export const UPDATE_BUSINESS_FORM = [
  { type: 'headline', label: 'Datos de la empresa:' },
  { name: 'name', placeholder: 'Nombre Fiscal', required: true },
  { name: 'rut', placeholder: 'RUT', required: true },
  { type: 'headline', label: 'Usuario:' },
  { name: 'email', placeholder: 'Email', disabled: true },
  { type: 'headline', label: 'Dirección y contacto:' },
  {
    type: 'array',
    name: 'address',
    items: [
      { name: 'branch', placeholder: 'Nombre / Alias de la sucursal' },
      { name: 'phone', placeholder: 'Teléfono de la sucursal', required: true },
      [
        { name: 'street', placeholder: 'Calle', required: true },
        { name: 'number', placeholder: 'Número' },
      ],
      [
        { name: 'suite', placeholder: 'Apartamento / Oficina' },
        { name: 'cornerstreet', placeholder: 'Esquina' },
      ],
      [
        { name: 'city', placeholder: 'Ciudad', required: true },
        { name: 'state', placeholder: 'Departamento', required: true },
      ],
      { name: 'district', placeholder: 'Barrio / Localidad', required: true },
    ],
  },
  { type: 'headline', label: 'Comentarios:' },
  { name: 'comments', placeholder: 'Notas o comentarios' },
]

export const NEW_PATIENT_FORM = [
  { type: 'headline', label: 'Datos del empleado:' },
  { name: 'id', placeholder: 'Cédula de identidad', required: true },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'middlename', placeholder: 'Segundo nombre' },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { name: 'employeenumber', placeholder: 'Número de funcionario' },
  { type: 'headline', label: 'Dirección y contacto:' },
  {
    type: 'array',
    name: 'address',
    items: [
      { name: 'email', placeholder: 'Email' },
      { name: 'phone', placeholder: 'Teléfono', required: true },
      [
        { name: 'street', placeholder: 'Calle', required: true },
        { name: 'number', placeholder: 'Número' },
      ],
      [
        { name: 'suite', placeholder: 'Apartamento / Oficina' },
        { name: 'cornerstreet', placeholder: 'Esquina' },
      ],
      [
        { name: 'city', placeholder: 'Ciudad', required: true },
        { name: 'state', placeholder: 'Departamento', required: true },
      ],
      { name: 'district', placeholder: 'Barrio / Localidad', required: true },
      { name: 'indications', placeholder: 'Indicaciones' },
    ],
  },

]

export const UPDATE_PATIENT_FORM = [
  { type: 'headline', label: 'Datos del empleado:' },
  { name: 'id', placeholder: 'Cédula de identidad', disabled: true },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'middlename', placeholder: 'Segundo nombre' },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { name: 'employeenumber', placeholder: 'Número de funcionario' },
  { type: 'headline', label: 'Dirección y contacto:' },
  {
    type: 'array',
    name: 'address',
    items: [
      { name: 'email', placeholder: 'Email' },
      { name: 'phone', placeholder: 'Teléfono', required: true },
      [
        { name: 'street', placeholder: 'Calle', required: true },
        { name: 'number', placeholder: 'Número' },
      ],
      [
        { name: 'suite', placeholder: 'Apartamento / Oficina' },
        { name: 'cornerstreet', placeholder: 'Esquina' },
      ],
      [
        { name: 'city', placeholder: 'Ciudad', required: true },
        { name: 'state', placeholder: 'Departamento', required: true },
      ],
      { name: 'district', placeholder: 'Barrio / Localidad', required: true },
      { name: 'indications', placeholder: 'Indicaciones' },
    ],
  },
]

export const DELETE_PATIENT_FORM = [
  { type: 'headline', label: 'Estás seguro que deseas eliminar el empleado?' },
]

export const NEW_CERTIFICATION_FORM = (employees) => [
  {
    type: 'headline',
    label: 'Las certificaciones solicitadas pasadas las 14hs se gestionarán al próximo día.',
  },
  { type: 'headline', label: 'Empleado:' },
  { name: 'fullname', placeholder: 'Empleado', options: employees, required: true },
  { name: 'document', placeholder: 'Cédula de identidad', disabled: true },
  { type: 'headline', label: 'Dirección:' },
  {
    type: 'array',
    name: 'address',
    items: [
      { type: 'checkbox', name: 'isHospital', placeholder: '¿Es en hospital?' },
      [
        { name: 'street', placeholder: 'Calle', required: true },
        { name: 'number', placeholder: 'Número' },
      ],
      [
        { name: 'suite', placeholder: 'Apartamento / Oficina' },
        { name: 'cornerstreet', placeholder: 'Esquina' },
      ],
      [
        { name: 'city', placeholder: 'Ciudad', required: true },
        { name: 'state', placeholder: 'Departamento', required: true },
      ],
      { name: 'district', placeholder: 'Barrio / Localidad', required: true },
      { name: 'phone', placeholder: 'Número de contacto', required: true },
      { name: 'indications', placeholder: 'Indicaciones' },
    ],
  },
  { type: 'headline', label: 'Comentarios:' },
  { name: 'comments', placeholder: 'Notas o comentarios' },
]

export const NEW_CERTIFICATION_FORM_EMPLOYEE = [
  {
    type: 'headline',
    label: 'Las certificaciones solicitadas pasadas las 14hs se gestionarán al próximo día.',
  },
  { type: 'headline', label: 'Empleado:' },
  { name: 'fullname', placeholder: 'Empleado', disabled: true },
  { name: 'document', placeholder: 'Cédula de identidad', disabled: true },
  { type: 'headline', label: 'Dirección:' },
  {
    type: 'array',
    name: 'address',

    items: [
      { type: 'checkbox', name: 'isHospital', placeholder: '¿Es en hospital?' },
      [
        { name: 'street', placeholder: 'Calle', required: true },
        { name: 'number', placeholder: 'Número' },
      ],
      [
        { name: 'suite', placeholder: 'Apartamento / Oficina' },
        { name: 'cornerstreet', placeholder: 'Esquina' },
      ],
      [
        { name: 'city', placeholder: 'Ciudad', required: true },
        { name: 'state', placeholder: 'Departamento', required: true },
      ],
      { name: 'district', placeholder: 'Barrio / Localidad', required: true },
      { name: 'phone', placeholder: 'Número de contacto', required: true },
      { name: 'indications', placeholder: 'Indicaciones' },
    ],
  },
  { type: 'headline', label: 'Comentarios:' },
  { name: 'comments', placeholder: 'Notas o comentarios' },
]
