// dependencies.
import styled from 'styled-components'
// components.
import NavigationButton from '../components/Button/NavigationButton'
import { Button } from '../components/Button'
import { FlatCard } from '../components/Card'
// utils.
import { getNameFull } from '../js/usernameUtils'
import { toAddressString } from '../js/parseAddress'
import { useDimensions } from '../js/hooks'

/*************************/
/*                       */
/*    Show Data Modal    */
/*                       */
/*************************/

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
  gap: '16px',
  justifyContent: 'space-between',
  margin: '0 auto',
  maxWidth: (props) => (props.isMobile ? '416px' : '100%'),
  padding: (props) => (props.isMobile ? '32px 0' : '16px'),
  width: '100%',
})

const Column = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  margin: '0 auto',
  maxWidth: '100%',
  width: '100%',
})

const StyledFlatCard = styled(FlatCard)({
  height: '100%',
})

const NavigationWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

const ButtonWrapper = styled.div({
  margin: '0 auto',
  padding: '16px 0 0',
  width: '100%',
})

// main component.
const CertificationData = ({ certification, onClick }) => {
  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // hooks
  const { isMobile } = useDimensions()

  // extract object keys.
  const { address, patientData, lat, lng, comments } = certification
  const { city, cornerstreet, district, number, state, street, suite, phone, isHospital, indications } = address

  // build data object.
  const patientDataCard = {
    icon: 'user',
    title: 'Datos del paciente',
    items: [
      {
        label: 'Nombre completo:',
        value: getNameFull(patientData.firstname, patientData.lastname, patientData.middlename),
      },
      {
        label: 'Cédula de identidad:',
        value: patientData.id,
      },
      {
        label: 'Teléfono:',
        value: `${phone}`,
      },
    ],
  }

  const commentsCard = {
    icon: 'copy',
    title: 'Comentarios:',
    items: [comments],
  }

  const addressCard = {
    icon: 'bookmark',
    title: `Dirección ${isHospital ? '(Hospital)' : ''}`,
    items: [
      {
        label: 'Calle y número:',
        value: `${street}, ${number}`,
      },
      {
        label: 'Apartamento / Oficina:',
        value: suite,
      },
      {
        label: 'Esquina:',
        value: cornerstreet,
      },
      {
        label: 'Ciudad y departamento:',
        value: city && state ? `${city || ''}, ${state || ''}` : `${city || ''} ${state || ''}`,
      },
      {
        label: 'Barrio / Localidad:',
        value: district,
      },
      {
        label: 'Indicaciones:',
        value: indications,
      },
    ],
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <>
      <Wrapper isMobile={isMobile}>
        <Column>
          <StyledFlatCard {...patientDataCard} />

          <StyledFlatCard {...commentsCard} />
        </Column>

        <Column>
          <StyledFlatCard {...addressCard} />
        </Column>

        <Column>
          <StyledFlatCard icon="location" title="Como llegar?">
            <NavigationWrapper>
              <NavigationButton key="googleMaps" variant="googleMaps" address={{ lat, lng }} />
              <NavigationButton key="waze" variant="waze" address={{ lat, lng }} />
              <NavigationButton key="copy" address={toAddressString(address)} />
            </NavigationWrapper>
          </StyledFlatCard>

          <ButtonWrapper>
            <Button onClick={onClick}>Realizar Acto Médico</Button>
          </ButtonWrapper>
        </Column>
      </Wrapper>
    </>
  )
}

export default CertificationData
