// dependencies.
import { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
// components.
import AuthTemplate from '../../templates/AuthScreen'
import { Label } from '../../components/Typography'
// utils.
import firebaseErrorMessage from '../../js/firebase/firebaseErrorTranslator'
import notifyToast from '../../js/notifyToast'
import { useAuth } from '../../js/contexts/AuthContext'
// constants.
import { RESET_FORM } from './constants'
import { SIGN_UP_ENABLED } from '../../constants'

/*******************************/
/*                             */
/*    Reset Password Screen    */
/*                             */
/*******************************/

// partials.
const StyledButtons = styled.div({
  alignItems: 'center',
  display: 'flex',
  flxWrap: 'wrap',
  gap: '32px',
  justifyContent: 'center',
  margin: '32px auto',
  maxWidth: '360px',
})

// main component.
const ResetScreen = () => {
  // contexts.
  const { sendReset } = useAuth()
  // hooks.
  const navigate = useNavigate()
  // states.
  const [email, setEmail] = useState('')

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle update value change.
  const handleValueChange = (values) => {
    setEmail(values.email)
  }

  // navigate to sign-in page.
  const handleSigninClick = () => {
    navigate('/signin')
  }

  // navigate to sign-up page.
  const handleRegisterClick = () => {
    navigate('/signup')
  }

  // handle send reset password email with link.
  const handleResetPasswordClick = (email) => {
    sendReset(email).then((response) => {
      if (response === 'sent') {
        notifyToast('Email enviado correctamente', 'success')
      } else {
        notifyToast(firebaseErrorMessage(response), 'error')
      }
    })
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <AuthTemplate
      description="Le enviaremos un correo con instrucciones para reestablecer su contraseña."
      form={RESET_FORM}
      submitLabel="Reestablecer"
      topbarIcon="caret-left"
      topbarTitle="Reestablecer"
      onChange={(value) => handleValueChange(value)}
      onSubmit={() => handleResetPasswordClick(email)}
      onTopbarAction={() => handleSigninClick()}
    >
      {SIGN_UP_ENABLED && (
        <StyledButtons>
          <Label color="grey" onClick={() => handleRegisterClick()}>
            No tiene cuenta?
          </Label>
        </StyledButtons>
      )}
    </AuthTemplate>
  )
}

export default ResetScreen
