export const ASSIGN_CERTIFICATION_FORM = (doctors) => [
  { type: 'headline', label: 'Médico:' },
  { name: 'doctorId', placeholder: 'Médico', options: doctors },
  { type: 'headline', label: 'Empresa:' },
  { name: 'businessName', placeholder: 'Empresa', disabled: true },
  { type: 'headline', label: 'Empleado:' },
  {
    type: 'array',
    name: 'patientData',
    items: [
      { name: 'fullname', placeholder: 'Paciente', disabled: true },
      { name: 'id', placeholder: 'Cédula de identidad', disabled: true },
    ],
  },
  { type: 'headline', label: 'Dirección:' },
  {
    type: 'array',
    name: 'address',
    items: [
      { type: 'checkbox', name: 'isHospital', placeholder: '¿Es en hospital?' },
      [
        { name: 'street', placeholder: 'Calle', required: true },
        { name: 'number', placeholder: 'Número' },
      ],
      [
        { name: 'suite', placeholder: 'Apartamento / Oficina' },
        { name: 'cornerstreet', placeholder: 'Esquina' },
      ],
      [
        { name: 'city', placeholder: 'Ciudad', required: true },
        { name: 'state', placeholder: 'Departamento', required: true },
      ],
      { name: 'district', placeholder: 'Barrio / Localidad', required: true },
      { name: 'phone', placeholder: 'Número de contacto', required: true },
      { name: 'indications', placeholder: 'Indicaciones' },
    ],
  },
  { type: 'headline', label: 'Comentarios:' },
  { name: 'comments', placeholder: 'Notas o comentarios' },
]

export const ASSIGN_MULTI_CERTIFICATION_FORM = (doctors, selectedDoctor) => [
  { type: 'headline', label: 'Médico:' },
  { name: 'doctorId', placeholder: selectedDoctor || 'Médico', options: doctors, required: true },
]