// dependencies.
import styled from 'styled-components'
// components.
import Body from './Body'
// utils.
import styleguideHasKey from '../../js/styleguideHasKey'
import { theme } from '../../styleguide'

/*****************************/
/*                           */
/*    Pill Text Component    */
/*                           */
/*****************************/

// partials.
const Wrapper = styled.span(
  {
    background: (props) => props.type,
    borderRadius: '12px',
    display: 'block',
    padding: '3px 12px',
  },
  `@media print { padding: 0 8px; }`
)

// main component.
const Pill = ({ background = 'alert', color = 'white', align = 'center', className, children }) => {
  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  const parseBackgroundProp = () => {
    if (typeof background === 'number') {
      switch (background) {
        case 1:
          return 'success'
        case 2:
          return 'terciary'
        case 3:
          return 'terciary'
        case 4:
          return 'warning'
        case 5:
          return 'ash'
        default:
          return 'alert'
      }
    } else {
      return background
    }
  }

  const parseColorProp = () => {
    if (typeof background === 'number') {
      switch (background) {
        case 1:
          return 'black'
        case 4:
          return 'black'
        case 5:
          return 'black'
        default:
          return 'white'
      }
    } else {
      return color
    }
  }

  // make sure background is included in the library.
  const { colors } = theme
  const backgroundColor = styleguideHasKey(background, colors, parseBackgroundProp())

  // build props object.
  const props = {
    className,
    type: colors[backgroundColor],
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper {...props}>
      <Body type="caption" align={align} color={parseColorProp()}>
        {children}
      </Body>
    </Wrapper>
  )
}

export default Pill
