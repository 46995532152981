// helpers
// CI Validator
// https://github.com/picandocodigo/ci_js/
const validationDigit = (ci) => {
  let a = 0
  let i = 0

  for (i = ci.length; i < 7; i++) {
    ci = '0' + ci
  }

  for (i = 0; i < 7; i++) {
    a += (parseInt('2987634'[i]) * parseInt(ci[i])) % 10
  }

  if (a % 10 === 0) return 0
  else return 10 - (a % 10)
}

const validateCi = (ci) => {
  ci = cleanCi(ci)
  if (Number(ci)) {
    const dig = Number(ci[ci.length - 1])
    ci = ci.replace(/[0-9]$/, '')
    return dig === validationDigit(ci)
  }
  return false
}

export const cleanCi = (ci) => {
  return ci.replace(/\D/g, '')
}

// Phone validator

const validatePhone = (p) => {
  if (p.length !== 9) return false
}

// Mail validator

const validateEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email)
}

// main function

const validateInput = (name, value) => {
  if (name === 'id') {
    return validateCi(value)
  } else if (name === 'phone') return validatePhone(value)
  else if (name === 'email') return validateEmail(value)
}

export default validateInput
