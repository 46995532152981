// dependencies.
import styled from 'styled-components'
// components.
import { Body, Label, Pill } from '../Typography'
import { Icon } from '../Icon'
import { Group, Item } from '../Button'
// utils.
import { theme, constants } from '../../styleguide'

/***************************/
/*                         */
/*    Table Item Module    */
/*                         */
/***************************/

// helpers.
const { TABLE_CELL_MIN_WIDTH, TABLE_CELL_MID_WIDTH, TABLE_CELL_MAX_WIDTH } = constants

// partials.
const ContentItemWrapper = styled.li(
  {
    alignItems: 'stretch',
    backgroundColor: (props) => (props.isEven ? theme.colors.smoke : theme.colors.white),
    borderBottom: (props) => !props.isLast && `1px solid ${theme.colors.ash}`,
    display: 'flex',
    flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
    minHeight: '64px',
    minWidth: '100%',
    padding: (props) => (props.isMobile ? '8px' : 0),
    width: 'fit-content',
  },
  `@media print {
    min-height: 40px;
    min-width: unset;
    padding: 0;
  }`
)

const ContentItem = styled(Body)(
  {
    alignItems: 'center',
    borderRight: (props) => !props.isLast && !props.isMobile && `1px solid ${theme.colors.ash}`,
    display: 'flex',
    flexGrow: (props) => (props.grow ? 'grow' : 0),
    gap: '8px',
    justifyContent: (props) => (props.isMobile ? 'flex-start' : 'center'),
    maxWidth: (props) =>
      props.isMobile
        ? '100%'
        : props.grow
        ? props.grow === 2
          ? '100%'
          : TABLE_CELL_MID_WIDTH
        : TABLE_CELL_MIN_WIDTH,
    minWidth: (props) =>
      props.isMobile
        ? '100%'
        : props.grow
        ? props.grow === 2
          ? TABLE_CELL_MAX_WIDTH
          : TABLE_CELL_MID_WIDTH
        : TABLE_CELL_MIN_WIDTH,
    padding: '2px 4px',
    width: (props) => (props.isMobile || props.grow ? '100%' : 'auto'),
  },
  `@media print {
    max-width: 108px;
    min-width: 108px;
    padding: 2px;

    &#action-cell {
      display: none;
    }
  }`
)

const IconElement = styled.span({
  display: 'block',
  paddingRight: '8px',
})

const ActionsElement = ({
  id,
  isMobile,
  //
  disableRequest,
  onRequest,
  //
  disableMedicAct,
  onMedicAct,
  //
  disableShowHistory,
  onShowHistory,
  //
  disableShowMedicAct,
  onShowMedicAct,
  //
  disableEdit,
  onEdit,
  //
  disableCancel,
  onCancel,
  //
  disableDelete,
  onDelete,
}) => {
  return (
    <ContentItem id={id} grow={2} isLast isMobile={isMobile}>
      {(onShowHistory || onShowMedicAct || onRequest || onMedicAct) && (
        <Group>
          {onRequest && (
            <Item icon="add" label="Certificar" disabled={disableRequest} onClick={onRequest} />
          )}
          {onMedicAct && (
            <Item icon="add" label="Acto médico" disabled={disableMedicAct} onClick={onMedicAct} />
          )}

          {onShowMedicAct && (
            <Item label="Ver acto" disabled={disableShowMedicAct} onClick={onShowMedicAct} />
          )}
          {onShowHistory && (
            <Item label="Ver historial" disabled={disableShowHistory} onClick={onShowHistory} />
          )}
        </Group>
      )}

      {(onEdit || onCancel || onDelete) && (
        <Group>
          {onEdit && (
            <Item
              icon="edit"
              // label="Editar"
              variant="primary"
              disabled={disableEdit}
              onClick={onEdit}
            />
          )}
          {onCancel && (
            <Item
              icon="delete"
              // label="Cancelar"
              variant="warning"
              disabled={disableCancel}
              onClick={onCancel}
            />
          )}
          {onDelete && (
            <Item
              icon="trash"
              // label="Eliminar"
              variant="alert"
              disabled={disableDelete}
              onClick={onDelete}
            />
          )}
        </Group>
      )}
    </ContentItem>
  )
}

// main component.
const TableItem = ({
  items,
  //
  isMobile,
  isEven,
  isLast,
  //
  onDetail,
  //
  disableRequest,
  enableRequest,
  onRequest,
  //
  disableMedicAct,
  enableMedicAct,
  onMedicAct,
  //
  disableShowHistory,
  enableShowHistory,
  onShowHistory,
  //
  disableShowMedicAct,
  enableShowMedicAct,
  onShowMedicAct,
  //
  disableEdit,
  enableEdit,
  onEdit,
  //
  disableCancel,
  enableCancel,
  onCancel,
  //
  disableDelete,
  enableDelete,
  onDelete,
}) => {
  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // add labels for mobile view.
  const hasMobileLabel = (label, value) => (isMobile ? `${label} ${value}` : value)

  // check show actions column.
  const actionsColumnShown =
    enableRequest ||
    enableMedicAct ||
    enableShowHistory ||
    enableShowMedicAct ||
    enableEdit ||
    enableCancel ||
    enableDelete

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // only show if there's items.
  if (!items) return null
  // return content.
  return (
    <ContentItemWrapper isMobile={isMobile} isEven={isEven} isLast={isLast}>
      {items.map((item, j) => {
        const text = item.label ? hasMobileLabel(item.label, item.copy) : item.copy

        return (
          <ContentItem
            key={j}
            align="center"
            type={item?.type}
            isMobile={isMobile}
            grow={item?.grow}
          >
            {item.icon && isMobile && (
              <IconElement>
                <Icon name={item.icon} size="16" />
              </IconElement>
            )}

            {(item.triggerDetail && onDetail) || item.href || item.to ? (
              <Label href={item.href} to={item.to} onClick={onDetail}>
                {text}
              </Label>
            ) : item?.type === 'pill' ? (
              <Pill key={j} background={item?.pillBackground}>
                {text}
              </Pill>
            ) : (
              text
            )}
          </ContentItem>
        )
      })}

      {actionsColumnShown && (
        <ActionsElement
          id="action-cell"
          isMobile={isMobile}
          //
          disableRequest={disableRequest}
          onRequest={enableRequest && onRequest}
          //
          disableMedicAct={disableMedicAct}
          onMedicAct={enableMedicAct && onMedicAct}
          //
          disableShowHistory={disableShowHistory}
          onShowHistory={enableShowHistory && onShowHistory}
          //
          disableShowMedicAct={disableShowMedicAct}
          onShowMedicAct={enableShowMedicAct && onShowMedicAct}
          //
          disableEdit={disableEdit}
          onEdit={enableEdit && onEdit}
          //
          disableCancel={disableCancel}
          onCancel={enableCancel && onCancel}
          //
          disableDelete={disableDelete}
          onDelete={enableDelete && onDelete}
        />
      )}
    </ContentItemWrapper>
  )
}

export default TableItem
