// dependencies.
import { doc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import { Form } from '../../components/Form'
// utils.
import notifyToast from '../../js/notifyToast'
import { db } from '../../js/firebase/firebase'
import { useAuth } from '../../js/contexts/AuthContext'
// constants.
import { SETTINGS_FORM } from './constants'

/*********************************/
/*                               */
/*    Doctors Settings Screen    */
/*                               */
/*********************************/

const DoctorsSettingsScreen = ({ navMenu, navTitle, items = SETTINGS_FORM }) => {
  // contexts.
  const { userData, updateUserData } = useAuth()

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle user information update.
  const handleUpdateUserClick = async (obj) => {
    const userRef = doc(db, 'users', userData.uid)
    const updateFields = {
      firstname: obj.firstname,
      lastname: obj.lastname,
      phone: obj.phone,
      updatedDate: new Date(),
    }
    await setDoc(userRef, updateFields, { merge: true })
      .then(() => {
        updateUserData(obj)
        notifyToast('Datos guardados correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // only show if there's items.
  if (!items) return null

  // return content.
  return (
    <AdminTemplate navigation={navMenu} sectionTitle={navTitle}>
      <Form
        items={items}
        defaultValues={userData}
        submitLabel="Actualizar"
        onClick={(obj) => handleUpdateUserClick(obj)}
      />
    </AdminTemplate>
  )
}

export default DoctorsSettingsScreen
