// dependencies.
import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import styled from 'styled-components'
// components.
import {
  Activate,
  AdminBusiness,
  AdminCertifications,
  AdminDoctor,
  AdminEmployees,
  AdminHome,
  AdminPathologies,
  AdminSettings,
  AdminUsers,
  AdminZoning,
  AdminReports,
  BusinessCertifications,
  BusinessEmployees,
  BusinessHome,
  DevelopmentHome,
  DoctorCertifications,
  DoctorHome,
  DoctorSettings,
  Reset,
  Signin,
  Signup,
  Splash,
} from './screens'
// utils.
import { useAuth } from './js/contexts/AuthContext'
import { useDimensions } from './js/hooks'
// constants.
import { DEV_NAVIGATION_ITEMS, NAVIGATION_ITEMS, SIGN_UP_ENABLED } from './constants'
import DoctorAdminZoningScreen from './screens/AdminDoctor/Zoning'

/************************/
/*                      */
/*    App and Screen    */
/*                      */
/************************/

// partials:
const Wrapper = styled.div(
  {
    height: (props) => `${props.height}px`,
    width: (props) => `${props.width}px`,
  },
  `@media print { height: auto; width: auto; }`
)

// main component:
const App = () => {
  // contexts.
  const { userData } = useAuth()
  // hooks.
  const dimensions = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // build navigation menues.
  const NAV_ITEMS = (panel) => {
    if (!panel || userData?.access === 'developer') return DEV_NAVIGATION_ITEMS
    else return NAVIGATION_ITEMS[panel]
  }

  // return content.
  return (
    <Wrapper {...dimensions}>
      <Routes>
        {/* Splash Screen */}
        <Route path="/" element={<Splash />} />
        {/* Auth Screens */}
        <Route path="/activate" element={<Activate />} />
        <Route path="/reset-password" element={<Reset />} />
        <Route path="/signin" element={<Signin />} />
        {SIGN_UP_ENABLED && <Route path="/signup" element={<Signup />} />}
        {/* Superadmin Screens */}
        <Route path="/admin">
          <Route
            index
            element={
              <AdminHome navMenu={NAV_ITEMS('admin')} navTitle="Inicio" />
              //
            }
          />
          <Route
            path="/admin/ajustes"
            element={
              <AdminSettings navMenu={NAV_ITEMS('admin')} navTitle="Ajustes" />
              //
            }
          />
          <Route
            path="/admin/certificaciones"
            element={
              <AdminCertifications navMenu={NAV_ITEMS('admin')} navTitle="Certificaciones" />
              //
            }
          />
          <Route
            path="/admin/empresas"
            element={
              <AdminBusiness navMenu={NAV_ITEMS('admin')} navTitle="Empresas" />
              //
            }
          />
          <Route
            path="/admin/empresas/:businessId"
            element={
              <AdminEmployees navMenu={NAV_ITEMS('admin')} navTitle="Pacientes" />
              //
            }
          />
          <Route
            path="/admin/medicos"
            element={
              <AdminDoctor navMenu={NAV_ITEMS('admin')} navTitle="Médicos" />
              //
            }
          />
          <Route
            path="/admin/patologias"
            element={
              <AdminPathologies navMenu={NAV_ITEMS('admin')} navTitle="Patologías" />
              //
            }
          />
          <Route
            path="/admin/usuarios"
            element={
              <AdminUsers navMenu={NAV_ITEMS('admin')} navTitle="Admins" />
              //
            }
          />
          <Route
            path="/admin/mapa"
            element={
              <AdminZoning navMenu={NAV_ITEMS('admin')} navTitle="Zonificación" />
              //
            }
          />
        </Route>
        <Route
            path="/admin/estadisticas"
            element={
              <AdminReports navMenu={NAV_ITEMS('admin')} navTitle="Estadísticas" />
              //
            }
            
          />

        {/* Doctor Screens */}
        <Route path="/doctores">
          <Route
            index
            element={
              <DoctorHome navMenu={NAV_ITEMS('doctor')} navTitle="Inicio" />
              //
            }
          />
          <Route
            path="/doctores/ajustes"
            element={
              <DoctorSettings navMenu={NAV_ITEMS('doctor')} navTitle="Ajustes" />
              //
            }
          />
          <Route
            path="/doctores/certificaciones"
            element={
              <DoctorCertifications navMenu={NAV_ITEMS('doctor')} navTitle="Certificaciones" />
              //
            }
          />
        </Route>
        {/* Business Screens */}
        <Route path="/empresas">
          <Route
            index
            element={
              <BusinessHome navMenu={NAV_ITEMS('business')} navTitle="Inicio" />
              //
            }
          />
          <Route
            path="/empresas/empleados"
            element={
              <BusinessEmployees navMenu={NAV_ITEMS('business')} navTitle="Empleados" />
              //
            }
          />
          <Route
            path="/empresas/certificaciones"
            element={
              <BusinessCertifications navMenu={NAV_ITEMS('business')} navTitle="Certificaciones" />
              //
            }
          />
        </Route>
        {/* Development Screens */}
        <Route path="/dev">
          <Route
            index
            element={
              <DevelopmentHome navMenu={NAV_ITEMS()} navTitle="Developers" />
              //
            }
          />
        </Route>

        <Route path="/doctor-admin">
          <Route
            index
            element={
              <DoctorAdminZoningScreen navMenu={NAV_ITEMS('doctoradmin')} navTitle="Zonificación" />
              //
            }
          />
    
        </Route>
      </Routes>
    </Wrapper>
  )
}
export default App
