export const CERTIFICATION_FORM = (pathologies) => [
  {
    type: 'array',
    disabled: true,
    name: 'medicAct',
    items: [
      { type: 'date', name: 'issuedDate', placeholder: 'Fecha de Realizado', required: true },
      { type: 'headline', label: 'Certificación:' },
      [
        { type: 'date', name: 'startDate', placeholder: 'Inicio', required: true },
        { type: 'date', name: 'endDate', placeholder: 'Fin', required: true },
      ],
      {
        name: 'homeLeave',
        placeholder: 'Salida Domiciliaria',
        options: [
          { name: 'authorized', label: 'Puede' },
          { name: 'unauthorized', label: 'No Puede' },
          { name: 'appointment', label: 'Consulta Médica' },
        ],
        required: true,
      },
      { name: 'pathologyId', placeholder: 'Patología', options: pathologies, required: true },
      { type: 'checkbox', name: 'externalCertificateCheck', placeholder: 'Certificación Externa' },
      {
        name: 'doctorNameExt',
        placeholder: 'Nombre del Médico',
        showOnExternalCertification: true,
      },
      {
        type: 'date',
        name: 'startDateExt',
        placeholder: 'Inicio',
        showOnExternalCertification: true,
      },
      {
        type: 'date',
        name: 'endDateExt',
        placeholder: 'Fin',
        showOnExternalCertification: true,
      },
      { type: 'headline', label: 'Comentarios:' },
      { name: 'comments', placeholder: 'Notas o comentarios' },
    ],
  },
]

export const SETTINGS_FORM = [
  { type: 'headline', label: 'Datos personales:' },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { type: 'headline', label: 'Contacto:' },
  { name: 'phone', placeholder: 'Teléfono', required: true },
]
