export const NEW_PATHOLOGIE_FORM = [
  { type: 'headline', label: 'Datos de la patología:' },
  { name: 'publicName', placeholder: 'Nombre público', required: true },
  { name: 'confidentialName', placeholder: 'Nombre confidencial', required: true },
  { name: 'code', placeholder: 'Código de identificación', required: true },
  {
    name: 'isClinical',
    placeholder: '¿Es una patologia clinica?',
    type: 'checkbox',
  },
  { type: 'headline', label: 'Comentarios:' },
  { name: 'comments', placeholder: 'Notas o comentarios' },
  { type: 'checkbox', name: 'isActive', placeholder: 'Activa' },
]

export const NEW_ADMIN_FORM = [
  { type: 'headline', label: 'Datos del administrador:' },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { name: 'email', placeholder: 'Email', required: true },
  { name: 'phone', placeholder: 'Celular' },
]

export const NEW_DOCTOR_FORM = [
  { type: 'headline', label: 'Datos del médico:' },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { name: 'email', placeholder: 'Email', required: true },
  { name: 'phone', placeholder: 'Celular', required: true },
]

export const EDIT_DOCTOR_FORM = (userNotCreated) => [
  { type: 'headline', label: 'Datos del médico:' },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { name: 'email', placeholder: 'Email', required: true },
  { name: 'phone', placeholder: 'Celular', required: true },
  { type: 'checkbox', name: 'isActive', placeholder: 'Activo', disabled: userNotCreated },
]

export const NEW_CERTIFICATION_FORM = (businesses, employees) => [
  { name: 'businessName', placeholder: 'Empresa', options: businesses, required: true },
  { type: 'headline', label: 'Empleado:' },
  { name: 'selectPatient', placeholder: 'Paciente', options: employees },
  { name: 'document', placeholder: 'Cédula de identidad', required: true, disabled: true },
  { type: 'headline', label: 'Dirección:' },
  {
    type: 'array',
    name: 'address',
    items: [
      { type: 'checkbox', name: 'isHospital', placeholder: '¿Es en hospital?' },
      [
        { name: 'street', placeholder: 'Calle', required: true },
        { name: 'number', placeholder: 'Número' },
      ],
      [
        { name: 'suite', placeholder: 'Apartamento / Oficina' },
        { name: 'cornerstreet', placeholder: 'Esquina' },
      ],
      [
        { name: 'city', placeholder: 'Ciudad', required: true },
        { name: 'state', placeholder: 'Departamento', required: true },
      ],
      { name: 'district', placeholder: 'Barrio / Localidad', required: true },
      { name: 'phone', placeholder: 'Número de contacto', required: true },
      { name: 'indications', placeholder: 'Indicaciones' },
    ],
  },
  { type: 'headline', label: 'Comentarios:' },
  { name: 'comments', placeholder: 'Notas o comentarios' },
]

export const NEW_PATIENT_FORM = [
  { type: 'headline', label: 'Datos del empleado:' },
  { name: 'id', placeholder: 'Cédula de identidad', required: true },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'middlename', placeholder: 'Segundo nombre' },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { name: 'employeenumber', placeholder: 'Número de empleado' },
  { type: 'headline', label: 'Dirección y contacto:' },
  {
    type: 'array',
    name: 'address',
    items: [
      { name: 'email', placeholder: 'Email' },
      { name: 'phone', placeholder: 'Teléfono', required: true },
      [
        { name: 'street', placeholder: 'Calle', required: true },
        { name: 'number', placeholder: 'Número' },
      ],
      [
        { name: 'suite', placeholder: 'Apartamento / Oficina' },
        { name: 'cornerstreet', placeholder: 'Esquina' },
      ],
      [
        { name: 'city', placeholder: 'Ciudad', required: true },
        { name: 'state', placeholder: 'Departamento', required: true },
      ],
      { name: 'district', placeholder: 'Barrio / Localidad', required: true },
      { name: 'indications', placeholder: 'Indicaciones' },
    ],
  },
]

export const UPDATE_PATIENT_FORM = [
  { type: 'headline', label: 'Datos del empleado:' },
  { name: 'id', placeholder: 'Cédula de identidad', disabled: true },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'middlename', placeholder: 'Segundo nombre' },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { name: 'employeenumber', placeholder: 'Número de empleado' },
  { type: 'headline', label: 'Dirección y contacto:' },
  {
    type: 'array',
    name: 'address',
    items: [
      { name: 'email', placeholder: 'Email' },
      { name: 'phone', placeholder: 'Teléfono', required: true },
      [
        { name: 'street', placeholder: 'Calle', required: true },
        { name: 'number', placeholder: 'Número' },
      ],
      [
        { name: 'suite', placeholder: 'Apartamento / Oficina' },
        { name: 'cornerstreet', placeholder: 'Esquina' },
      ],
      [
        { name: 'city', placeholder: 'Ciudad', required: true },
        { name: 'state', placeholder: 'Departamento', required: true },
      ],
      { name: 'district', placeholder: 'Barrio / Localidad', required: true },
      { name: 'indications', placeholder: 'Indicaciones' },
    ],
  },
]

export const SET_BUSINESS_PATIENT_FORM = (businesses) => [
  { type: 'headline', label: 'Asignar el empleado a una empresa:' },
  { name: 'businessName', placeholder: 'Empresa', options: businesses, required: true },
]

export const DELETE_PATIENT_FORM = [
  { type: 'headline', label: 'Estás seguro que deseas eliminar el empleado?' },
]

export const SETTINGS_FORM = [
  { type: 'headline', label: 'Datos personales:' },
  { name: 'firstname', placeholder: 'Nombre', required: true },
  { name: 'lastname', placeholder: 'Apellido', required: true },
  { type: 'headline', label: 'Contacto:' },
  { name: 'phone', placeholder: 'Teléfono' },
]

export const ASSIGN_CERTIFICATION_FORM = (doctors) => [
  { type: 'headline', label: 'Médico:' },
  { name: 'doctorId', placeholder: 'Médico', options: doctors },
  { type: 'headline', label: 'Empresa:' },
  { name: 'businessName', placeholder: 'Empresa', disabled: true },
  { type: 'headline', label: 'Empleado:' },
  {
    type: 'array',
    name: 'patientData',
    items: [
      { name: 'fullname', placeholder: 'Paciente', disabled: true },
      { name: 'id', placeholder: 'Cédula de identidad', disabled: true },
    ],
  },
  { type: 'headline', label: 'Dirección:' },
  {
    type: 'array',
    name: 'address',
    items: [
      { type: 'checkbox', name: 'isHospital', placeholder: '¿Es en hospital?' },
      [
        { name: 'street', placeholder: 'Calle', required: true },
        { name: 'number', placeholder: 'Número' },
      ],
      [
        { name: 'suite', placeholder: 'Apartamento / Oficina' },
        { name: 'cornerstreet', placeholder: 'Esquina' },
      ],
      [
        { name: 'city', placeholder: 'Ciudad', required: true },
        { name: 'state', placeholder: 'Departamento', required: true },
      ],
      { name: 'district', placeholder: 'Barrio / Localidad', required: true },
      { name: 'phone', placeholder: 'Número de contacto', required: true },
      { name: 'indications', placeholder: 'Indicaciones' },
    ],
  },
  { type: 'headline', label: 'Comentarios:' },
  { name: 'comments', placeholder: 'Notas o comentarios' },
]

export const ASSIGN_MULTI_CERTIFICATION_FORM = (doctors, selectedDoctor) => [
  { type: 'headline', label: 'Médico:' },
  { name: 'doctorId', placeholder: selectedDoctor || 'Médico', options: doctors, required: true },
]

export const CANCEL_CERTIFICATION_FORM = [
  { type: 'headline', label: '¿Estás seguro que deseas cancelar la certificación?' },
]

export const EDIT_CERTIFICATION_FORM = [
  { name: 'business', placeholder: 'Empresa', disabled: true },
  { type: 'headline', label: 'Empleado:' },
  {
    type: 'array',
    name: 'patientData',
    items: [
      { name: 'fullname', placeholder: 'Paciente', disabled: true },
      { name: 'id', placeholder: 'Cédula de identidad', disabled: true },
    ],
  },
  { type: 'headline', label: 'Dirección:' },
  {
    type: 'array',
    name: 'address',
    items: [
      { type: 'checkbox', name: 'isHospital', placeholder: '¿Es en hospital?' },
      [
        { name: 'street', placeholder: 'Calle', required: true },
        { name: 'number', placeholder: 'Número' },
      ],
      [
        { name: 'suite', placeholder: 'Apartamento / Oficina' },
        { name: 'cornerstreet', placeholder: 'Esquina' },
      ],
      [
        { name: 'city', placeholder: 'Ciudad', required: true },
        { name: 'state', placeholder: 'Departamento', required: true },
      ],
      { name: 'district', placeholder: 'Barrio / Localidad', required: true },
      { name: 'phone', placeholder: 'Número de contacto', required: true },
      { name: 'indications', placeholder: 'Indicaciones' },
    ],
  },
  { type: 'headline', label: 'Comentarios:' },
  { name: 'comments', placeholder: 'Notas o comentarios' },
]

export const MEDIC_ACT_CERTIFICATION = [
  {
    type: 'array',
    disabled: true,
    name: 'medicAct',
    items: [
      { type: 'headline', label: 'Inicio Certificación' },
      { type: 'date', name: 'startDate', placeholder: 'DD/MM/YYYY', disabled: true },
      { type: 'headline', label: 'Fin Certificación' },
      { type: 'date', name: 'endDate', placeholder: 'DD/MM/YYYY', disabled: true },
      { type: 'headline', label: 'Fecha de Realizado' },
      { type: 'date', name: 'issuedDate', placeholder: 'DD/MM/YYYY', disabled: true },
      {
        type: 'checkbox',
        name: 'externalCertificateCheck',
        placeholder: 'Certificación Externa',
        disabled: true,
      },
      {
        name: 'doctorNameExt',
        placeholder: 'Nombre del Médico',
        showOnExternalCertification: true,
        disabled: true,
      },
      {
        type: 'headline',
        label: 'Inicio Certificación Ext',
        showOnExternalCertification: true,
        disabled: true,
      },
      {
        type: 'date',
        name: 'startDateExt',
        placeholder: 'DD/MM/YYYY',
        showOnExternalCertification: true,
        disabled: true,
      },
      {
        type: 'headline',
        label: 'Fin Certificación Ext',
        showOnExternalCertification: true,
        disabled: true,
      },
      {
        type: 'date',
        name: 'endDateExt',
        placeholder: 'DD/MM/YYYY',
        showOnExternalCertification: true,
        disabled: true,
      },
      { type: 'headline', label: 'Salida domiciliaria' },
      {
        name: 'homeLeave',
        placeholder: 'Salida Domiciliaria',
        disabled: true,
        options: [
          { name: 'authorized', label: 'Puede' },
          { name: 'unauthorized', label: 'No Puede' },
          { name: 'appointment', label: 'Consulta Médica' },
        ],
      },
      { type: 'headline', label: 'Patología' },
      { name: 'pathology', placeholder: 'Patología', disabled: true },
      { type: 'headline', label: 'Comentarios' },
      { name: 'comments', placeholder: 'Comentarios', disabled: true },
    ],
  },
]
export const CERTIFICATION_FORM = (pathologies) => [
  {
    type: 'array',
    disabled: true,
    name: 'medicAct',
    items: [
      { type: 'date', name: 'issuedDate', placeholder: 'Fecha de Realizado', required: true },
      { type: 'headline', label: 'Certificación:' },
      [
        { type: 'date', name: 'startDate', placeholder: 'Inicio', required: true },
        { type: 'date', name: 'endDate', placeholder: 'Fin', required: true },
      ],
      {
        name: 'homeLeave',
        placeholder: 'Salida Domiciliaria',
        options: [
          { name: 'authorized', label: 'Puede' },
          { name: 'unauthorized', label: 'No Puede' },
          { name: 'appointment', label: 'Consulta Médica' },
        ],
        required: true,
      },
      { name: 'pathologyId', placeholder: 'Patología', options: pathologies, required: true },
      { type: 'checkbox', name: 'externalCertificateCheck', placeholder: 'Certificación Externa' },
      {
        name: 'doctorNameExt',
        placeholder: 'Nombre del Médico',
        showOnExternalCertification: true,
      },
      {
        type: 'date',
        name: 'startDateExt',
        placeholder: 'Inicio',
        showOnExternalCertification: true,
      },
      {
        type: 'date',
        name: 'endDateExt',
        placeholder: 'Fin',
        showOnExternalCertification: true,
      },
      { type: 'headline', label: 'Comentarios:' },
      { name: 'comments', placeholder: 'Notas o comentarios' },
    ],
  },
]
