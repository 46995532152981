// dependencies.
import styled from 'styled-components'
// components.
import { FlatCard } from '../components/Card'

/*************************/
/*                       */
/*    Show Data Modal    */
/*                       */
/*************************/

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  margin: '0 auto',
  maxWidth: '416px',
  width: '100%',
})

// main component.
const PatientData = ({ employee }) => {
  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // extract object keys.
  const { firstname, middlename, lastname, id, employeenumber, address, comments } = employee
  const { city, cornerstreet, district, email, number, phone, state, street, suite, isHospital, indications } =
    address

  // build data object.
  const data = [
    {
      icon: 'user',
      title: 'Datos del empleado',
      items: [
        {
          label: 'Nombre completo:',
          value: `${firstname} ${middlename} ${lastname}`,
        },
        {
          label: 'Cédula de identidad:',
          value: id,
        },
        {
          label: 'Nro. de Funcionario:',
          value: employeenumber,
        },
      ],
    },
    {
      icon: 'external',
      title: 'Contacto',
      items: [
        {
          label: 'Email:',
          value: email,
          href: `mailto:${email}`,
        },
        {
          label: 'Teléfono:',
          value: phone,
          href: `tel:${phone}`,
        },
      ],
    },
    {
      icon: 'bookmark',
      title: `Dirección ${isHospital ? '(Hospital)' : ''}`,
      items: [
        {
          label: 'Calle y número:',
          value: `${street}, ${number}`,
        },
        {
          label: 'Apartamento / Oficina:',
          value: suite,
        },
        {
          label: 'Esquina:',
          value: cornerstreet,
        },
        {
          label: 'Ciudad y departamento:',
          value: `${city}, ${state}`,
        },
        {
          label: 'Barrio / Localidad:',
          value: district,
        },
        {
          label: 'Indicaciones:',
          value: indications,
        }
      ],
    },
    {
      icon: 'edit',
      title: 'Notas y comentarios',
      items: [comments],
    },
  ]

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper>
      {data.map((item) => (
        <FlatCard key={item.title} {...item} />
      ))}
    </Wrapper>
  )
}

export default PatientData
