// dependencies.
import { useEffect, useState } from 'react'
import styled from 'styled-components'
// components.
import { Icon } from '../Icon'
import { Headline } from '../Typography'
// utils.
import { theme } from '../../styleguide'

// helpers.
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.div({
  alignItems: 'center',
  background: theme.colors.white,
  filter: `drop-shadow(${theme.shadows.normal})`,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  maxWidth: `${baseUnit * 30}px`,
  padding: `${baseUnit / 2}px`,
  position: 'fixed',
  right: (props) => (props.isOpen ? 0 : `-${baseUnit * 30}px`),
  top: 0,
  transition: 'right 400ms ease',
  width: '100%',
  zIndex: 10,
})

const ContentWrapper = styled.div({
  overflowY: 'scroll',
  padding: `${baseUnit * 2}px 0`,
  width: '100%',
})

const Header = styled.div({
  alignItems: 'center',
  background: theme.colors.white,
  display: 'flex',
  gap: `${baseUnit}px`,
  height: `${baseUnit * 4}px`,
  padding: `0 ${baseUnit / 2}px ${baseUnit / 2}px`,
  width: '100%',
})

const CloseElement = styled.button({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
})

// main component.
const Drawer = ({ title, children, isOpen, onChange }) => {
  // status.
  const [open, setOpen] = useState(isOpen)

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // update value object.
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  // propagate value.
  useEffect(() => {
    onChange(open)
  }, [open])

  // return content.
  return (
    <Wrapper isOpen={open}>
      <Header>
        <Headline size="smallest">{title}</Headline>

        <CloseElement onClick={() => setOpen(false)}>
          <Icon name="close" size={24} />
        </CloseElement>
      </Header>

      {open && <ContentWrapper>{children}</ContentWrapper>}
    </Wrapper>
  )
}

export default Drawer
