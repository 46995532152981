// dependencies.
import { useEffect, useState } from 'react'
import { collection, query, where } from 'firebase/firestore'
// utils.
import useFetch from '../useFetch'
import { db } from '../../firebase/firebase'
import { useAuth } from '../../contexts/AuthContext'

/****************************/
/*                          */
/*    User Business Data    */
/*                          */
/****************************/

const useUserBusinessData = (reload, access = ['company', 'developer']) => {
  // contexts.
  const { user, userData } = useAuth()
  // states.
  const [businessCard, setBusinessCard] = useState({})

  // ------------------------------------------------------------------------------------------- //
  // FETCH DATABASE DATA                                                                         //
  // ------------------------------------------------------------------------------------------- //

  // database query.
  const q =
    userData &&
    userData.businessRef &&
    query(collection(db, 'business'), where('ref', '==', userData.businessRef))

  // fetch user's business from firebase's collection.
  const { data, empty } = useFetch(
    {
      ...(userData && userData.businessRef && { q }),
      condition: userData === {} || !userData.businessRef,
      access,
      user,
      userData,
      name: 'UserBusiness',
    },
    [reload]
  )

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // process business list for card list for Business Home page.
  const businessCardArr = (businessSet = data) => {
    return businessSet.map((businessObj) => {
      const { name, rut, address } = businessObj

      // card items.
      return {
        name: name,
        items: [
          { icon: 'edit', copy: address?.branch || address?.city, grow: true },
          { icon: 'bookmark', copy: `${address.street || ''} ${address.number || ''}`, grow: true },
          { icon: 'title', copy: rut },
          { icon: 'user', copy: address?.phone },
        ],
        raw: businessObj,
      }
    })
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // build data object.
  useEffect(() => {
    setBusinessCard(businessCardArr(data))
  }, [data])

  // return values.
  return { business: data, businessCard, emptyBusiness: empty }
}

export default useUserBusinessData
