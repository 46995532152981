// dependencies.
import { useState, useEffect } from 'react'
import styled from 'styled-components'
// components.
import { Body } from '../Typography'
import { Icon } from '../Icon'
// utils.
import { theme } from '../../styleguide'

/**********************************/
/*                                */
/*    Checkbox Input Component    */
/*                                */
/**********************************/

// helpers.
const iconSize = 20
const paddingSize = 4

// partials.
const Wrapper = styled.div({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  gap: '8px',
  padding: '8px 0',
})

const CheckboxWrapper = styled.div({
  alignItems: 'center',
  background: (props) => props.background,
  border: (props) => `2px solid ${props.color}`,
  borderRadius: '4px',
  color: (props) => props.color,
  cursor: 'pointer',
  display: 'flex',
  flex: 'none',
  flexDirection: 'row',
  flexGrow: 0,
  height: `${iconSize + paddingSize * 2}px`,
  justifyContent: 'center',
  order: 0,
  padding: `${paddingSize}px`,
  width: `${iconSize + paddingSize * 2}px`,
})

// main component.
const Checkbox = ({ label = null, name, defaultValue, disabled, onChange }) => {
  // state.
  const [checked, setChecked] = useState(false)

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle check / uncheck click.
  const handleClick = () => {
    if (disabled) return null
    setChecked(!checked)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // update value object.
  useEffect(() => {
    if (defaultValue !== '') setChecked(defaultValue)
  }, [defaultValue])

  // propagate value.
  useEffect(() => {
    if (!onChange) return
    onChange(checked)
  }, [checked])

  // build props object.
  const { colors } = theme
  const color = disabled ? 'ash' : 'black'
  const background = disabled ? 'smoke' : 'white'

  // return content.
  return (
    <Wrapper onClick={handleClick}>
      <CheckboxWrapper color={colors[color]} background={colors[background]}>
        {checked && <Icon name="check" size={iconSize} color={color} />}
      </CheckboxWrapper>
      {label ? <Body type="caption">{label}</Body> : null}
    </Wrapper>
  )
}

export default Checkbox
