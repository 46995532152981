// main function.
const styleguideHasKey = (key, arr, def) => {
  // make sure color is included in the library.
  let keyCheck

  if (key in arr) keyCheck = key
  else keyCheck = def

  return keyCheck
}

export default styleguideHasKey
