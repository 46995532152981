// dependencies.
import { useState } from 'react'
import styled from 'styled-components'
// components.
import { Button } from '../Button'
import { Input } from '../Form'
import { Icon } from '../Icon'
import { Body } from '../Typography'
// utils.
import { theme } from '../../styleguide'
import { useDimensions } from '../../js/hooks'
import { CSVLink } from 'react-csv'

/************************/
/*                      */
/*    Search Heading    */
/*                      */
/************************/

// partials.
const Wrapper = styled.div({
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  borderBottom: (props) => (props.isMobile ? `1px solid ${theme.colors.ash}` : 'none'),
  display: 'flex',
  flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
  justifyContent: 'flex-start',
  margin: '-24px -16px 0',
  padding: (props) => (props.isMobile ? '8px' : '24px 16px 40px'),
  width: 'calc(100% + 32px)',
  gap: '8px',
})

const InputWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '8px',
  flexGrow: '1',
})

const StyledInput = styled(Input)({
  width: '100%',
})

const StyledButton = styled(Button)({
  padding: 0,
  height: '48px',
  minWidth: '48px',
  width: (props) => (props.isMobile ? '100%' : 'unset'),
})
const StyledActionButton = styled(Button)({
  padding: 0,
  height: '48px',
  minWidth: '48px',
  width: (props) => (props.isMobile ? '48px' : 'unset'),
})
const InputContainer = styled.div({
  display: 'flex',
  width: '40%',
  gap: '15px',
})

const Divider = styled.hr({
  border: '0',
  width: '1px',
  height: '32px',
  backgroundColor: theme.colors.ash,
})

const ActionButtonWrapper = styled.div({
  maxWidth: '250px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '8px',
  alignContent: 'center',
  alignItems: 'center',
})

const ReportWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: (props) => (props.isMobile ? '100%' : 'unset'),
})

// main component.
const FilterHeader = ({
  exportReport,
  label = 'Nuevo',
  onChange = () => {},
  onFilterClick,
  onActionClick,
  header = [],
  items = [],
  filename = 'reporte',
  placeholder = 'Buscar...',
  selectInput,
  search = true,
}) => {
  // hooks.
  const { isMobile } = useDimensions()

  // states

  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [selectInputValue, setSelectInputValue] = useState()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  // Prepare data for CSV
  const csvHeaders = header.map((h) => h.label)
  const csvData = items.map((item) => {
    return item.items.map((subItem) => subItem.copy)
  })

  const case1 = onFilterClick && exportReport
  const case2 = !onFilterClick && !exportReport && onActionClick
  //  const case3 = onFilterClick && !exportReport && !onActionClick

  const conditionalStyles = {
    case1: {
      search: { width: isMobile ? '100%' : '20%' },
      filters: { width: isMobile ? '100%' : '35%' },
    },
    case2: {
      search: { width: isMobile ? '100%' : '50%' },
      filters: { width: isMobile ? '100%' : '35%' },
    },
    case3: {
      search: { width: isMobile ? '100%' : '50%' },
      filters: { width: isMobile ? '100%' : '50%' },
    },
  }

  const searchConditionStyle = case1
    ? conditionalStyles.case1.search
    : case2
    ? conditionalStyles.case2.search
    : conditionalStyles.case3.search

  const filtersConditionStyle = case1
    ? conditionalStyles.case1.filters
    : case2
    ? conditionalStyles.case2.filters
    : conditionalStyles.case3.filters

  // return content.
  return (
    <Wrapper isMobile={isMobile}>
      {(search || selectInput) && (
        <InputWrapper style={searchConditionStyle}>
          {selectInput?.name && (
            <Input
              {...selectInput}
              onChange={(value) => {
                setSelectInputValue(value)
              }}
            />
          )}

          {search && (
            <StyledInput
              isMobile={isMobile}
              name="search"
              iconL="search"
              placeholder={placeholder}
              onChange={(value) => onChange(value)}
            />
          )}
          {onActionClick &&
            (isMobile ? (
              <StyledActionButton
              onClick={onActionClick}
                hideLabel={isMobile}
                isMobile={isMobile}
              >
                {isMobile ? <Icon name="plus" color="white" size="24" /> : <>{label}</>}
              </StyledActionButton>
            ) : (
              <ActionButtonWrapper>
                {!isMobile && <Divider />}
                <Button onClick={onActionClick} width="100%">
                  {label}
                </Button>
              </ActionButtonWrapper>
            ))}
        </InputWrapper>
      )}

      {onFilterClick && (
        <>
          {(search || selectInput) && !isMobile && <Divider />}
          <InputContainer style={filtersConditionStyle}>
            <Input
              name="fromDate"
              placeholder="Desde"
              showLabel
              onChange={(value) => setFromDate(value)}
              type="date"
            />
            <Input
              name="toDate"
              placeholder="Hasta"
              showLabel
              onChange={(value) => setToDate(value)}
              type="date"
            />
          </InputContainer>
          <StyledButton
            isMobile={isMobile}
            onClick={() =>
              onFilterClick({
                fromDate,
                toDate,
                selectInputValue,
              })
            }
          >
            Filtrar
          </StyledButton>
        </>
      )}
      {exportReport && (
        <ReportWrapper isMobile={isMobile}>
          {!isMobile && <Divider />}
          <Body type="caption">Exportar:</Body>
          <StyledButton isMobile={isMobile} onClick={() => window.print()} background="alert">
            PDF
          </StyledButton>
          {csvHeaders && csvData && (
            <CSVLink
              className="link-reset"
              style={{
                textDecoration: 'none',
                color: 'inherit',
                width: isMobile ? '100%' : 'unset',
              }}
              filename={`${filename}.csv`}
              data={csvData}
              headers={csvHeaders}
            >
              <StyledButton background="success">CSV</StyledButton>
            </CSVLink>
          )}
        </ReportWrapper>
      )}
    </Wrapper>
  )
}

export default FilterHeader
