// dependencies.
import { useEffect, useState } from 'react'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import Table from '../../components/Table'
import { Drawer } from '../../components/Modal'
import { Form } from '../../components/Form'
import { Header } from '../../components/Typography'
import { List } from '../../components/Card'
import { Loader } from '../../components/Icon'
// utils.
import notifyToast from '../../js/notifyToast'
import { db } from '../../js/firebase/firebase'
import { useAuth } from '../../js/contexts/AuthContext'
import {
  useUserBusinessData,
  useBusinessCertificationsData,
  useBusinessPatientsData,
  usePathologiesData,
} from '../../js/hooks/data'
// constants.
import { NEW_CERTIFICATION_FORM, UPDATE_BUSINESS_FORM } from './constants'
import { parseAddressToLatLng } from '../../js/parseAddress'

/******************************/
/*                            */
/*    Business Home Screen    */
/*                            */
/******************************/

// helpers.
const tableHeader = [
  { label: 'Solicitud', grow: 1 },
  { label: 'Estado' },
  { label: 'Documento', grow: 1 },
  { label: 'Nombre', grow: 2 },
  { label: 'Inicio' },
  { label: 'Fin' },
  { label: 'Patología', grow: 1 },
  { label: 'Salida' },
  { label: 'Realizado' },
]

// main component.
const BusinessHomeScreen = ({ navMenu, navTitle }) => {
  const [reload, setReload] = useState(false)
  // contexts.
  const { userData } = useAuth()
  // hooks.
  const { business, businessCard, emptyBusiness }  = useUserBusinessData(reload)
  const { patients, patientsSelect } = useBusinessPatientsData(business)
  const { pathologies } = usePathologiesData()
  const { activeCertificationsTableItems, empty } = useBusinessCertificationsData(
    business,
    patients,
    pathologies,
    reload
  )
  // data objects.
  const patientSelectOptions = patientsSelect()
  // states.
  const [loaded, setLoaded] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  // handle edit business information modal.
  const handleEditBusinessModal = (obj) => {
    setModalContent({
      title: 'Actualizar Empresa',
      component: (
        <Form
          items={UPDATE_BUSINESS_FORM}
          defaultValues={obj}
          onClick={(obj) => handleUpdateBusinessClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // handle show request certification modal.
  const handleNewCertificationModal = () => {
    setModalContent({
      title: 'Solicitar certificación',
      component: (
        <Form
          items={NEW_CERTIFICATION_FORM(patientSelectOptions)}
          onClick={(obj) => handleSaveCertificationClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle business information update.
  const handleUpdateBusinessClick = async (obj) => {
    const businessRef = doc(db, 'business', userData.businessRef.id)
    const updateFields = {
      address: obj.address,
      comments: obj.comments,
      name: obj.name,
      updatedDate: new Date(),
    }
    await setDoc(businessRef, updateFields, { merge: true })
      .then(() => notifyToast('Empresa guardada correctamente', 'success'))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // handle request new certification.
  const handleSaveCertificationClick = async (obj) => {
    if (obj) delete obj.selectPatient

    const patientRef = doc(db, 'patients', obj.document)
    const address = `${obj.address.street} ${obj.address.number}, ${obj.address.district}, ${obj.address.city}, Uruguay`
    const { lat, lng } = await parseAddressToLatLng(address)

    await addDoc(collection(db, 'certifications'), {
      address: { ...obj?.address, lat, lng },
      createdDate: new Date(),
      updatedDate: new Date(),
      status: 0,
      patientRef,
      businessRef: business[0].ref,
      businessName: business[0].name,
      comments: obj.comments,
    })
      .then(async (certRef) => {
        await setDoc(certRef, { ref: certRef }, { merge: true })
        notifyToast('Certificación solicitada correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    setReload(!reload)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // only show content when loaded.
  useEffect(() => {
    (!empty || !emptyBusiness) && setLoaded(true)
  }, [empty, emptyBusiness])

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        {loaded ? (
          <>
            <List
              items={businessCard}
              noItemsMessage="No tiene una empresa asignada."
              onClick={(obj) => handleEditBusinessModal(obj)}
            />

            <Header label="Solicitar nueva" onClick={() => handleNewCertificationModal()}>
              Certificaciones Activas
            </Header>

            <Table header={tableHeader} items={activeCertificationsTableItems} hideActions />
          </>
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default BusinessHomeScreen
