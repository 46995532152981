const search = (array, searchTerm) => {
  return array.filter((data) => {
    let str = `${data.id}`

    // Admin
    if (data.raw) {
      if (data.raw.id) str = `${str}^${data.raw.id}`
      if (data.raw.firstname) str = `${str}^${data.raw.firstname}`
      if (data.raw.middlename) str = `${str}^${data.raw.middlename}`
      if (data.raw.lastname) str = `${str}^${data.raw.lastname}`
      if (data.raw.phone) str = `${str}^${data.raw.phone}`
      if (data.raw.email) str = `${str}^${data.raw.email}`

      if (data.raw.access) str = `${str}^${data.raw.access}`

      if (data.raw.name) str = `${str}^${data.raw.name}`
      if (data.raw.rut) str = `${str}^${data.raw.rut}`

      if (data.raw.code) str = `${str}^${data.raw.code}`
      if (data.raw.confidentialName) str = `${str}^${data.raw.confidentialName}`
      if (data.raw.publicName) str = `${str}^${data.raw.publicName}`
      if (data.raw.isClinical) str = `${str}^${data.raw.isClinical}`

      if (data.raw.address) {
        if (data.raw.address.city) str = `${str}^${data.raw.address.city}`
        if (data.raw.address.district) str = `${str}^${data.raw.address.district}`
        if (data.raw.address.street) str = `${str}^${data.raw.address.street}`
        if (data.raw.address.suite) str = `${str}^${data.raw.address.suite}`
      }

      if (data.raw.patientData) {
        if (data.raw.patientData.firstname) str = `${str}^${data.raw.patientData.firstname}`
        if (data.raw.patientData.middlename) str = `${str}^${data.raw.patientData.middlename}`
        if (data.raw.patientData.lastname) str = `${str}^${data.raw.patientData.lastname}`
        if (data.raw.patientData.id) str = `${str}^${data.raw.patientData.id}`
      }
    }

    if (data.status) str = `${str} ${data.status}`

    // Doctors
    if (data.patientData) {
      if (data.patientData.firstname) str = `${str}^${data.patientData.firstname}`
      if (data.patientData.middlename) str = `${str}^${data.patientData.middlename}`
      if (data.patientData.lastname) str = `${str}^${data.patientData.lastname}`
      if (data.patientData.id) str = `${str}^${data.patientData.id}`
    }

    // Business
    if (data.id) str = `${str} ${data.id}`
    if (data.idnumber) str = `${str} ${data.idnumber}`
    if (data.firstname) str = `${str} ${data.firstname}`
    if (data.middlename) str = `${str} ${data.middlename}`
    if (data.lastname) str = `${str} ${data.lastname}`
    if (data.business) str = `${str} ${data.business}`

    if (data.city) str = `${str}^${data.city}`
    if (data.district) str = `${str}^${data.district}`
    if (data.street) str = `${str}^${data.street}`
    if (data.suite) str = `${str}^${data.suite}`

    if (data.address) {
      if (data.address.city) str = `${str}^${data.address.city}`
      if (data.address.district) str = `${str}^${data.address.district}`
      if (data.address.street) str = `${str}^${data.address.street}`
      if (data.address.suite) str = `${str}^${data.address.suite}`
    }

    const processStr = str.toUpperCase().includes(searchTerm.trim('^').toUpperCase())

    return processStr
  })
}

export default search
