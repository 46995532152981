// dependencies.
import { useEffect, useState } from 'react'
import styled from 'styled-components'
// components.
import { Label } from '../../components/Typography'
import { Icon } from '../../components/Icon'
// utils.
import setBackgroundColor from '../../js/setBackgroundColor'
import { theme } from '../../styleguide'
import { useAuth } from '../../js/contexts/AuthContext'
import { useNavigate } from 'react-router'

/******************************/
/*                            */
/*    Navigation Component    */
/*                            */
/******************************/

// partials.
const NavigationWrapper = styled.div(
  {
    alignItems: 'center',
    background: (props) => props.background,
    bottom: 0,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    height: '48px',
    left: 0,
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: '0 16px',
    position: 'fixed',
    transition: 'all 200ms ease',
    width: '100%',
    zIndex: 3,
  },
  `@media print { display: none; }`
)

const Item = styled(Label)({
  alignItems: 'center',
  cursor: (props) => (props.active ? 'text' : 'pointer'),
  display: 'flex',
  flexDirection: 'column',
  fontSize: '10px',
  fontWeight: '400',
  padding: '6px 0 2px',
  position: 'relative',
  whiteSpace: 'nowrap',
  width: '100%',
})

const Divider = styled.hr({
  backgroundColor: theme.opacity.white10,
  border: 'none',
  display: 'block',
  height: '80%',
  minWidth: '1px',
  pointerEvents: 'none',
  width: '2px',
})

// main component.
const BottomBar = ({
  title,
  items,
  footNote,
  expanded,
  condensed = false,
  background,
  onClick,
}) => {
  // hooks.
  const navigate = useNavigate()
  const { userData } = useAuth()

  // states.
  const [activePath, setActivePath] = useState('')

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle redirect depending on user access.
  const handleAccess = (pathname) => {
    if (!pathname.startsWith('/admin', 0) && userData.access === 'superadmin') navigate('/admin')
    if (!pathname.startsWith('/empresas', 0) && userData.access === 'company') navigate('/empresas')
    if (!pathname.startsWith('/doctores', 0) && userData.access === 'doctor') navigate('/doctores')
    if (!pathname.startsWith('/doctor-admin', 0) && userData.access === 'doctor-admin') navigate('/doctor-admin')
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // set active item and redirect user to the right panel.
  useEffect(() => {
    if (typeof window === 'undefined') return

    const urlObject = new URL(window.location.href)

    handleAccess(urlObject?.pathname)
    setActivePath(urlObject?.pathname)
  }, [userData])

  // return content.
  return (
    <NavigationWrapper background={setBackgroundColor(userData.access)}>
      {items.map((item, i) => {
        const isActive = item.path === activePath
        if (item.type === 'divider' || item.type === 'spacer') return null
        if (item.type === 'blockspacer') return <Divider key={i} />

        return (
          <Item
            key={i}
            active={isActive}
            color={isActive ? 'primary' : 'white'}
            size="smaller"
            to={!isActive && item.path}
          >
            {<Icon key={i} name={item.icon} color={isActive ? 'primary' : 'white'} size={20} />}
            {item.label}
          </Item>
        )
      })}
    </NavigationWrapper>
  )
}
export default BottomBar
